import React from "react";

import { SCHEDULE_LIST } from "@/pages/hospital/info/constants";
import { Box } from "@/pages/hospital/info/components";
import { convertFormat } from "@/pages/hospital/info/utils";
import { useFieldScheduleHook } from "@/pages/hospital/info/hooks";

export default function FieldSchedule() {
  const { storeData, handleChange, handleClick } = useFieldScheduleHook();

  return (
    <Box>
      <div className="mb-[10px]">
        <label className="mr-[30px] text-[14px] font-medium text-form-purple">운영시간</label>
        <button
          type="button"
          className="bg-form-putext-form-purple inline-block h-[30px] rounded-md px-[20px] text-white"
          onClick={handleClick}
        >
          모든 요일에 복사하기
        </button>
      </div>
      <div className="flex flex-col gap-[5px]">
        {SCHEDULE_LIST.map((item) => (
          <div key={`schedule-${item.id}`}>
            <div className="mr-[20px] inline-block w-[60px]">
              <input
                type="checkbox"
                className="mr-[5px] align-middle"
                name={item.allowKey}
                checked={storeData[item.allowKey] ?? false}
                onChange={handleChange}
              />
              <label className="align-middle">{item.title}</label>
            </div>
            <div className="inline-flex w-[400px] items-center">
              <input
                type="time"
                className="h-[30px] grow rounded-md border-2 px-[10px]"
                name={item.startKey}
                value={convertFormat(storeData[item.startKey])}
                onChange={handleChange}
              />
              <span className="mx-[10px]">-</span>
              <input
                type="time"
                className="h-[30px] grow rounded-md border-2 px-[10px]"
                name={item.endKey}
                value={convertFormat(storeData[item.endKey])}
                onChange={handleChange}
              />
            </div>
          </div>
        ))}
        <div className="flex items-center gap-[20px]">
          <div className="w-[60px]">
            <label>기타정보</label>
          </div>
          <div className="relative">
            <input
              type="text"
              className="h-[30px] w-[400px] grow rounded-md border-2 pl-[10px] pr-[50px] placeholder:text-black"
              name="opening_desc"
              value={storeData["ko"]?.opening_desc ?? ""}
              maxLength={50}
              placeholder={"일요일 정기휴무"}
              onChange={handleChange}
            />
            <span className="absolute right-[10px] top-1/2 z-10 -translate-y-1/2">{`${storeData["ko"]?.opening_desc?.length ?? 0} / 50`}</span>
          </div>
        </div>
      </div>
    </Box>
  );
}
