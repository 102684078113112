import React, { useMemo, useRef } from "react";

import { ProcessButton } from "@/pages/event/consultation/components/ui/process-button";
import { CONSULTATION_PROCESS } from "@/pages/event/consultation/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { eventConsultFetch } from "@/api";

export default function ProcessCard({
  id,
  deps,
  value,
  disabled
}: {
  id: number;
  deps: 0 | 1 | 2;
  value: string;
  disabled: boolean;
}) {
  const queryClient = useQueryClient();
  const listRef = useRef<HTMLDivElement>(null);

  const mutation = useMutation({
    mutationFn: async (status: string) =>
      eventConsultFetch.patch("step", `${id}`, {
        step_type: deps,
        [`step${deps + 1}`]: status
      }),
    onSuccess: () => {
      alert("성공");
      queryClient.invalidateQueries({
        queryKey: ["event", "consultation", "process"]
      });
    },
    onError: () => {
      alert("에러");
    }
  });

  const handleOpenList = () => {
    if (disabled) return;
    const el = listRef.current as HTMLDivElement;
    if (el.dataset.modal === "open") {
      el.dataset.modal = "close";
      return;
    }

    for (const el of document.querySelectorAll("div[data-name=process-card]")) {
      el.setAttribute("data-modal", "close");
    }

    el.dataset.modal = "open";
  };

  const handleChangeStatus = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    const { value } = e.currentTarget;

    mutation.mutate(value);

    const el = listRef.current as HTMLDivElement;

    el.dataset.modal = "close";
  };

  const selectedStatus = useMemo(() => {
    return CONSULTATION_PROCESS[deps].find((item) => item.value === value);
  }, [deps, value]);

  return (
    <div
      ref={listRef}
      className="group relative"
      data-name="process-card"
      data-modal="close"
      onClick={(e) => e.stopPropagation()}
    >
      <ProcessButton status={selectedStatus?.status ?? "blocked"} props={{ disabled, onClick: handleOpenList }}>
        {selectedStatus?.text ?? "-"}
      </ProcessButton>
      <div className="absolute z-50 mt-[5px] hidden flex-col gap-[5px] rounded-md border bg-white group-data-[modal=open]:flex">
        {CONSULTATION_PROCESS[deps].map((item) => (
          <ProcessButton
            key={item.id}
            status={item.status}
            props={{ value: item.value, disabled, onClick: handleChangeStatus }}
          >
            {item.text}
          </ProcessButton>
        ))}
      </div>
    </div>
  );
}
