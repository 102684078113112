import React, { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import Nav from "@/components/nav";
import { IconAngleDown, IconClose, IconInfo } from "@/components/icons";
import { eventApprovalFetch, eventFetch, hospitalFetch } from "@/api";

import EventCategorySection from "@/pages/event/regist/components/EventCategorySection";
import { EVENT_REGIST_NAV_LIST } from "@/pages/event/regist/constants";

const hospitalListQuery = {
  queryKey: ["hospital", "list", "size=50"],
  queryFn: async () => {
    const response = await hospitalFetch.get("size=50");

    return response.data.items;
  }
};

export const EventRegistLoader = (queryClient: QueryClient) => () => {
  return queryClient.ensureQueryData(hospitalListQuery);
};

export default function EventRegistPage() {
  const loaderData = useLoaderData();
  const navigate = useNavigate();

  const [selectHospital, setSelectHospital] = useState<any>(null);
  const [useEndDate, setUseEndDate] = useState(false);
  const [tags, setTags] = useState<any[]>([]);
  const [optionCount, setOptionCount] = useState(1);
  const [multiImage, setMultiImage] = useState<any>([]);

  const { data: hospitalList } = useQuery<any>({ ...hospitalListQuery, initialData: loaderData });

  const { data: eventList } = useQuery<any>({
    queryKey: ["event", "approval", "list", `hospital_title=${selectHospital?.hospital_id}`],
    queryFn: async () => {
      const response = await eventApprovalFetch.get(`hospital_id=${selectHospital?.hospital_id}`);

      return response.data.items;
    },
    enabled: !!selectHospital
  });

  const mutation = useMutation({
    mutationFn: async (body: FormData) => eventFetch.post(body),
    onSuccess: () => {
      alert("good");
      navigate("/event/list");
    },
    onError: () => {
      alert("failed");
    }
  });

  const handleRegistSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const newForm = new FormData();

    formData.forEach((value, key) => {
      if (value) {
        if (key === "treatments") {
          const arr = formData.getAll(key);
          arr.forEach((value2, index2) => {
            newForm.set(`medical_category[${index2}].m_c_i_id`, value2);
            newForm.set(`medical_category[${index2}].state`, "INSERT");
            newForm.set(`medical_category[${index2}].classify`, "SUBJECT");
          });
        } else if (key === "open_end_date") {
          newForm.set(
            key,
            dayjs
              .tz(value as string)
              .utc()
              .add(1, "day")
              .subtract(1, "millisecond")
              .format("YYYY-MM-DD HH:mm:ss.SSS")
          );
        } else if (key === "open_start_date") {
          newForm.set(
            key,
            dayjs
              .tz(value as string)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss.SSS")
          );
        } else if (key === "tags") {
          const arr = formData.getAll(key);
          arr.forEach((value2, index2) => {
            newForm.set(`tags[${index2}]`, value2);
          });
        } else if (key.includes("option")) {
          const index = key.replace("option", "") || "0";
          const arr = formData.getAll(key);
          newForm.set(`items[${index}].item_title`, arr[0]);
          newForm.set(`items[${index}].price`, arr[1]);
          newForm.set(`items[${index}].discount`, arr[2]);
        } else if (key === "thumbnail") {
          newForm.set(`thumb_big_image`, value);
        } else if (key === "multiple") {
          const arr = formData.getAll(key);
          arr.forEach((value2, index2) => {
            newForm.set(`detail_multi_image[${index2}]`, value2);
          });
        } else if (key === "intro-image") {
          newForm.set(`des_image`, value);
        } else {
          if (key !== "endDate") newForm.set(key, value);
        }
      }
    });

    mutation.mutate(newForm);
  };

  const handleRegistReset = () => {
    navigate("/event/list");
  };

  return (
    <div>
      <Nav list={EVENT_REGIST_NAV_LIST} />
      <div className="h-full bg-white px-[30px] py-[20px]">
        {/* 제목 */}
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h2 className="text-[18px] font-semibold">이벤트 등록</h2>
        </div>
        {/* 병원 선택 */}
        <div className="relative mb-[20px] h-[40px] w-[400px] rounded-md border-2">
          <select
            className="absolute left-0 top-0 z-10 h-full w-full appearance-none bg-transparent pl-[10px] pr-[20px]"
            name="hospital_id"
            defaultValue=""
            required
            onChange={(e) => {
              const hospitalId = +e.currentTarget.value;

              setSelectHospital(hospitalList.find((item: any) => item.hospital_id === hospitalId) ?? null);
            }}
          >
            <option value="" hidden>
              병원선택
            </option>
            {hospitalList &&
              hospitalList.map((item: any) => (
                <option key={`hospital-list-${item.hospital_id}`} value={item.hospital_id}>
                  {item.title}
                </option>
              ))}
          </select>
          <span className="absolute right-[5px] top-1/2 -translate-y-1/2">
            <IconAngleDown fill="black" />
          </span>
        </div>
        {/* 입력 폼 */}
        <form className="flex w-[1000px] flex-col gap-[10px]" onReset={handleRegistReset} onSubmit={handleRegistSubmit}>
          {/* 1. 이벤트 연결 */}
          <div className="rounded-xl border-2">
            <div className="border-b-2 px-[15px]">
              <h3 className="h-[40px] text-[14px] font-medium leading-[40px]">이벤트 연결</h3>
            </div>
            <div className="p-[20px]">
              <label>
                <div className="relative w-[80%]">
                  <select
                    className="h-[40px] w-full appearance-none rounded-md border px-[10px]"
                    name="base_event_id"
                    defaultValue=""
                    onClick={(e) => {
                      const childCount = e.currentTarget.childElementCount;
                      if (childCount > 1) return;
                      alert("연결할 이벤트가 없습니다.");
                    }}
                  >
                    <option value="" hidden>
                      이벤트 검색
                    </option>
                    {eventList &&
                      eventList.map((item: any) => (
                        <option key={`event-list-${item.event_id}`} value={item.event_id}>
                          {item.event_title}
                        </option>
                      ))}
                  </select>
                  <IconAngleDown className="absolute right-[10px] top-1/2 -translate-y-1/2" />
                </div>
              </label>
              <div className="mb-[20px] mt-[5px] flex gap-[8px]">
                <IconInfo className="fill-form-green" />
                <div>
                  <p>같은 시술의 이벤트를 다른 국가의 고객에게 제공하는 경우, 이벤트를 연결할 수 있습니다.</p>
                  <p>단, 연결할 이벤트의 시술형 태그는 동일해야 합니다.</p>
                </div>
              </div>
            </div>
          </div>
          {/* 2. 이벤트 분류 */}
          <div className="rounded-xl border-2">
            <div className="border-b-2 px-[15px]">
              <h3 className="h-[40px] text-[14px] font-medium leading-[40px]">이벤트 분류</h3>
            </div>
            <div className="border-b p-[20px]">
              <h4 className="label-required mb-[8px] inline-block text-form-purple">대상 국가</h4>
              <fieldset className="flex gap-[12px]">
                <label>
                  <input type="radio" className="mr-[5px] align-middle" name="local" value="KO" required />
                  <span className="align-middle">한국(한국어)</span>
                </label>
                <label>
                  <input type="radio" className="mr-[5px] align-middle" name="local" value="EN" required />
                  <span className="align-middle">영어권(영어)</span>
                </label>
                <label>
                  <input type="radio" className="mr-[5px] align-middle" name="local" value="JA" required />
                  <span className="align-middle">일본(일본어)</span>
                </label>
                <label>
                  <input type="radio" className="mr-[5px] align-middle" name="local" value="VI" required />
                  <span className="align-middle">베트남(베트남어)</span>
                </label>
              </fieldset>
            </div>
            <EventCategorySection />
          </div>
          {/* 3. 이벤트 정보 */}
          <div className="rounded-xl border-2">
            {/* 제목 */}
            <div className="border-b-2 px-[15px]">
              <h3 className="h-[40px] text-[14px] font-medium leading-[40px]">이벤트 정보</h3>
            </div>
            {/* 이벤트 제목 */}
            <div className="p-[20px]">
              <h4 className="label-required mb-[8px] inline-block text-form-purple">이벤트제목</h4>
              <input
                type="text"
                className="block h-[40px] w-full rounded-md border-2 px-[10px]"
                name="title"
                placeholder="예: 사각턱보톡스 리즈톡스 50유닛,자연유착 쌍커풀"
                pattern="^[\p{L}\p{N}&-_,.\s]+$"
                required
              />
              <div className="mt-[5px] flex gap-[8px]">
                <IconInfo className="fill-form-red" />
                <p className="text-form-red">비용을 입력하지 마세요. &-_,.를 제외한 다른 특수문자 입력 하지 마세요.</p>
              </div>
            </div>
            <div className="border-y p-[20px]">
              <h4 className="label-required mb-[8px] inline-block text-form-purple">시술 설명</h4>
              <div className="relative">
                <textarea
                  className="block h-[100px] w-full resize-none rounded-md border-2 p-[10px]"
                  name="description"
                  placeholder="예: 사각턱보톡스 리즈톡스 50유닛,자연유착 쌍커풀"
                  required
                />
                <span className="absolute bottom-[5px] right-[10px] text-gray-400">0 / 300</span>
              </div>
            </div>
            {/* 이벤트 기간 */}
            <div className="p-[20px]">
              <h4 className="mb-[8px] inline-block text-form-purple">이벤트 기간</h4>
              <div className="flex gap-[40px]">
                <div>
                  <h4 className="label-required mb-[8px] inline-block text-form-purple">시작일</h4>
                  <input
                    type="date"
                    className="block h-[40px] w-[200px] rounded-md border-2 px-[10px]"
                    name="open_start_date"
                    required
                  />
                </div>
                <div>
                  <div>
                    <h4 className="mb-[8px] mr-[15px] inline-block text-form-purple">종료일</h4>
                    <span
                      className="inline-flex aspect-square h-[16px] w-[16px] cursor-default items-center justify-center rounded-full border-2 text-gray-300"
                      title="종료일을 설정하면 시작일과 종료일이 노출돼요"
                    >
                      i
                    </span>
                  </div>
                  <div className="flex items-center">
                    <fieldset
                      className="h-[40px] content-center"
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement;

                        if (target.value === "use") {
                          setUseEndDate(true);
                          return;
                        }
                        setUseEndDate(false);
                      }}
                    >
                      <label className="mr-[20px] align-middle">
                        <input
                          type="radio"
                          className="mr-[5px] align-middle"
                          value="unUse"
                          name="endDate"
                          defaultChecked
                        />
                        없음
                      </label>
                      <label className="mr-[20px] align-middle">
                        <input type="radio" className="mr-[5px] align-middle" value="use" name="endDate" />
                        종료일 설정
                      </label>
                    </fieldset>
                    {useEndDate && (
                      <input
                        type="date"
                        className="block h-[40px] w-[200px] rounded-md border-2 px-[10px]"
                        name="open_end_date"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* 대상 성별 */}
            <div className="p-[20px]">
              <h4 className="label-required mb-[8px] inline-block text-form-purple">대상 성별</h4>
              <div className="flex gap-[10px]">
                <label className="align-middle">
                  <input
                    type="radio"
                    className="mr-[5px] align-middle"
                    name="gender"
                    value="MAN"
                    defaultChecked
                    required
                  />
                  남성
                </label>
                <label className="align-middle">
                  <input type="radio" className="mr-[5px] align-middle" name="gender" value="WOMAN" required />
                  여성
                </label>
                <label className="align-middle">
                  <input type="radio" className="mr-[5px] align-middle" name="gender" value="NONE" required />
                  표기안함
                </label>
              </div>
            </div>
            {/* 자유 태그 */}
            <div className="p-[20px]">
              <h4 className="mb-[8px] inline-block text-form-purple">자유태그</h4>
              <div className="flex w-[80%] flex-wrap gap-[5px] rounded-md border-2 px-[10px] py-[5px]">
                {tags.map((tag) => (
                  <div
                    key={`tags-${tag.index}-${Math.floor(Math.random() * 1000)}`}
                    className="flex h-[40px] items-center gap-[10px] rounded-md bg-stone-200 px-[10px]"
                  >
                    <label>
                      <input type="text" className="sr-only" name="tags" defaultValue={tag.text} readOnly />
                      {tag.text}
                    </label>
                    <button
                      type="button"
                      value={tag.index}
                      onClick={(e) => {
                        const index = +e.currentTarget.value;

                        setTags(tags.filter((prevTag) => prevTag.index !== index));
                      }}
                    >
                      <IconClose />
                    </button>
                  </div>
                ))}
                <input
                  type="text"
                  className="h-[40px] flex-grow px-[5px]"
                  placeholder="단어를 입력하고 엔터키를 누르면 등록됩니다."
                  onKeyDown={(e) => {
                    if (e.nativeEvent.isComposing) return;

                    const text = e.currentTarget.value;
                    if (e.key === "Enter") {
                      e.currentTarget.value = "";
                      e.preventDefault();

                      if (text.trim()) {
                        setTags((prev) => [...prev, { index: prev.length + 1, text }]);
                      }
                    }
                  }}
                />
              </div>
              <div className="mb-[20px] mt-[5px] flex gap-[8px]">
                <IconInfo className="fill-form-red" />
                <p className="text-form-red">이벤트와 관련없는 태그는 통보없이 삭제될 수 있습니다.</p>
              </div>
            </div>
          </div>
          {/* 4. 가격 정보 */}
          <div className="rounded-xl border-2">
            {/* 제목 */}
            <div className="border-b-2 px-[15px]">
              <h3 className="h-[40px] text-[14px] font-medium leading-[40px]">가격 정보</h3>
            </div>
            {/* VAT */}
            <div className="p-[20px]">
              <div>
                <h4 className="label-required mb-[8px] mr-[12px] inline-block text-form-purple">VAT</h4>
                <span
                  className="inline-flex aspect-square h-[16px] w-[16px] cursor-default items-center justify-center rounded-full border-2 text-gray-300"
                  title="예시) 치아교정, 스케일링등 미용목적이 아닌 시술만 VAT 비대상 진료 가능"
                >
                  i
                </span>
              </div>
              <div className="flex gap-[20px]">
                <label>
                  <input type="radio" className="mr-[5px] align-middle" name="is_vat" value="true" required />
                  <span className="align-middle">포함</span>
                </label>
                <label>
                  <input type="radio" className="mr-[5px] align-middle" name="is_vat" value="false" required />
                  <span className="align-middle">비대상</span>
                </label>
              </div>
              <div className="mt-[5px] flex gap-[8px]">
                <IconInfo fill="black" />
                <p>VAT가 부과되는 시술은 VAT가 포함된 가격으로 입력해 주세요.</p>
              </div>
            </div>
            {/* 마취비 */}
            <div className="p-[20px]">
              <div>
                <h4 className="label-required mb-[8px] mr-[12px] inline-block text-form-purple">마취비</h4>
                <span
                  className="inline-flex aspect-square h-[16px] w-[16px] cursor-default items-center justify-center rounded-full border-2 text-gray-300"
                  title="예시) 가슴성형수술의 경우 포함, 레이저리프팅의 경우 없음"
                >
                  i
                </span>
              </div>
              <div className="flex gap-[20px]">
                <label>
                  <input type="radio" className="mr-[5px] align-middle" name="is_anesthesia" value="true" required />
                  <span className="align-middle">포함</span>
                </label>
                <label>
                  <input type="radio" className="mr-[5px] align-middle" name="is_anesthesia" value="false" required />
                  <span className="align-middle">없음</span>
                </label>
              </div>
              <div className="mt-[5px] flex gap-[8px]">
                <IconInfo fill="black" />
                <p>
                  [없음]은 추가 비용이 드는 수면마취가 필수적이지 않은 경우에만 가능해요. 이를 어길시 패널티가 적용될 수
                  있어요.
                </p>
              </div>
            </div>
            {/* 사후관리비 */}
            <div className="p-[20px]">
              <div>
                <h4 className="label-required mb-[8px] mr-[12px] inline-block text-form-purple">사후관리비</h4>
                <span
                  className="inline-flex aspect-square h-[16px] w-[16px] cursor-default items-center justify-center rounded-full border-2 text-gray-300"
                  title="예시) 가슴성형수술의 경우 포함, 레이저리프팅의 경우 없음"
                >
                  i
                </span>
              </div>
              <div className="flex gap-[20px]">
                <label>
                  <input type="radio" className="mr-[5px] align-middle" name="is_after" value="true" />
                  <span className="align-middle">포함</span>
                </label>
                <label>
                  <input type="radio" className="mr-[5px] align-middle" name="is_after" value="false" />
                  <span className="align-middle">없음</span>
                </label>
              </div>
              <div className="mt-[5px] flex gap-[8px]">
                <IconInfo fill="black" />
                <p>[없음]은 사후관리가 필수적이지 않은 경우에만 가능해요. 이를 어길시 패널티가 적용될 수 있어요.</p>
              </div>
            </div>
          </div>
          {/* 5. 옵션 */}
          {Array.from({ length: optionCount }).map((_, index) => (
            <div key={`option-${index}`} className="rounded-xl border-2">
              {/* 제목 */}
              <div className="border-b-2 px-[15px]">
                <h3 className="h-[40px] text-[14px] font-medium leading-[40px]">{`옵션 ${index + 1}`}</h3>
              </div>
              {/* 컨텐츠 */}
              <div className="flex gap-[20px] p-[20px]">
                {/* 옵션명 */}
                <div>
                  <div>
                    <h4 className="label-required mb-[8px] mr-[12px] inline-block text-form-purple">옵션명</h4>
                  </div>
                  <div className="relative w-fit">
                    <input
                      type="text"
                      className="h-[40px] w-[500px] rounded-md border-2 px-[10px]"
                      name={`option${index}`}
                      placeholder="예: 사각턱보톡스 리즈톡스 50유닛 (사각턱,팔자주름 부위 택1)"
                      maxLength={40}
                      required
                      onChange={(e) => {
                        const el = e.currentTarget;
                        const textLength = el.value.length;

                        const nextEl = el.nextElementSibling as HTMLElement;

                        nextEl.innerHTML = `${textLength} / 40`;
                      }}
                    />
                    <span className="absolute bottom-[10px] right-[10px] text-gray-400">0 / 40</span>
                  </div>
                </div>
                <fieldset
                  className="flex gap-[20px]"
                  onChange={(e) => {
                    const el = e.target as HTMLInputElement;

                    const optionName = el.name;

                    const parent = e.currentTarget;

                    const price = +(
                      parent.querySelector(`input[name=${optionName}][data-type=price]`) as HTMLInputElement
                    ).value;
                    const discount = +(
                      parent.querySelector(`input[name=${optionName}][data-type=discount]`) as HTMLInputElement
                    ).value;

                    const rateEl = parent.querySelector(`span#${optionName}`) as HTMLSpanElement;

                    const isValueNaN = isNaN(price) || isNaN(discount);

                    if (isValueNaN) {
                      alert("입력 값이 정확하지 않습니다.");
                      return;
                    }

                    const discountRate = 100 - (discount / price) * 100;

                    rateEl.innerHTML = `${discountRate.toFixed(2)}%`;
                  }}
                >
                  {/* 정상가 */}
                  <div>
                    <div>
                      <h4 className="label-required mb-[8px] mr-[12px] inline-block text-form-purple">정상가</h4>
                    </div>
                    <div className="flex h-[40px] w-[160px] rounded-md border-2">
                      <input
                        type="text"
                        className="w-0 flex-1 border-r-2 px-[5px]"
                        name={`option${index}`}
                        data-type="price"
                        pattern="[\d]+"
                        required
                      />
                      <span className="block aspect-square h-full content-center bg-gray-100 text-center">원</span>
                    </div>
                  </div>
                  {/* 할인가 */}
                  <div>
                    <div>
                      <h4 className="label-required mb-[8px] mr-[12px] inline-block text-form-purple">할인가</h4>
                    </div>
                    <div className="flex h-[40px] w-[160px] rounded-md border-2">
                      <input
                        type="text"
                        className="w-0 flex-1 border-r-2 px-[5px]"
                        name={`option${index}`}
                        data-type="discount"
                        pattern="[\d]+"
                        required
                      />
                      <span className="block aspect-square h-full content-center bg-gray-100 text-center">원</span>
                    </div>
                  </div>
                  {/* 할인율 */}
                  <div>
                    <div>
                      <h4 className="label-required mb-[8px] mr-[12px] inline-block text-form-purple">할인율</h4>
                    </div>
                    <div className="flex h-[40px] w-[80px] items-center justify-end rounded-md border-2 bg-gray-100 px-[10px]">
                      <span id={`option${index}`} className="text-form-purple">
                        0%
                      </span>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          ))}
          {/* 6. 옵션 추가 버튼 */}
          <div>
            <button
              type="button"
              className="block h-[40px] w-full rounded-md bg-btn-red text-white"
              onClick={() => {
                setOptionCount((prev) => prev + 1);
              }}
            >
              옵션추가
            </button>
          </div>
          {/* 7. 이벤트 이미지 */}
          <div className="rounded-xl border-2">
            {/* title */}
            <div className="border-b-2 px-[15px]">
              <h3 className="h-[40px] text-[14px] font-medium leading-[40px]">이벤트 이미지</h3>
            </div>
            {/* 7-1 썸네일 */}
            <div className="py-[30px] pl-[20px] pr-[100px]">
              {/* head */}
              <div className="mb-[10px]">
                <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">
                  이벤트 썸네일 이미지 (1080X1080)
                </h4>
                <label className="cursor-pointer rounded-md bg-form-green px-[20px] py-[5px] text-white">
                  이미지 선택
                  <input
                    type="file"
                    className="sr-only"
                    name="thumbnail"
                    required
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.currentTarget.files;
                      const name = e.currentTarget.name;
                      const reader = new FileReader();

                      if (file && file.length > 0) {
                        reader.readAsDataURL(file[0]);
                        reader.onload = (a) => {
                          const el = document.querySelector(`img[data-type=${name}`) as HTMLImageElement;
                          const dataUrl = a.target?.result as string;

                          el.src = dataUrl;
                        };
                      }
                    }}
                  />
                </label>
              </div>
              {/* content */}
              <div>
                <div className="relative mb-1 w-[100px] rounded-md border-2 bg-green-200">
                  <img src={""} alt="" data-type="thumbnail" className="aspect-square" />
                  <button
                    type="button"
                    className="absolute right-2 top-1 bg-transparent"
                    onClick={(e) => {
                      const imgEl = e.currentTarget.previousElementSibling as HTMLImageElement;
                      const inputEl = document.querySelector("input[type=file][name=thumbnail]") as HTMLInputElement;

                      imgEl.src = "";

                      inputEl.files = null;
                      inputEl.value = "";
                    }}
                  >
                    <IconClose width={12} height={12} />
                  </button>
                </div>
              </div>
              {/* footer */}
              <div className="flex gap-[10px]">
                <IconInfo className="fill-form-green" />
                <span>1080px * 1080px, 최대 2M, 이벤트를 대표하는 이미지를 등록해 주세요.</span>
              </div>
            </div>
            {/* 7-3 이벤트 상단 이미지 */}
            <div className="py-[30px] pl-[20px] pr-[100px]">
              {/* head */}
              <div className="mb-[30px]">
                <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">
                  이벤트 상품 상세페이지 상단 이미지 (1080X468)
                </h4>
                <label className="cursor-pointer rounded-md bg-form-green px-[20px] py-[5px] text-white">
                  이미지 선택
                  <input
                    type="file"
                    className="sr-only"
                    name="multiple"
                    multiple
                    accept="image/*"
                    required
                    onChange={(e) => {
                      const files = e.currentTarget.files;

                      if (!files) return;

                      const filesLength = files.length;

                      if (filesLength <= 0) return;

                      for (let i = 0; i < filesLength; i++) {
                        const reader = new FileReader();

                        reader.readAsDataURL(files[i]);

                        reader.onload = (readE) => {
                          const imgUrl = readE.target?.result as string;

                          const hasImage = multiImage.findIndex((item: any) => item.image === imgUrl);

                          if (hasImage !== -1) {
                            alert("이미 존재하는 이미지입니다.");
                            return;
                          }

                          setMultiImage((prev: any) => [...prev, { image: imgUrl, file: files[i] }]);
                        };
                      }
                    }}
                  />
                </label>
              </div>
              {/* content */}

              <div className="mb-2 flex flex-wrap gap-2">
                {multiImage.length > 0 &&
                  multiImage.map((item: any, index: number) => (
                    <div className="relative w-[300px] bg-green-200">
                      <img src={item.image} alt={`multi-${index}`} className="aspect-[1080/468] rounded-md" />
                      <button
                        type="button"
                        className="absolute right-2 top-1"
                        value={item.image}
                        onClick={(e) => {
                          const url = e.currentTarget.value;

                          const checkItem = multiImage.filter((prevItem: any) => prevItem.image !== url);

                          if (checkItem < 1) {
                            const el = document.querySelector("input[name=multiple") as HTMLInputElement;
                            el.files = null;
                            el.value = "";
                          }
                          setMultiImage(checkItem);
                        }}
                      >
                        <IconClose width={12} height={12} />
                      </button>
                    </div>
                  ))}
              </div>

              {/* footer */}
              <div className="flex gap-[10px]">
                <IconInfo className="fill-form-green" />
                <span>1080px * 468px, 최대 2M, 이벤트를 대표하는 이미지를 등록해 주세요.</span>
              </div>
            </div>
            {/* 7-4 이미지 상세 */}
            <div className="py-[30px] pl-[20px] pr-[100px]">
              <div className="mb-[30px]">
                <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">
                  이벤트 상품 소개이미지
                </h4>
                <label className="inline rounded-md bg-form-green px-[20px] py-[5px] text-white">
                  이미지 선택
                  <input
                    type="file"
                    className="sr-only"
                    name="intro-image"
                    required
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.currentTarget.files;
                      const name = e.currentTarget.name;
                      const reader = new FileReader();

                      if (file && file.length > 0) {
                        reader.readAsDataURL(file[0]);
                        reader.onload = (a) => {
                          const el = document.querySelector(`img[data-type=${name}`) as HTMLImageElement;
                          const dataUrl = a.target?.result as string;

                          el.src = dataUrl;
                        };
                      }
                    }}
                  />
                </label>
              </div>
              <div>
                <div className="relative mb-2 h-[1200px] w-[768px] overflow-y-scroll bg-green-200">
                  <img src={""} alt="" className="rounded-md object-contain" data-type="intro-image" />
                  <button
                    type="button"
                    className="absolute right-[10px] top-[10px]"
                    onClick={(e) => {
                      const imgEl = e.currentTarget.previousElementSibling as HTMLImageElement;
                      const inputEl = document.querySelector("input[type=file][name=intro-image]") as HTMLInputElement;

                      imgEl.src = "";

                      inputEl.files = null;
                      inputEl.value = "";
                    }}
                  >
                    <IconClose width={12} height={12} />
                  </button>
                </div>
                <div className="flex gap-[10px]">
                  <IconInfo className="fill-form-green" />
                  <span>이미지 크기는 W = 1080px, 최대 5M</span>
                </div>
              </div>
            </div>
          </div>
          {/* 8. 부작용 info */}
          <div className="rounded-xl border-2">
            {/* 제목 */}
            <div className="border-b-2 px-[15px]">
              <h3 className="h-[40px] text-[14px] font-medium leading-[40px]">부작용 안내</h3>
            </div>
            {/* 입력 양식 컨테이너 */}
            <div className="flex items-end gap-[10px] p-[20px]">
              <textarea
                className="flex-1 resize-none rounded-md border-2 px-[15px] py-[10px]"
                name="side_effect_des"
                rows={8}
                maxLength={300}
                placeholder={`수술/시술에 따라 전형적으로 발생되는 휴유증 또는 부작용을 알려주세요.\n\n- 예 : 수술/시술 후 일반적으로 발생할 수 있는 출혈,감염,염증 등 부작용들은 개인에 따라 차이가 있을 수 있으므로 주의해 주세요.\n\n- 예 : 일시적으로 색소 침착이나 내출혈,작열감,반흔이 발생할 수 있습니다.`}
              />
              <span className="text-gray-400">0 / 300</span>
            </div>
          </div>
          {/* 9. submit btn */}
          <div className="mt-[50px] flex justify-end gap-[20px]">
            <button type="reset" className="bg-btn-gray px-[40px] py-[6px] text-white">
              취소
            </button>
            <button type="submit" className="bg-btn-red px-[40px] py-[6px] text-white">
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
