import React from "react";

import { useFieldMedicalHook } from "@/pages/hospital/info/hooks";
import { Box } from "@/pages/hospital/info/components";

export default function FieldMedical() {
  const { fieldData, required, medicalData, handleChange, handleClick } = useFieldMedicalHook();

  return (
    <Box>
      <div className="mb-[10px]">
        <label htmlFor="h-address" className="label-required mr-[30px] text-[14px] font-medium text-form-purple">
          진료과목
        </label>
        <select
          className="h-[30px] rounded-md border-2 px-[10px]"
          name="medical_category"
          defaultValue=""
          required={required}
          onChange={handleChange}
        >
          <option value="" hidden>
            선택해 주세요
          </option>
          {medicalData.map((item: any) => (
            <option key={`common-medical-option-key-${item.medical_id}`} value={item.medical_id}>
              {item.text.find((v: any) => v.local === "ko")?.title}
            </option>
          ))}
        </select>
      </div>
      <div className="flex gap-[10px] rounded-md border-2 p-[10px]">
        {fieldData
          .filter((item) => item.state !== "DELETE")
          .map((item: any) => (
            <button
              type="button"
              key={`common-medical-key-${item.m_c_i_id}`}
              className="h-[30px] rounded-md bg-slate-300 px-[15px] after:ml-[10px] after:[content:'X']"
              value={item.m_c_i_id}
              onClick={handleClick}
            >
              {
                medicalData
                  ?.find((data: any) => data.medical_id === item.m_c_i_id)
                  ?.text?.find((v: any) => v.local === "ko")?.title
              }
            </button>
          ))}
      </div>
    </Box>
  );
}
