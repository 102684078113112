export const DOCTOR_TYPE = [
  {
    key: "doctor-type-normal",
    value: "DOCTOR",
    text: "의사"
  },
  {
    key: "doctor-type-o",
    value: "O_DOCTOR",
    text: "한의사"
  },
  {
    key: "doctor-type-dentist",
    value: "DENTIST",
    text: "치과의사"
  }
] as const;
