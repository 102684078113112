import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { useLoaderData, useParams } from "react-router-dom";

import { doctorFetch } from "@/api";

import { query } from "@/pages/hospital/doctor/list/query";

export const useHook = () => {
  const loaderData = useLoaderData();
  const queryClient = useQueryClient();
  const params = useParams();

  const hospitalId = params.hospital_id as string;

  const dialogRef = useRef<HTMLDialogElement>(null);

  const { data, isSuccess } = useQuery<any>({
    ...query(hospitalId),
    initialData: loaderData
  });

  const postMutation = useMutation({
    mutationFn: async (item: any) => doctorFetch.post(item),
    onSuccess: () => {
      alert("등록 성공");

      queryClient.invalidateQueries({ queryKey: query(hospitalId).queryKey });
    },
    onError: () => alert("등록 실패")
  });

  const patchMutation = useMutation({
    mutationFn: async ({ type, body }: any) => doctorFetch.patch(type, body),
    onSuccess: () => {
      alert("수정 성공");
      queryClient.invalidateQueries({ queryKey: query(hospitalId).queryKey });
    },
    onError: () => alert("수정 실패")
  });

  const handleRegisteModalSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);

    postMutation.mutate({
      hospital_id: hospitalId,
      text: [{ local: "ko", title: formData.get("name") }],
      type: formData.get("type"),
      d_license: formData.get("license")
    });
  };

  const handleOpenRegistModal = () => {
    const el = dialogRef.current;
    if (el && !el.open) el.showModal();
  };

  return { dialogRef, data, isSuccess, patchMutation, handleRegisteModalSubmit, handleOpenRegistModal };
};
