import useStore from "@/pages/hospital/info/store";

export const useFieldScheduleHook = () => {
  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, type, name } = e.currentTarget;
    const key = name;

    if (type === "time") {
      if (!value) {
        storeAction.setData(key, "");
        return;
      }

      const formatting = value.replace(":", "");
      storeAction.setData(key, formatting);
      return;
    }

    if (type === "checkbox") {
      const checked = (e.currentTarget as HTMLInputElement).checked;

      storeAction.setData(key, checked);
    }

    storeAction.setLocaleData("ko", { [key]: value });
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    alert("동작하지 않는 기능입니다.");
  };

  return {
    storeData: storeData,
    handleChange,
    handleClick
  };
};
