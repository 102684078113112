import { useFieldLogoHook } from "../../hooks/useFieldLogoHook";
import { IconClose, IconInfo } from "@/components/icons";

export default function FieldLogo() {
  const { logo, required, handleChange, handleClick } = useFieldLogoHook();

  return (
    <div>
      <div className="mb-[10px]">
        <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">병원로고</h4>
        <label className="relative inline cursor-pointer rounded-md bg-form-green px-[20px] py-[5px] text-white">
          이미지 선택
          <input
            type="file"
            className="sr-only bottom-0 left-0 scroll-mt-[50px]"
            accept="image/*"
            required={required}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        {logo?.image_url && logo?.state !== "DELETE" && (
          <div className="relative mb-1 aspect-square max-w-[200px] border-2 bg-green-200">
            <img src={logo?.image_url} alt="" className="aspect-square w-full" />
            <button type="button" className="absolute right-2 top-1" onClick={handleClick}>
              <IconClose width={12} height={12} />
            </button>
          </div>
        )}
        <div className="flex gap-[10px]">
          <IconInfo className="fill-form-green" />
          <span>512px * 512px, 최대 2M</span>
        </div>
      </div>
    </div>
  );
}
