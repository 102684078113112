import { hospitalFetch } from "@/api";
import useStore from "@/pages/hospital/info/store";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

export const useFieldButtonHook = () => {
  const storeData = useStore((state) => state.data);

  const navigate = useNavigate();
  const params = useParams();

  const hospitalId = params.hospital_id as string;

  const patchMutation = useMutation({
    mutationFn: async ({ id, state }: { id: string; state: "WAIT" | "COMPLETE" }) =>
      hospitalFetch.patch("state", id, { state }),
    onSuccess: () => {
      alert("변경에 성공했습니다.");
      navigate("/hospital/list");
    },
    onError: () => {
      alert("실패");
    }
  });

  const handleClick = () => {
    patchMutation.mutate({ id: hospitalId, state: "COMPLETE" });
  };
  return {
    storeData,
    handleClick
  };
};
