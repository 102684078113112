import { forwardRef, useRef } from "react";

import { IconClose } from "@/components/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { eventConsultFetch } from "@/api";
import dayjs from "dayjs";

interface Props {
  title: string;
  label: string;
  type: 0 | 1;
  selectDate: { id: string; date: string };
}

export default forwardRef<HTMLDialogElement, Props>(function DatePickerModal({ title, label, type, selectDate }, ref) {
  const queryClient = useQueryClient();
  const formRef = useRef<HTMLFormElement>(null);

  const mutation = useMutation({
    mutationFn: async (date: string) => eventConsultFetch.patch("date", selectDate.id, { date_type: type, date }),
    onSuccess: () => {
      alert("성공");
      queryClient.invalidateQueries({ queryKey: ["event", "consultation", "process"] });
    },
    onError: () => {
      alert("실패");
    }
  });

  return (
    <dialog
      ref={ref}
      className="rounded-xl"
      onReset={(e) => e.currentTarget.close()}
      onClose={() => formRef.current?.reset()}
    >
      <form
        ref={formRef}
        method="dialog"
        className="w-[400px]"
        onSubmit={(e) => {
          const formData = new FormData(e.currentTarget);

          mutation.mutate(dayjs.tz(formData.get("date") as string).toISOString());
        }}
      >
        <div className="flex h-[50px] items-center justify-between bg-slate-800 px-[20px]">
          <h3 className="text-2xl text-white">{title}</h3>
          <button type="reset">
            <IconClose fill="white" />
          </button>
        </div>
        <div className="p-[20px]">
          <div className="mb-[20px]">
            <label>{label}</label>
            <input
              type="datetime-local"
              className="mt-[5px] block w-full rounded-md border px-[10px] py-[5px]"
              name="date"
              defaultValue={selectDate.date}
            />
          </div>
          <div className="text-right">
            <button className="rounded-3xl bg-red-500 px-[20px] py-[5px] text-white">저장</button>
          </div>
        </div>
      </form>
    </dialog>
  );
});
