import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { commonMedicalCategoryFetch, commonMedicalSubjectFetch, commonMedicalTreatmentFetch } from "@/api";
import { IconAngleDown, IconClose, IconInfo } from "@/components/icons";

export default function EventCategorySection() {
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [treatId, setTreatId] = useState<string | null>(null);
  const [selectItems, setSelectItems] = useState<{}[]>([]);

  const { data: categoryList } = useQuery<any>({
    queryKey: ["common", "medical", "category"],
    queryFn: async () => {
      const response = await commonMedicalCategoryFetch.get();

      return response.data.items;
    }
  });

  const { data: treatmentList } = useQuery<any>({
    queryKey: ["common", "medical", "treatment", categoryId],
    queryFn: async () => {
      const response = await commonMedicalTreatmentFetch.get(`medicalCategoryId=${categoryId}`);

      return response.data.items;
    },
    enabled: !!categoryId
  });

  const { data: treatDetailList } = useQuery<any>({
    queryKey: ["common", "medical", "treatment", "detail", treatId],
    queryFn: async () => {
      const response = await commonMedicalSubjectFetch.get(`medicalCategoryItemId=${treatId}`);

      return response.data.items;
    },
    enabled: !!treatId
  });

  return (
    <div className="p-[20px]">
      <div className="relative mb-[8px] w-fit">
        <h4 className="label-required inline-block text-form-purple">시술분류</h4>
      </div>
      <div className="flex gap-[10px]">
        <div className="relative h-[40px] w-[200px] rounded-md border-2">
          <select
            className="absolute left-0 top-0 z-10 h-full w-full appearance-none bg-transparent pl-[10px] pr-[20px]"
            onClick={(e) => {
              const childCount = e.currentTarget.childElementCount;
              if (childCount > 1) return;
              alert("진료분류 아이템이 없습니다.");
            }}
            onChange={(e) => {
              setCategoryId(e.currentTarget.value);
              setTreatId(null);
            }}
          >
            <option value="" hidden>
              진료분류
            </option>
            {categoryList &&
              categoryList.map((item: any) => (
                <option
                  key={`medical-${item.medical_id}-category-${item.medical_category_id}`}
                  value={item.medical_category_id}
                >
                  {item.text.find((txt: any) => txt.local === "ko").title}
                </option>
              ))}
          </select>
          <div className="absolute right-[5px] top-1/2 -translate-y-1/2">
            <IconAngleDown fill="black" />
          </div>
        </div>
        <div className="relative mb-[10px] h-[40px] w-[200px] rounded-md border-2">
          <select
            className="absolute left-0 top-0 z-10 h-full w-full appearance-none bg-transparent pl-[10px] pr-[20px] disabled:bg-slate-300"
            disabled={!categoryId || !treatmentList}
            onClick={(e) => {
              const childCount = e.currentTarget.childElementCount;
              console.log(childCount);
              if (childCount < 2) alert("시술항목 아이템이 없습니다.");
            }}
            onChange={(e) => setTreatId(e.currentTarget.value)}
          >
            <option value="" hidden>
              시술항목
            </option>
            {treatmentList &&
              treatmentList.map((item: any) => (
                <option
                  key={`medical-treatment-${item.medical_category_item_id}`}
                  value={item.medical_category_item_id}
                >
                  {item.text.find((txt: any) => txt.local === "ko").title}
                </option>
              ))}
          </select>
          <div className="absolute right-[5px] top-1/2 -translate-y-1/2">
            <IconAngleDown fill="black" />
          </div>
        </div>
        <div className="relative mb-[10px] h-[40px] w-[200px] rounded-md border-2">
          <select
            className="absolute left-0 top-0 z-10 h-full w-full appearance-none bg-transparent pl-[10px] pr-[20px] disabled:bg-slate-300"
            disabled={!treatId || !treatDetailList}
            onClick={(e) => {
              const childCount = e.currentTarget.childElementCount;
              if (childCount < 2) alert("세부항목 아이템이 없습니다.");
            }}
            onChange={(e) => {
              const detailId = e.currentTarget.value;

              if (selectItems.findIndex((item: any) => item.id === detailId) !== -1)
                return alert("이미 존재하는 아이템입니다.");

              setSelectItems([
                ...selectItems,
                {
                  m_c_i_id: detailId,
                  title: treatDetailList
                    .find((item: any) => `${item.medical_item_subject_id}` === detailId)
                    .text.find((txt: any) => txt.local === "ko").title,
                  classify: "SUBJECT"
                }
              ]);
            }}
          >
            <option value="" hidden>
              세부항목
            </option>
            {treatDetailList &&
              treatDetailList.map((item: any) => (
                <option
                  key={`medical-treat-detail-${item.medical_item_subject_id}`}
                  value={item.medical_item_subject_id}
                >
                  {item.text.find((txt: any) => txt.local === "ko").title}
                </option>
              ))}
          </select>
          <div className="absolute right-[5px] top-1/2 -translate-y-1/2">
            <IconAngleDown fill="black" />
          </div>
        </div>
      </div>

      {selectItems.length > 0 ? (
        <div className="mb-[5px] flex h-[50px] gap-[10px] rounded-md border-2 px-[10px] py-[5px]">
          {selectItems.map((item: any) => (
            <div
              key={`select-item-${item.m_c_i_id}`}
              className="h-full content-center rounded-md bg-stone-200 px-[10px]"
            >
              <label className="relative mr-[10px] align-middle">
                <input type="text" className="sr-only" name="treatments" value={item.m_c_i_id} readOnly />
                {item.title}
              </label>
              <button
                type="button"
                value={item.m_c_i_id}
                onClick={(e) => {
                  setSelectItems(selectItems.filter((v: any) => `${v.m_c_i_id}` !== e.currentTarget.value));
                }}
              >
                <span className="inline-block align-middle">
                  <IconClose />
                </span>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <input type="text" className="sr-only" required />
      )}
      <div className="flex items-center gap-[5px]">
        <IconInfo className="fill-form-red" />
        <p className="text-form-red">이벤트와 관련없는 태그는 통보없이 삭제될 수 있습니다.</p>
      </div>
    </div>
  );
}
