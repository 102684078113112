import React from "react";

import Nav from "@/components/nav";

import { DoctorListHead, DoctorListTable, DoctorRegistModal } from "./components";
import { NAV_LIST } from "./constant";

export default function DoctorListPage() {
  return (
    <div>
      <Nav list={NAV_LIST} />
      <DoctorListHead />
      <DoctorListTable />
      <DoctorRegistModal />
    </div>
  );
}
