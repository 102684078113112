import React from "react";

import { useFieldMultiHook } from "../../hooks/useFieldMultiHook";
import { IconClose, IconInfo } from "@/components/icons";

export default function FieldMulti() {
  const { multi, required, handleChange, handleClick } = useFieldMultiHook();

  return (
    <div>
      <div className="mb-[30px]">
        <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">병원대표 이미지</h4>
        <label className="relative inline cursor-pointer rounded-md bg-form-green px-[20px] py-[5px] text-white">
          이미지 선택
          <input
            type="file"
            className="sr-only bottom-0 left-0 scroll-mt-[50px]"
            multiple
            accept="image/*"
            required={required}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <div className="mb-2 flex flex-wrap gap-2">
          {multi.map((item, index) => (
            <div
              key={`multi-img-${index}`}
              className="relative w-[300px] bg-green-200 data-[hidden=true]:hidden"
              data-hidden={item?.state === "DELETE"}
            >
              <img src={item?.image_url} alt="" className="aspect-[1080/468] rounded-md object-contain" />
              <button type="button" className="absolute right-2 top-1" value={item?.image_id} onClick={handleClick}>
                <IconClose width={12} height={12} />
              </button>
            </div>
          ))}
        </div>
        <div className="flex gap-[10px]">
          <IconInfo className="fill-form-green" />
          <span>1080px * 468px, 개당 최대 2M 씩, 한개 이상등록 가능</span>
        </div>
      </div>
    </div>
  );
}
