import { create } from "zustand";

import { Etc, LocaleData, LocaleDataItem, Schedule, StoreType } from "./types";

const localeData: LocaleData = {
  ko: null,
  en: null,
  ja: null,
  vi: null
};

const initLocaleData: LocaleDataItem = {
  title: null,
  sub_title: null,
  description: null,
  zipcode: null,
  address: null,
  sub_address: null,
  opening_desc: null,
  thumb_image: null,
  main_multi_image: null,
  des_image: null
};
export const schedule: Schedule = {
  schedule_mon_s: null,
  schedule_mon_e: null,
  schedule_tue_s: null,
  schedule_tue_e: null,
  schedule_wed_s: null,
  schedule_wed_e: null,
  schedule_thu_s: null,
  schedule_thu_e: null,
  schedule_fri_s: null,
  schedule_fri_e: null,
  schedule_sat_s: null,
  schedule_sat_e: null,
  schedule_sun_s: null,
  schedule_sun_e: null,
  schedule_mon_allow: false,
  schedule_tue_allow: false,
  schedule_wed_allow: false,
  schedule_thu_allow: false,
  schedule_fri_allow: false,
  schedule_sat_allow: false,
  schedule_sun_allow: false
};

const etc: Etc = {
  logo_image: null,
  medical_category: null,
  shared_category: null,
  state: "WAIT",
  locale: "ko"
};

const initialData = {
  ...localeData,
  ...schedule,
  ...etc
};

const useStore = create<StoreType>((set) => ({
  data: initialData,
  action: {
    init: (data) => set((prev) => ({ data: { ...prev.data, ...data } })),
    reset: () => set({ data: { ...initialData } }),
    setLocaleData: (locale, data) =>
      set((prev) => {
        const prevData = prev.data[locale] ?? initLocaleData;
        return { data: { ...prev.data, [locale]: { ...prevData, ...data } } };
      }),
    setData: (key, value) => set((prev) => ({ data: { ...prev.data, [key]: value } }))
  }
}));

export default useStore;
