import React from "react";

import { IconClose, IconInfo } from "@/components/icons";

import { useDefaultFormHook } from "@/pages/hospital/doctor/detail/hooks";
import { DOCTOR_TYPE, DOCTOR_STATE } from "@/pages/hospital/doctor/detail/constants";

export default function DefaultForm({ hidden }: { hidden: boolean }) {
  const { storeData, medicalData, medicalCategoryData, handleChange, handleSelectChange, handleClick } =
    useDefaultFormHook();

  return (
    <div className="data-[hidden=true]:hidden" data-hidden={hidden}>
      {/*  의사면허번호 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        {/* 경고문 */}
        <div className="mb-[20px] flex gap-[10px] border-2 border-black bg-yellow-400 px-[10px] py-[6px]">
          <IconInfo fill="white" />
          <p>
            의사 면허증, 전문의 면허증과 입력하신 번호가 동일해야 승인됩니다. (주민등록번호등과 같은 개인정보는
            마스킹처리하여 업로드 해주세요)
          </p>
        </div>
        {/* 제목 */}
        <div className="mb-[5px]">
          <h4 className="label-required mr-[30px] inline rounded-md text-[14px] font-medium text-form-purple">
            의사면허번호
          </h4>
          <span className="text-form-red text-[10px]">
            의사 면허 번호는 등록 이후 수정이 불가하므로 신중하게 입력해주세요.
          </span>
        </div>
        {/* 입력 양식 */}
        <div>
          <input
            type="text"
            className="mb-[10px] block h-[40px] w-[350px] rounded-md border-2 bg-slate-200 px-[10px]"
            defaultValue={storeData.d_license}
            disabled
          />
          <div className="flex gap-[5px] pl-[10px]">
            <IconInfo className="fill-form-green" />
            <p>허위 정보를 입력할 경우 불이익이 있을 수 있습니다.</p>
          </div>
        </div>
      </div>
      {/*  의사 분류 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        {/* 제목 */}
        <div className="mb-[5px]">
          <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">의사 분류</h4>
        </div>
        {/* 입력 양식 */}
        <div>
          <select
            className="mb-[10px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] disabled:bg-slate-200"
            value={storeData.type}
            disabled={!!storeData.type}
          >
            {DOCTOR_TYPE.map((item) => (
              <option key={`doctor-type-${item.id}`} value={item.id}>
                {item.text}
              </option>
            ))}
          </select>
          <div className="flex gap-[5px] pl-[10px]">
            <IconInfo className="fill-form-green" />
            <p>선택 후 수정 불가합니다.</p>
          </div>
        </div>
      </div>
      {/*  면허증 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        {/* 제목 */}
        <div className="mb-[5px]">
          <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">의사면허증</h4>
        </div>
        {/* 입력 양식 */}
        <div>
          <label className="relative mb-[10px] box-content flex aspect-square w-[300px] cursor-pointer flex-col items-center justify-center gap-[20px] border-2 bg-slate-200">
            <span className="image-plus-box" />
            이미지 추가
            <img
              src={storeData.d_license_image?.image_url}
              alt="의사면허증"
              className="absolute z-10 h-full w-full object-contain"
              width={300}
              height={300}
              hidden={!storeData.d_license_image}
            />
            <input
              type="file"
              className="sr-only"
              name="d_license_image"
              required={!storeData.d_license_image}
              accept="image/*"
              onChange={handleChange}
            />
          </label>
          <div className="ml-[10px] flex gap-[5px]">
            <IconInfo />
            <p>300px * 300px, 최대 2MB</p>
          </div>
        </div>
      </div>
      {/*  프로필 & 성별 & 대표원장 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        {/* 프로필  */}
        <div className="mb-[30px]">
          {/* 제목 */}
          <div className="mb-[5px]">
            <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">
              의사 프로필 사진
            </h4>
          </div>
          {/* 입력 양식 */}
          <div>
            <label className="relative mb-[10px] box-content flex aspect-square w-[300px] cursor-pointer flex-col items-center justify-center gap-[20px] border-2 bg-slate-200">
              <span className="image-plus-box" />
              <span>이미지 추가</span>
              <img
                src={storeData.profile_image?.image_url}
                alt="의사프로필"
                className="absolute z-10 h-full w-full object-contain"
                width={300}
                height={300}
                hidden={!storeData.profile_image}
              />
              <input
                type="file"
                className="sr-only"
                required={!storeData.profile_image}
                name="profile_image"
                accept="image/*"
                onChange={handleChange}
              />
            </label>
            <div className="ml-[10px] flex gap-[5px]">
              <IconInfo />
              300px * 300px, 최대 2MB
            </div>
          </div>
        </div>
        {/* 의사 성별 & 대표원장 */}
        <div>
          {/* 제목 */}
          <div className="mb-[10px]">
            <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">성별</h4>
          </div>
          {/* 입력양식 */}
          <div className="flex items-center">
            <input
              type="radio"
              id="male"
              className="mr-[5px]"
              name="gender"
              checked={storeData.gender}
              onChange={handleChange}
            />
            <label htmlFor="male" className="mr-[40px]">
              남성
            </label>
            <input
              type="radio"
              id="female"
              className="mr-[5px]"
              name="gender"
              checked={!storeData.gender}
              onChange={handleChange}
            />
            <label htmlFor="female" className="mr-[50px]">
              여성
            </label>
            <input
              type="checkbox"
              id="director"
              name="is_head_director"
              checked={storeData.is_head_director}
              onChange={handleChange}
            />
            <label htmlFor="director" className="ml-[5px]">
              대표원장 여부
            </label>
            <div className="ml-[20px] flex gap-[5px]">
              <IconInfo className="fill-form-purple" />
              <p>대표원장일 경우 의사명단중 맨 위에 게시됩니다.</p>
            </div>
          </div>
        </div>
      </div>
      {/*  전문의 자격 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        {/* 제목 */}
        <div className="mb-[5px]">
          <h4 className="mr-[30px] inline text-[14px] font-medium text-form-purple">전문의 자격</h4>
        </div>
        {/* 입력 양식 */}
        <div>
          <select
            className="mb-[10px] block h-[40px] w-[350px] rounded-md border-2 px-[10px]"
            name="medical_id"
            value={storeData.medical_id ?? ""}
            onChange={handleSelectChange}
          >
            <option value="" hidden>
              선택해 주세요.
            </option>
            {medicalData &&
              medicalData.map((item: any) => (
                <option value={item.medical_id} key={`doctor-medical-${item.medical_id}`}>
                  {item.text.find((txt: any) => txt.local === "ko")?.title ?? "No Text"}
                </option>
              ))}
          </select>
          <div className="ml-[10px] flex gap-[5px]">
            <IconInfo className="fill-form-green" />
            <p>
              실제 전문의 자격이 있는 전공만 선택해야 합니다. 허위인 경우 경고없이 삭제될 수 있으며, 해당 전공이 없는
              경우 전문의 아님을 선택해 주세요.
            </p>
          </div>
        </div>
      </div>
      {/* 전문의 자격번호 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        {/* 제목 */}
        <div className="mb-[5px]">
          <h4 className="mr-[30px] inline text-[14px] font-medium text-form-purple">전문의 자격번호</h4>
          <span className="text-form-red text-[10px]">
            전문의 자격번호는 등록 이후 수정이 불가하므로 신중하게 입력해주세요.
          </span>
        </div>
        {/* 입력양식 */}
        <div>
          <input
            type="text"
            className="mb-[5px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] placeholder:text-black"
            name="spec_license_no"
            placeholder="숫자만 입력해 주세요."
            value={storeData.spec_license_no ?? ""}
            pattern="^[0-9]+$"
            onChange={handleChange}
          />
          <div className="ml-[10px] flex gap-[5px]">
            <IconInfo className="fill-form-green" />
            <p>허위 정보를 입력할 경우 불이익이 있을 수 있습니다.</p>
          </div>
        </div>
      </div>
      {/* 전문의 자격증 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        {/* 제목 */}
        <div className="mb-[5px]">
          <h4 className="mr-[30px] inline text-[14px] font-medium text-form-purple">전문의 자격증</h4>
        </div>
        {/* 입력양식 */}
        <div>
          <label className="relative mb-[10px] box-content flex aspect-square w-[300px] cursor-pointer flex-col items-center justify-center gap-[20px] border-2 bg-slate-200">
            <span className="image-plus-box" />
            이미지 추가
            <img
              src={storeData.spec_image?.image_url}
              alt="전문의 자격증"
              id="spec"
              className="absolute h-full w-full object-contain"
              width={300}
              height={300}
              hidden={!storeData.spec_image}
            />
            <input type="file" className="sr-only" name="spec_image" accept="image/*" onChange={handleChange} />
          </label>
          <div className="ml-[10px] flex gap-[5px]">
            <IconInfo />
            <p>300px * 300px, 최대 2MB</p>
          </div>
        </div>
      </div>
      {/* 그 외 자격증 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        {/* 제목 */}
        <div>
          <h4 className="mb-[5px] inline-block text-[14px] font-medium text-form-purple">그외 자격증</h4>
        </div>
        {/* 입력양식 */}
        <div className="mb-[10px] grid grid-cols-5 gap-[10px]">
          {storeData.etc_multi_image.map((item, index) => (
            <div
              key={`etc-image-${index}`}
              className="relative col-span-1 box-content flex aspect-square cursor-pointer flex-col items-center justify-center gap-[20px] border-2 bg-slate-200 data-[hidden=true]:hidden"
              data-hidden={item?.state === "DELETE"}
            >
              <span className="image-plus-box" />
              이미지 추가
              <img
                src={item?.image_url}
                alt="그외 자격증"
                className="absolute h-full w-full object-contain"
                width={300}
                height={300}
              />
              <button
                type="button"
                className="absolute right-[5px] top-[5px] border border-black bg-white disabled:hidden"
                data-pair={index}
                value="etc_multi_image"
                onClick={handleClick}
              >
                <IconClose fill={!!item?.image_url ? "black" : "gray"} />
              </button>
            </div>
          ))}
          {storeData.etc_multi_image.filter((item) => item?.state !== "DELETE").length < 10 && (
            <label className="relative col-span-1 flex aspect-square cursor-pointer flex-col items-center justify-center gap-[20px] border-2 bg-slate-200">
              <input
                type="file"
                className="sr-only"
                name="etc_multi_image"
                multiple
                accept="image/*"
                onChange={handleChange}
              />
              <span className="image-plus-box" />
              이미지 추가
            </label>
          )}
        </div>
        <div className="ml-[10px] flex gap-[5px]">
          <IconInfo />
          <p>300px * 300px, 개당 최대 2MB 10개까지 업로드 가능</p>
        </div>
      </div>
      {/*  진료과목 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        <div className="mb-[10px]">
          {/* 제목 */}
          <label className="label-required mr-[30px] text-[14px] font-medium text-form-purple">진료과목</label>
          {/* 입력양식 */}
          <select
            className="h-[30px] rounded-md border-2 px-[10px]"
            name="medical_category"
            defaultValue=""
            required={storeData.medical_category.filter((category) => category.state !== "DELETE").length === 0}
            onChange={handleSelectChange}
          >
            <option value="" hidden>
              선택해 주세요
            </option>
            {medicalCategoryData &&
              medicalCategoryData.map((item: any) => (
                <option value={item.medical_category_id} key={`medical-category-${item.medical_category_id}`}>
                  {item.text.find((txt: any) => txt.local === "ko")?.title ?? "No text"}
                </option>
              ))}
          </select>
        </div>
        {/* 입력 아이템 */}
        <div className="flex gap-[10px] rounded-md border-2 p-[10px]">
          {medicalCategoryData &&
            storeData.medical_category
              .filter((category) => category.state !== "DELETE")
              .map((category) => (
                <button
                  type="button"
                  key={`medical-category-select-${category.m_c_i_id}`}
                  className="h-[30px] rounded-md bg-slate-300 px-[15px] after:ml-[10px] after:[content:'X']"
                  value="medical_category"
                  data-pair={category.m_c_i_id}
                  onClick={handleClick}
                >
                  {
                    medicalCategoryData
                      .find((item: any) => `${item.medical_category_id}` === `${category.m_c_i_id}`)
                      ?.text?.find((txt: any) => txt.local === "ko")?.title
                  }
                </button>
              ))}
        </div>
      </div>
      {/*  상담 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        <div className="mb-[30px]">
          {/* 제목 */}
          <div className="mb-[10px]">
            <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">
              의사 상담가능 여부
            </h4>
          </div>
          {/* 입력양식 */}
          <div>
            <label className="custom-check-button mr-[10px]">
              <input
                type="checkbox"
                className="sr-only"
                name="is_consultation"
                checked={storeData.is_consultation}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        {/* 진료가능 상태 */}
        <div>
          {/* 제목 */}
          <div className="mb-[10px]">
            <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">진료가능 상태</h4>
          </div>
          {/* 입력양식 */}
          <div>
            <select
              className="h-[30px] w-[150px] rounded-md border-2 px-[10px]"
              name="state"
              required
              value={storeData.state}
              onChange={handleSelectChange}
            >
              {DOCTOR_STATE.map((item) => (
                <option key={item.key} value={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {/*  약관 */}
      <div className="border-b-2 py-[30px] pl-[20px] pr-[100px]">
        {/* 제목 */}
        <div className="mb-[10px]">
          <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">약관동의</h4>
        </div>
        {/* 입력양식 */}
        <div>
          <label>
            <input
              type="checkbox"
              className="mr-[5px] align-middle"
              name="is_terms_agreed"
              checked={storeData.is_terms_agreed}
              onChange={handleChange}
            />
            <span className="align-middle">개인정보 수집 이용에 동의합니다.</span>
          </label>
        </div>
      </div>
    </div>
  );
}
