import dayjs from "dayjs";

export const transDate = (date: string, format = "YYYY-MM-DD HH:mm:ss") => {
  if (!date) return "-";
  return dayjs.utc(date).tz().format(format);
};

export const joinMedical = (medicals: any[]) => {
  if (medicals.length < 1) return "-";
  return medicals.map((medi) => medi.title).join(", ");
};
