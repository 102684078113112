import React from "react";

import { IconAngleDown, IconClose, IconInfo } from "@/components/icons";

import { useHook } from "@/pages/hospital/doctor/list/components/modal/hook";
import { DOCTOR_TYPE } from "@/pages/hospital/doctor/list/components/modal/constant";

import { MODAL_ID } from "@/pages/hospital/doctor/list/constant";

export default function Modal() {
  const { handleSubmit, handleReset } = useHook();

  return (
    <dialog id={MODAL_ID} className="rounded-xl" onReset={handleReset}>
      <form method="dialog" className="w-[500px]" onSubmit={handleSubmit}>
        <div className="flex h-[50px] items-center justify-between bg-gray-600 px-[20px] text-white">
          <h3 className="text-[18px]">의사 등록하기</h3>
          <button type="reset">
            <IconClose fill="white" />
          </button>
        </div>
        <div className="p-[20px]">
          <div className="mb-[20px] flex flex-col gap-[20px]">
            <p>의사 등록을 위해 이름, 면허번호를 입력해 주세요.</p>
            <label>
              <span className="mb-[8px] inline-block">의사명</span>
              <input
                type="text"
                className="h-[40px] w-full rounded-md border px-[10px]"
                name="name"
                placeholder="의사명을 입력해주세요."
                required
              />
            </label>
            <label>
              <span className="mb-[8px] block">의사분류</span>
              <div className="relative h-[40px]">
                <select
                  className="absolute z-10 h-full w-full appearance-none rounded-md border bg-transparent px-[10px] invalid:text-gray-400"
                  name="type"
                  defaultValue=""
                  required
                >
                  <option value="" hidden>
                    의사 분류를 선택해주세요
                  </option>
                  {DOCTOR_TYPE.map((item) => (
                    <option key={`doctor-type-${item.key}`} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
                <IconAngleDown fill="#4b5563" className="absolute right-[10px] top-1/2 -translate-y-1/2" />
              </div>
            </label>
            <div>
              <label>
                <span className="mb-[8px] block">의사면허번호</span>
                <input
                  type="number"
                  className="h-[40px] w-full rounded-md border px-[10px] [&::-webkit-inner-spin-button]:appearance-none"
                  name="license"
                  placeholder="숫자만 입력 가능합니다."
                  required
                />
              </label>
              <div className="mb-[5px] mt-[10px] flex items-center gap-[5px]">
                <IconInfo fill="red" />
                <p className="text-red-500">의사 면허번호는 등록 이후 수정이 불가하므로 신중하게 입력해 주세요.</p>
              </div>
              <div className="flex items-center gap-[5px]">
                <IconInfo fill="#a9e086" />
                <p className="inline align-middle">허위 정보를 입력할 경우 불이익이 있을 수 있습니다.</p>
              </div>
            </div>
          </div>
          <div className="h-[30px] text-end">
            <button type="reset" className="mr-[10px] h-full rounded-3xl bg-stone-400 px-[1.5em] text-white">
              취소
            </button>
            <button className="h-full rounded-3xl bg-red-600 px-[1.5em] text-white">등록하기</button>
          </div>
        </div>
      </form>
    </dialog>
  );
}
