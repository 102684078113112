import { doctorDetailFetch } from "@/api";
import { ImageItem } from "../types";

const query = (id: string) => ({
  queryKey: ["doctor", "detail", id],
  queryFn: async () => {
    const response = await doctorDetailFetch.get(id);

    const result = response.data;

    delete result.result_code;

    return {
      ...result,
      etc_multi_image: result.etc_multi_image.map((item: ImageItem) => ({ ...item, state: "STAY" }))
    };
  }
});

export default query;
