import { MODAL_ID } from "@/pages/hospital/doctor/list/constant";

export const useHook = () => {
  const handleClick = () => {
    const el = document.getElementById(MODAL_ID) as HTMLDialogElement;

    if (el && !el.open) el.showModal();
  };

  return { handleClick };
};
