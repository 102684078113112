import React from "react";

import Nav from "@/components/nav";

import { ContactForm, ContactHead } from "@/pages/hospital/contact/components";
import { NAV_LIST } from "@/pages/hospital/contact/constant";

export default function HospitalContactPage() {
  return (
    <div>
      <Nav list={NAV_LIST} />
      <div className="flex flex-col gap-[20px] bg-white px-[30px] py-[20px]">
        <ContactHead />
        <ContactForm />
      </div>
    </div>
  );
}
