import React from "react";

import Nav from "@/components/nav";
import { IconClose, IconInfo } from "@/components/icons";

import { DefaultForm, NameForm } from "@/pages/hospital/doctor/detail/components";
import { useHook } from "@/pages/hospital/doctor/detail/hooks";

export default function DoctorDetailPage() {
  const { tab, dialogRef, isSuccess, handleSubmit, handleReset, handleTabClick } = useHook();

  return (
    <div>
      <Nav
        list={[
          { title: "병원관리", link: "/hospital/list" },
          { title: "의사정보", link: null }
        ]}
      />
      <div className="h-full min-w-full bg-white px-[30px] py-[20px]">
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h2 className="mr-[15px] inline text-[18px] font-semibold">병원 의사정보</h2>
          <p className="inline">해당 병원의 의사정보를 등록하는 페이지 입니다.</p>
        </div>
        {isSuccess && (
          <section>
            <h3 className="mb-[10px] text-[14px] font-medium">병원 의사정보</h3>
            <div>
              {/* form nav */}
              <div className="flex h-[40px]">
                <button
                  type="button"
                  className="relative mt-[2px] h-full w-[100px] rounded-tl-md rounded-tr-md border-2 bg-slate-300 data-[active=true]:border-b-0 data-[active=true]:bg-white"
                  value={"default"}
                  data-active={tab === "default"}
                  onClick={handleTabClick}
                >
                  기본정보
                </button>
                <button
                  type="button"
                  className="relative mt-[2px] h-full w-[100px] rounded-tl-md rounded-tr-md border-2 bg-slate-300 data-[active=true]:border-b-0 data-[active=true]:bg-white"
                  value={"name"}
                  data-active={tab === "name"}
                  onClick={handleTabClick}
                >
                  의사명
                </button>
              </div>
              {/* form */}
              <form
                className="w-[1080px] overflow-hidden rounded-lg rounded-tl-none border-2"
                onSubmit={handleSubmit}
                onReset={handleReset}
              >
                <DefaultForm hidden={!(tab === "default")} />
                <NameForm hidden={!(tab === "name")} />
                {/* button */}
                <div className="flex justify-end gap-[10px] p-[20px] text-sm">
                  <button type="reset" className="rounded-3xl bg-btn-gray px-[20px] py-[6px] text-white">
                    취소
                  </button>
                  <button className="rounded-3xl bg-btn-red px-[20px] py-[6px] text-white">저장하기</button>
                </div>
              </form>
            </div>
          </section>
        )}
      </div>
      <dialog ref={dialogRef} className="rounded-md">
        <form method="dialog" className="relative flex w-[220px] flex-col justify-between bg-white p-[12px]">
          <button className="absolute right-[10px] top-[10px]">
            <IconClose />
          </button>
          <div className="m-auto mb-[8px] flex flex-col items-center gap-[10px] px-[20px]">
            <IconInfo width={50} height={50} fill="blue" />
            <p>약관 동의를 하셔야 저장을 완료 할 수 있습니다.</p>
          </div>
          <div className="text-right">
            <button className="border-2 px-[20px] py-[5px]">확인</button>
          </div>
        </form>
      </dialog>
    </div>
  );
}
