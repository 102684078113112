export const SCHEDULE_LIST = [
  {
    id: "monday",
    allowKey: "schedule_mon_allow",
    startKey: "schedule_mon_s",
    endKey: "schedule_mon_e",
    title: "월요일"
  },
  {
    id: "tuesday",
    allowKey: "schedule_tue_allow",
    startKey: "schedule_tue_s",
    endKey: "schedule_tue_e",
    title: "화요일"
  },
  {
    id: "wednesday",
    allowKey: "schedule_wed_allow",
    startKey: "schedule_wed_s",
    endKey: "schedule_wed_e",
    title: "수요일"
  },
  {
    id: "thursday",
    allowKey: "schedule_thu_allow",
    startKey: "schedule_thu_s",
    endKey: "schedule_thu_e",
    title: "목요일"
  },
  {
    id: "friday",
    allowKey: "schedule_fri_allow",
    startKey: "schedule_fri_s",
    endKey: "schedule_fri_e",
    title: "금요일"
  },
  {
    id: "saturday",
    allowKey: "schedule_sat_allow",
    startKey: "schedule_sat_s",
    endKey: "schedule_sat_e",
    title: "토요일"
  },
  {
    id: "sunday",
    allowKey: "schedule_sun_allow",
    startKey: "schedule_sun_s",
    endKey: "schedule_sun_e",
    title: "일요일"
  }
] as const;
