import React, { useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";

import { query } from "../../query";
import useStore from "@/pages/hospital/info/store";
import { LocaleDataItem, ScheduleKey } from "@/pages/hospital/info/types";
import { i18n, TABS } from "./constants";
import { hospitalDetailFetch } from "@/api";
import { SCHEDULE_LIST } from "../../constants";

export const useHook = () => {
  const navigate = useNavigate();
  const loaderData = useLoaderData();
  const params = useParams();

  const hospitalId = params.hospital_id as string;

  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const { data: detailData } = useQuery<any>({
    ...query(hospitalId),
    initialData: loaderData,
    enabled: !!hospitalId
  });

  const detailMutation = useMutation({
    mutationFn: async () => {
      const promise = await new Promise<FormData>((resolve) => {
        const formData = new FormData();

        SCHEDULE_LIST.forEach((item) => {
          formData.set(item.allowKey, `${storeData[item.allowKey] ?? false}`);
          formData.set(item.startKey, storeData[item.startKey] || "");
          formData.set(item.endKey, storeData[item.endKey] || "");
        });

        if (storeData.shared_category) {
          storeData.shared_category.forEach((cate, index) => {
            formData.set(`shared_category[${index}].shared_category_id`, `${cate.shared_category_id}`);
            formData.set(`shared_category[${index}].classify`, cate.classify);
            formData.set(`shared_category[${index}].state`, cate.state ?? "STAY");
          });
        }
        if (storeData.medical_category) {
          storeData.medical_category.forEach((cate, index) => {
            formData.set(`medical_category[${index}].m_c_i_id`, `${cate.m_c_i_id}`);
            formData.set(`medical_category[${index}].classify`, cate.classify);
            formData.set(`medical_category[${index}].state`, cate.state ?? "STAY");
          });
        }

        if (storeData.logo_image) {
          if (storeData.logo_image?.state === "INSERT") {
            formData.set("logo_image.image", storeData.logo_image?.image);
            formData.set("logo_image.state", "INSERT");
          } else if (storeData.logo_image?.state === "UPDATE") {
            formData.set("logo_image.image_id", `${storeData.logo_image?.image_id}`);
            formData.set("logo_image.image", storeData.logo_image?.image);
            formData.set("logo_image.state", "UPDATE");
          } else if (storeData.logo_image?.state === "DELETE") {
            formData.set("logo_image.image_id", `${storeData.logo_image?.image_id}`);
            formData.set("logo_image.state", "DELETE");
          } else {
            formData.set("logo_image.image_id", `${storeData.logo_image?.image_id}`);
            formData.set("logo_image.state", "STAY");
          }
        }
        TABS.forEach(({ id }) => {
          if (storeData[id] === null) return;

          for (const key in storeData[id]) {
            if (key === "main_multi_image") {
              const imageList = storeData[id][key];

              imageList?.forEach((item, index) => {
                if (item?.state === "INSERT") {
                  formData.set(`${id}.main_multi_image[${index}].image`, item?.image);
                  formData.set(`${id}.main_multi_image[${index}].state`, "INSERT");
                } else if (item?.state === "UPDATE") {
                  formData.set(`${id}.main_multi_image[${index}].image_id`, `${item?.image_id}`);
                  formData.set(`${id}.main_multi_image[${index}].image`, item?.image);
                  formData.set(`${id}.main_multi_image[${index}].state`, "UPDATE");
                } else if (item?.state === "DELETE") {
                  formData.set(`${id}.main_multi_image[${index}].image_id`, `${item?.image_id}`);
                  formData.set(`${id}.main_multi_image[${index}].state`, "DELETE");
                } else if (item) {
                  formData.set(`${id}.main_multi_image[${index}].image_id`, `${item?.image_id}`);
                  formData.set(`${id}.main_multi_image[${index}].state`, "STAY");
                }
              });

              continue;
            }
            if (key === "thumb_image" || key === "des_image") {
              const item = storeData[id][key];
              if (!item) continue;
              if (item?.state === "INSERT") {
                formData.set(`${id}.${key}.image`, item?.image);
                formData.set(`${id}.${key}.state`, "INSERT");
              } else if (item?.state === "UPDATE") {
                formData.set(`${id}.${key}.image_id`, `${item?.image_id}`);
                formData.set(`${id}.${key}.image`, item?.image);
                formData.set(`${id}.${key}.state`, "UPDATE");
              } else if (item?.state === "DELETE") {
                formData.set(`${id}.${key}.image_id`, `${item?.image_id}`);
                formData.set(`${id}.${key}.state`, "DELETE");
              } else {
                formData.set(`${id}.${key}.image_id`, `${item?.image_id}`);
                formData.set(`${id}.${key}.state`, "STAY");
              }
              continue;
            }

            formData.set(`${id}.${key}`, `${storeData[id][key as keyof LocaleDataItem] ?? ""}`);
          }
        });

        resolve(formData);
      });

      await hospitalDetailFetch.put(hospitalId, promise);
    },
    onSuccess: () => {
      alert("SUCCESS");
      navigate(-1);
    },
    onError: () => {
      alert("ERROR");
    }
  });

  const handleReset = () => {
    navigate(-1);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    detailMutation.mutate();
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { name, value } = e.currentTarget;

    if (name === "tab") {
      storeAction.setData("locale", value);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const el = e.currentTarget;
    if (el instanceof HTMLInputElement) {
      const { value, type, name } = el;
      const key = name as ScheduleKey;
      if (type === "time") {
        if (!value) {
          storeAction.setData(key, "");
          return;
        }

        const formatting = value.replace(":", "");
        storeAction.setData(key, formatting);
        return;
      }

      if (type === "checkbox") {
        const checked = (el as HTMLInputElement).checked;

        storeAction.setData(key, checked);
        return;
      }

      storeAction.setLocaleData(storeData.locale, { [key]: value });
    }
    if (el instanceof HTMLTextAreaElement) {
      const { value, name } = el;
      const key = name as ScheduleKey;

      storeAction.setLocaleData(storeData.locale, { [key]: value });
    }
  };

  useEffect(() => {
    if (detailData) storeAction.init(detailData);
    return () => {
      storeAction.reset();
    };
  }, [detailData, storeAction]);

  return {
    i18n: i18n[storeData.locale],
    storeData,
    isKorea: storeData.locale === "ko",
    hospitalId,
    handleChange,
    handleButtonClick,
    handleReset,
    handleSubmit
  };
};
