import React from "react";

import { IconInfo } from "@/components/icons";

import { useNameFormHook } from "@/pages/hospital/doctor/detail/hooks";

export default function NameForm({ hidden }: { hidden: boolean }) {
  const { ko, en, ja, vi, handleChange, handleTextAreaChange } = useNameFormHook();
  return (
    <div className="data-[hidden=true]:hidden" data-hidden={hidden}>
      <div className="flex flex-col gap-[20px] py-[30px] pl-[20px] pr-[100px]">
        {/* 한국어 */}
        <div>
          <div className="mb-[5px]">
            <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">한국어</h4>
          </div>
          {/* 이름 */}
          <div className="mb-[20px]">
            <input
              type="text"
              className="mb-[5px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] placeholder:text-black disabled:bg-slate-200"
              defaultValue={ko?.title}
              disabled
            />
            <div className="flex gap-[5px] pl-[10px]">
              <IconInfo className="fill-form-green" />
              <p>국문 의사명 수정을 요청시 미인 고객센터에 문의 해 주시길 바랍니다.</p>
            </div>
          </div>
          {/* 소개 */}
          <div>
            <label htmlFor="ko-des">경력사항 및 의사소개</label>
            <div className="relative mt-[10px] w-fit">
              <textarea
                id="ko-des"
                className="h-[100px] w-[400px] resize-none rounded-md border p-[10px] pr-[40px]"
                placeholder="경력사항 및 의사소개"
                value={ko?.description ?? ""}
                data-locale="ko"
                maxLength={1000}
                onChange={handleTextAreaChange}
              />
              <span className="absolute bottom-[10px] right-[10px]">{`${ko?.description?.length ?? 0}/1000`}</span>
            </div>
          </div>
        </div>
        {/* English */}
        <div>
          <div className="mb-[5px]">
            <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">영어</h4>
          </div>
          {/* name */}
          <div>
            <input
              type="text"
              className="mb-[5px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] placeholder:text-black"
              placeholder="영문명을 입력해 주세요"
              value={en?.title}
              data-locale="en"
              required
              onChange={handleChange}
            />
          </div>
          {/* 소개 */}
          <div>
            <label htmlFor="en-des">경력사항 및 의사소개</label>
            <div className="relative mt-[10px] w-fit">
              <textarea
                id="en-des"
                className="h-[100px] w-[400px] resize-none rounded-md border p-[10px] pr-[40px]"
                placeholder="경력사항 및 의사소개"
                value={en?.description ?? ""}
                data-locale="en"
                maxLength={1000}
                onChange={handleTextAreaChange}
              />
              <span className="absolute bottom-[10px] right-[10px]">{`${en?.description?.length ?? 0}/1000`}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="mb-[5px]">
            <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">일어</h4>
          </div>
          <div>
            <input
              type="text"
              className="mb-[5px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] placeholder:text-black"
              placeholder="일본어 이름을 입력해 주세요"
              value={ja?.title}
              required
              data-locale="ja"
              onChange={handleChange}
            />
          </div>
          {/* 소개 */}
          <div>
            <label htmlFor="ja-des" className="label-required">
              경력사항 및 의사소개
            </label>
            <div className="relative mt-[10px] w-fit">
              <textarea
                id="ja-des"
                className="h-[100px] w-[400px] resize-none rounded-md border p-[10px] pr-[40px]"
                placeholder="경력사항 및 의사소개"
                value={ja?.description ?? ""}
                required
                data-locale="ja"
                maxLength={1000}
                onChange={handleTextAreaChange}
              />
              <span className="absolute bottom-[10px] right-[10px]">{`${ja?.description?.length ?? 0}/1000`}</span>
            </div>
          </div>
        </div>
        <div>
          <div className="mb-[5px]">
            <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">베트남어</h4>
          </div>
          <div>
            <input
              type="text"
              className="mb-[5px] block h-[40px] w-[350px] rounded-md border-2 px-[10px] placeholder:text-black"
              placeholder="베트남어 이름을 입력해 주세요"
              value={vi?.title}
              required
              data-locale="vi"
              onChange={handleChange}
            />
          </div>
          {/* 소개 */}
          <div>
            <label htmlFor="vi-des" className="label-required">
              경력사항 및 의사소개
            </label>
            <div className="relative mt-[10px] w-fit">
              <textarea
                id="vi-des"
                className="h-[100px] w-[400px] resize-none rounded-md border p-[10px] pr-[40px]"
                placeholder="경력사항 및 의사소개"
                value={vi?.description ?? ""}
                required
                data-locale="vi"
                maxLength={1000}
                onChange={handleTextAreaChange}
              />
              <span className="absolute bottom-[10px] right-[10px]">{`${vi?.description?.length ?? 0}/1000`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
