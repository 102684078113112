import { useQuery } from "@tanstack/react-query";

import { commonMedicalCategoryFetch, commonMedicalFetch } from "@/api";

import { useStore } from "@/pages/hospital/doctor/detail/hooks";
import { ImageItem } from "@/pages/hospital/doctor/detail/types";
import { MedicalItem } from "@/types/medical";
import { MedicalCategoryItem } from "@/types/medicalCategory";

const useDefaultFormHook = () => {
  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const { data: medicalData } = useQuery<MedicalItem[]>({
    queryKey: ["medical", "isOpenAllow"],
    queryFn: async () => {
      const response = await commonMedicalFetch.get();

      const result = response.data.items.filter((item: any) => item.is_open_allow);

      return result;
    }
  });

  const { data: medicalCategoryData } = useQuery<MedicalCategoryItem[]>({
    queryKey: ["medical", "category", "isOpenAllow"],
    queryFn: async () => {
      const response = await commonMedicalCategoryFetch.get();

      const result = response.data.items.filter((item: any) => item.is_open_allow);

      return result;
    }
  });

  const fileChange = ({ name, files }: HTMLInputElement) => {
    if (!files || files.length < 1) return;

    if (name !== "d_license_image" && name !== "profile_image" && name !== "spec_image") return;

    const data = storeData[name];
    const fileReader = new FileReader();

    fileReader.onload = (load) => {
      const url = load.target?.result as string;

      let item: ImageItem;

      item = {
        image: files[0],
        image_url: url,
        state: "INSERT"
      };

      if (data?.state === "STAY") {
        item = {
          image_id: data.image_id,
          image: files[0],
          image_url: url,
          state: "UPDATE"
        };
      }

      storeAction.setData(name, item);
    };

    fileReader.readAsDataURL(files[0]);
  };

  const multiFileChange = async ({ name, files }: HTMLInputElement) => {
    if (!files || files.length < 1) return;

    if (name !== "etc_multi_image") return;

    const data = storeData[name];

    const arr = [];

    const promise = (file: File) =>
      new Promise<ImageItem>((resolve) => {
        const fileReader = new FileReader();

        fileReader.onload = (load) => {
          const url = load.target?.result as string;

          resolve({
            image_url: url,
            image: file,
            state: "INSERT"
          });
        };

        fileReader.readAsDataURL(file);
      });

    for (const file of files) {
      arr.push(promise(file));
    }

    const response = await Promise.all<Promise<ImageItem>[]>(arr);

    const result = [...data, ...response];

    const array =
      result.filter((item) => item?.state !== "DELETE").length > 10
        ? [
            ...result.filter((item) => item?.state !== "DELETE").slice(0, 10),
            ...result.filter((item) => item?.state === "DELETE")
          ]
        : result;

    storeAction.setData(name, array);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const el = e.currentTarget;

    const { type, multiple, name, value, checked, id } = el;

    if (type === "file") {
      multiple ? multiFileChange(el) : fileChange(el);
      return;
    }

    if (name === "gender") {
      const result = id === "male" ? checked : !checked;
      storeAction.setData(name, result);
      return;
    }
    if (name === "is_head_director" || name === "is_consultation" || name === "is_terms_agreed") {
      storeAction.setData(name, checked);
      return;
    }
    if (name === "spec_license_no") {
      storeAction.setData(name, value);
      return;
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name: key, value } = e.currentTarget;

    if (key === "medical_id") {
      storeAction.setData(key, value);

      if (storeData.medical_category.length > 0) {
        storeAction.setData(
          "medical_category",
          storeData.medical_category.map((item) => ({ ...item, state: "DELETE" as const }))
        );
      }
    }
    if (key === "medical_category") {
      const id = +e.target.value;

      const hasCategory = storeData.medical_category.find((item) => item.m_c_i_id === id);

      if (hasCategory) {
        const medicalCategory = storeData.medical_category.map((item) => {
          if (item.m_c_i_id === id && item.state === "DELETE") {
            return {
              ...item,
              state: "STAY" as const
            };
          }
          return item;
        });

        storeAction.setData(key, medicalCategory);
        return;
      }

      const medicalCategory = [
        ...storeData.medical_category,
        {
          m_c_i_id: id,
          classify: "CATEGORY" as const,
          state: "INSERT" as const
        }
      ];

      storeAction.setData(key, medicalCategory);
    }

    if (key === "state") {
      storeAction.setData(key, value);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {
      value: key,
      dataset: { pair }
    } = e.currentTarget;

    if (!pair) return;

    if (key === "etc_multi_image") {
      const data = storeData[key]
        .map((item, index) => {
          if (index === +pair && item?.state === "STAY") {
            return {
              ...item,
              state: "DELETE" as const
            };
          }
          if (index === +pair && item?.state === "INSERT") {
            return null;
          }
          return item;
        })
        .filter(Boolean);

      storeAction.setData(key, data);
      if (data.filter((item) => item?.state !== "DELETE").length < 1) {
        const el = document.querySelector("input[type=file][multiple][name=etc_multi_image]") as HTMLInputElement;

        el.value = "";
      }
      return;
    }

    if (key === "medical_category") {
      const medicalCategory = storeData.medical_category
        .filter((item) => !(item.state === "INSERT" && item.m_c_i_id === +pair))
        .map((category) => {
          if (category?.m_c_i_id === +pair) {
            return {
              ...category,
              state: "DELETE" as const
            };
          }
          return category;
        });

      storeAction.setData(key, medicalCategory);
      return;
    }
  };

  return {
    storeData,
    medicalData,
    medicalCategoryData,
    handleChange,
    handleSelectChange,
    handleClick
  };
};
export default useDefaultFormHook;
