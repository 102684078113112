import { hospitalFetch } from "@/api";

export const query = (searchParams: URLSearchParams) => ({
  queryKey: ["hospital", "list", searchParams.toString() || "page=1"],
  queryFn: async () => {
    const newSearchParams = new URLSearchParams();

    const page = searchParams.get("page");

    const searchType = searchParams.get("st");
    const searchValue = searchParams.get("sv");

    if (page) newSearchParams.set("page", `${+page - 1}`);

    if (searchType && searchValue) newSearchParams.set(searchType, searchValue);

    try {
      const response = await hospitalFetch.get(newSearchParams.toString());

      return response.data;
    } catch {
      return null;
    }
  }
});
