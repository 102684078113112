import React from "react";
import { useFieldAddressHook } from "@/pages/hospital/info/hooks";

export default function FieldSimpleAddress() {
  const { localeData, handleChange } = useFieldAddressHook();

  return (
    <div>
      <label className="label-required mr-[30px] text-[14px] font-medium text-form-purple">주소</label>
      <input
        type="text"
        className="mt-[10px] h-[40px] w-full rounded-md border-2 px-[20px]"
        name="sub_address"
        value={localeData?.sub_address ?? ""}
        required={!localeData?.sub_address}
        onChange={handleChange}
      />
    </div>
  );
}
