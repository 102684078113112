import { create } from "zustand";

export type LocaleKey = "ko" | "en" | "ja" | "vi";

type State = "INSERT" | "DELETE" | "STAY" | "UPDATE";

export interface ImageItem {
  image_id?: number;
  image?: File;
  image_url?: string;
  state?: State;
}

export interface LocaleValue {
  title: string | null;
  sub_title: string | null;
  description: string | null;
  zipcode: string | null;
  address: string | null;
  sub_address: string | null;
  opening_desc: string | null;
  thumb_image: ImageItem | null;
  main_multi_image: ImageItem[] | null;
  des_image: ImageItem | null;
}

type LocaleData = Record<LocaleKey, LocaleValue | null>;

export type ScheduleKey =
  | "schedule_mon_s"
  | "schedule_mon_e"
  | "schedule_tue_s"
  | "schedule_tue_e"
  | "schedule_wed_s"
  | "schedule_wed_e"
  | "schedule_thu_s"
  | "schedule_thu_e"
  | "schedule_fri_s"
  | "schedule_fri_e"
  | "schedule_sat_s"
  | "schedule_sat_e"
  | "schedule_sun_s"
  | "schedule_sun_e";

export type ScheduleValue = string | null;

type Schedule = Record<ScheduleKey, ScheduleValue>;

export type ScheduleAllowKey =
  | "schedule_mon_allow"
  | "schedule_tue_allow"
  | "schedule_wed_allow"
  | "schedule_thu_allow"
  | "schedule_fri_allow"
  | "schedule_sat_allow"
  | "schedule_sun_allow";

type ScheduleAllowValue = boolean;

type ScheduleAllow = Record<ScheduleAllowKey, ScheduleAllowValue>;

type MedicalClassify = "MEDICAL" | "CATEGORY" | "ITEM";

type SharedClassify = "SUBWAY" | "ADDTL" | "LANG";

interface MedicalCategory {
  m_c_i_id: number;
  classify: MedicalClassify;
  state: State;
}

interface SharedCategory {
  shared_category_id: number;
  classify: SharedClassify;
  state?: State;
}

interface Category {
  logo_image: ImageItem | null;
  medical_category: MedicalCategory[] | null;
  shared_category: SharedCategory[] | null;
  state: "WAIT" | "COMPLETE";
}

type CombineData = Schedule & ScheduleAllow & LocaleData & Category;

interface Data {
  data: CombineData;
}

interface Action {
  action: {
    setData: (locale: LocaleKey, data: Partial<LocaleValue>) => void;
    setSchedule: (schedule: ScheduleKey, value: string) => void;
    setScheduleAllow: (schedule: ScheduleAllowKey, value: boolean) => void;
    setMedicalCategory: (category: MedicalCategory[]) => void;
    setCommonCategory: (category: SharedCategory[]) => void;
    setLogo: (item: ImageItem | null) => void;
    init: (data: CombineData) => void;
    reset: () => void;
  };
}

export const ITEM_STATE: Record<State, State> = {
  INSERT: "INSERT",
  DELETE: "DELETE",
  STAY: "STAY",
  UPDATE: "UPDATE"
};

const localeData: LocaleData = {
  ko: null,
  en: null,
  ja: null,
  vi: null
};

const initLocaleData: LocaleValue = {
  title: null,
  sub_title: null,
  description: null,
  zipcode: null,
  address: null,
  sub_address: null,
  opening_desc: null,
  thumb_image: null,
  main_multi_image: null,
  des_image: null
};
export const scheduleData: Schedule = {
  schedule_mon_s: null,
  schedule_mon_e: null,
  schedule_tue_s: null,
  schedule_tue_e: null,
  schedule_wed_s: null,
  schedule_wed_e: null,
  schedule_thu_s: null,
  schedule_thu_e: null,
  schedule_fri_s: null,
  schedule_fri_e: null,
  schedule_sat_s: null,
  schedule_sat_e: null,
  schedule_sun_s: null,
  schedule_sun_e: null
};

export const scheduleAllow: ScheduleAllow = {
  schedule_mon_allow: false,
  schedule_tue_allow: false,
  schedule_wed_allow: false,
  schedule_thu_allow: false,
  schedule_fri_allow: false,
  schedule_sat_allow: false,
  schedule_sun_allow: false
};

const category: Category = {
  logo_image: null,
  medical_category: null,
  shared_category: null,
  state: "WAIT"
};

const initialData = {
  ...localeData,
  ...scheduleData,
  ...scheduleAllow,
  ...category
};

const useHospitalStore = create<Data & Action>((set) => ({
  data: initialData,
  action: {
    setData: (locale, data) =>
      set((state) => {
        const prevData = state.data[locale] ?? initLocaleData;
        return { data: { ...state.data, [locale]: { ...prevData, ...data } } };
      }),
    setSchedule: (schedule, value) => set((state) => ({ data: { ...state.data, [schedule]: value } })),
    setScheduleAllow: (schedule, value) => set((state) => ({ data: { ...state.data, [schedule]: value } })),
    setMedicalCategory: (category: MedicalCategory[]) =>
      set((state) => ({ data: { ...state.data, medical_category: category } })),
    setCommonCategory: (category: SharedCategory[]) =>
      set((state) => ({ data: { ...state.data, shared_category: category } })),
    setLogo: (item: ImageItem | null) => set((state) => ({ data: { ...state.data, logo_image: item } })),
    init: (data) => set({ data }),
    reset: () => set({ data: initialData })
  }
}));

export default useHospitalStore;
