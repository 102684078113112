export const TABLE_HEAD = [
  { class: "w-[50px]", text: "순서 변경", visible: false, sort: null },
  { class: "w-[120px]", text: "대표원장여부", visible: true, sort: null },
  { class: "w-[120px]", text: "프로필이미지", visible: true, sort: null },
  { class: "w-[100px]", text: "의사명", visible: true, sort: null },
  { class: "w-[120px]", text: "의사 면허번호", visible: true, sort: null },
  { class: "w-[120px]", text: "전문의 자격", visible: true, sort: null },
  { class: "w-[250px]", text: "진료항목", visible: true, sort: null },
  { class: "w-[140px]", text: "의사상담가능", visible: true, sort: null },
  { class: "w-[120px]", text: "진료가능여부", visible: true, sort: null },
  { class: "w-[120px]", text: "후기수", visible: true, sort: "normal" },
  { class: "w-[120px]", text: "평점(건수)", visible: true, sort: "normal" },
  { class: "w-[120px]", text: "수정", visible: false, sort: null }
] as const;
