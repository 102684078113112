import { useMutation, useQueryClient } from "@tanstack/react-query";

import { hospitalFetch } from "@/api";
import { useSearchParams } from "react-router-dom";
import { useRef } from "react";

export const useHook = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const registRef = useRef<HTMLDialogElement>(null);

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const type = formData.get("search_type")?.toString();
    const value = formData.get("search_value")?.toString();

    if (type === "all") {
      setSearchParams();
      return;
    }
    if (type && value) setSearchParams({ st: type, sv: value });
  };

  const handleRegistDialog = () => {
    registRef.current?.showModal();
  };
  return { registRef, searchParams, handleSearchSubmit, handleRegistDialog };
};

export const useModalHook = () => {
  const queryClient = useQueryClient();

  const registMutation = useMutation({
    mutationFn: async (item: any) => hospitalFetch.post(item),
    onSuccess: () => {
      alert("Success");

      queryClient.invalidateQueries({ queryKey: ["hospital", "list"] });

      const el = document.querySelector("dialog[open]") as HTMLDialogElement;

      el.close();
    },
    onError: () => {
      alert("failed");
    }
  });

  const handleReset = (e: React.FormEvent<HTMLDialogElement>) => {
    e.currentTarget?.open && e.currentTarget?.close();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    registMutation.mutate({
      text: [
        {
          local: "ko",
          title: formData.get("title")
        }
      ]
    });
  };

  return { handleReset, handleSubmit };
};
