export const tableColumn = [
  { text: "신청일자", width: 160 },
  { text: "고객명", width: 100 },
  { text: "연락처", width: 180 },
  { text: "이메일", width: 180 },
  { text: "마케팅 채널정보", width: 160 },
  { text: "마케팅 예산", width: 180 },
  { text: "문의내용", width: 180 },
  { text: "상담진행 상태", width: 160 },
  { text: "담당자", width: 120 }
];
