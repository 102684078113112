import React from "react";

import { IconClose, IconInfo } from "@/components/icons";
import { useFieldSubwayHook } from "@/pages/hospital/info/hooks";

export default function SubwaySection() {
  const { fieldData, subwayData, handleChange, handleClick } = useFieldSubwayHook();

  return (
    <div className="mt-[30px]">
      <div className="mb-[15px] flex items-center">
        <label htmlFor="h-subway" className="mr-[30px] text-[14px] font-medium text-form-purple">
          지하철역
        </label>
        <select className="h-[30px] border-2 px-[5px]" name="shared_category" defaultValue="" onChange={handleChange}>
          <option hidden value="">
            선택해주세요.
          </option>
          {subwayData.map((item: any) => (
            <option key={`option-${item.shared_category_id}`} value={item.shared_category_id}>
              {item.text.find((v: any) => v.local === "ko")?.title}
            </option>
          ))}
        </select>
        <div className="ml-[10px] flex items-center gap-[10px]">
          <IconInfo />
          <span>4개까지 선택가능합니다.</span>
        </div>
      </div>
      <div className="flex gap-[10px] px-[10px]">
        {fieldData
          .filter((item) => item.state !== "DELETE")
          .map((item) => (
            <div
              key={`select-${item.shared_category_id}`}
              className="flex h-[30px] content-center items-center gap-[6px] rounded-md bg-slate-300 px-[15px]"
            >
              <p>
                {subwayData
                  .find((v: any) => v.shared_category_id === item.shared_category_id)
                  ?.text?.find((v: any) => v.local === "ko")?.title ?? "Null"}
              </p>
              <button type="button" name="shared_category" value={item.shared_category_id} onClick={handleClick}>
                <IconClose width={16} height={16} />
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}
