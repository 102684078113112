import React, { useRef, useState } from "react";
import { Link, LoaderFunctionArgs, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { eventDetailFetch, eventFetch } from "@/api";
import Nav from "@/components/nav";
import { IconClose } from "@/components/icons";

const eventDetailQuery = (eventId: string) => ({
  queryKey: ["event", "detail", eventId],
  queryFn: async () => {
    const response = await eventDetailFetch.get(eventId);

    return response.data;
  }
});

export const EventDetailLoader =
  (queryClient: QueryClient) =>
  ({ params }: LoaderFunctionArgs) => {
    return queryClient.ensureQueryData(eventDetailQuery(params.event_id as string));
  };

export default function EventDetailPage() {
  const loaderData = useLoaderData();
  const navigate = useNavigate();
  const params = useParams();

  const eventId = params.event_id as string;

  const dialogRef = useRef<HTMLDialogElement>(null);

  const [rejectText, setRejectText] = useState<any>(null);

  const { data: eventDetail, isSuccess } = useQuery<any>({ ...eventDetailQuery(eventId), initialData: loaderData });

  const patchMutation = useMutation({
    mutationFn: async ({ type, id, body }: any) => eventFetch.patch(type, id, body),
    onSuccess: () => {
      alert("성공");
      navigate("/event/list");
    },
    onError: () => {
      alert("실패");
    }
  });

  if (!isSuccess) throw new Error("EVENT DETAIL API ERROR");
  return (
    <div>
      <Nav
        list={[
          { title: "이벤트관리", link: "/event/list" },
          { title: "승인요청상세", link: null }
        ]}
      />
      <div className="min-w-[1080px] bg-white px-[30px] py-[20px]">
        {/* Page title */}
        <div className="mb-[24px] border-b-2 pb-[10px]">
          <h1 className="text-[16px]">승인 요청 상세</h1>
        </div>
        {/* Page contents */}
        <div className="flex flex-col gap-[20px]">
          {/* item_1 승인요청 정보 */}
          <div className="rounded-md border-2">
            {/* item_1 title */}
            <div className="border-b-2 px-[20px] py-[10px]">
              <h2 className="font-bold">승인요청 정보</h2>
            </div>
            {/* item_1 content */}
            <div className="flex flex-col gap-[30px] px-[20px] py-[20px]">
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">처리상태</h3>
                {eventDetail.event_approval?.state === "APPROVAL" ? (
                  <span className="mr-[5px] h-[10px] w-[10px] rounded-full bg-form-green" />
                ) : (
                  <span className="bg-form-red mr-[5px] h-[10px] w-[10px] rounded-full" />
                )}
                <p className="mr-[30px]">
                  {eventDetail.event_approval?.state === "WAIT" && "대기"}
                  {eventDetail.event_approval?.state === "REQ" && "신청 중"}
                  {eventDetail.event_approval?.state === "APPROVAL" && "승인"}
                  {eventDetail.event_approval?.state === "REFUSAL" && "반려"}
                  {eventDetail.event_approval?.state === "TERMINATION" && "게시 종료"}
                </p>
                {eventDetail.event_approval?.state === "REFUSAL" && (
                  <Link
                    to={`/event/edit/${eventDetail.event_id}`}
                    className="bg-form-red px-[10px] py-[5px] text-white"
                  >
                    이벤트 수정
                  </Link>
                )}
              </div>
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">처리일시</h3>
                <p>
                  {eventDetail.event_approval?.create_date
                    ? dayjs.utc(eventDetail.event_approval?.create_date).tz().format("YYYY.MM.DD HH:mm")
                    : "-"}
                </p>
              </div>
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">사유</h3>
                <p>{eventDetail.event_approval?.description ? eventDetail.event_approval?.description : "-"}</p>
              </div>
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">기존 이벤트</h3>
                <p>{eventDetail.base_event_id || "-"}</p>
              </div>
            </div>
          </div>
          {/* item_2 승인요청 과거내역 */}
          <div className="rounded-md border-2">
            {/* item_2 title */}
            <div className="border-b-2 px-[20px] py-[10px]">
              <h2 className="font-bold">승인요청 과거내역</h2>
            </div>
            {/* item_2 content */}
            <div className="px-[20px] py-[20px]">
              <table className="w-full text-center">
                <colgroup>
                  <col className="w-[200px]" data-label="처리일시" />
                  <col className="w-[140px]" data-label="처리상태" />
                  <col className="w-[160px]" data-label="반려사유" />
                  <col className="w-[200px]" data-label="승인요청 상세보기" />
                </colgroup>
                <thead>
                  <tr className="h-[40px] border-y-2 bg-gray-300">
                    <th>처리일시</th>
                    <th>처리상태</th>
                    <th>반려사유</th>
                    <th>승인요청 상세보기</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {eventDetail.approval_logs.map((logItem: any) => (
                    <tr key={`event-approval-log-${logItem.create_date}`} className="h-[40px] border-y-2">
                      <td>{dayjs.utc(logItem.create_date).tz().format("YYYY.MM.DD HH:mm")}</td>
                      <td>
                        {logItem?.state === "APPROVAL" ? (
                          <div>
                            <span className="mr-[5px] inline-block h-[10px] w-[10px] rounded-full bg-form-green" />
                            승인
                          </div>
                        ) : (
                          <div>
                            <span className="bg-form-red mr-[5px] inline-block h-[10px] w-[10px] rounded-full" />
                            반려
                          </div>
                        )}
                      </td>
                      <td>
                        {logItem?.state === "REFUSAL" && (
                          <button
                            type="button"
                            className="bg-form-red rounded-md px-[10px] py-[5px] text-white"
                            data-description={logItem.description}
                            onClick={(e) => {
                              const description = e.currentTarget.dataset.description;

                              setRejectText(description);

                              dialogRef.current?.showModal();
                            }}
                          >
                            사유
                          </button>
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/event/detail/${logItem.event_id}`}
                          className="rounded-md border-2 border-gray-400 bg-gray-200 px-[10px] py-[5px]"
                        >
                          승인요청상세보기
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* item_3 이벤트 분류 */}
          <div className="rounded-md border-2">
            {/* item_3 title */}
            <div className="border-b-2 px-[20px] py-[10px]">
              <h2 className="font-bold">이벤트 분류</h2>
            </div>
            {/* item_3 content */}
            <div className="flex flex-col gap-[30px] px-[20px] py-[20px]">
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">대상국가</h3>
                <p>
                  {eventDetail.local === "KO" && "한국"}
                  {eventDetail.local === "EN" && "영어"}
                  {eventDetail.local === "VI" && "베트남"}
                  {eventDetail.local === "JA" && "일본"}
                </p>
              </div>
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">시술명 태그</h3>
                <div className="flex gap-[5px]">
                  {eventDetail.medical_category.map((tagItem: any) => (
                    <span key={`treatment-item-${tagItem.m_c_i_id}`} className="rounded-md border px-[5px] py-[2px]">
                      {tagItem.title}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* item_4 이벤트 정보 */}
          <div className="rounded-md border-2">
            {/* item_4 title */}
            <div className="border-b-2 px-[20px] py-[10px]">
              <h2 className="font-bold">이벤트 정보</h2>
            </div>
            {/* item_4 content */}
            <div className="flex flex-col gap-[30px] px-[20px] py-[20px]">
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">이벤트 제목</h3>
                <p className="font-bold">{eventDetail.title}</p>
              </div>
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">시술 설명</h3>
                <p>{eventDetail.description}</p>
              </div>
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">이벤트 기간</h3>
                <p>
                  {dayjs.utc(eventDetail.open_start_date).tz().format("YYYY.MM.DD")}
                  <span>{" ~ "}</span>
                  {eventDetail.open_end_date && dayjs.utc(eventDetail.open_end_date).tz().format("YYYY.MM.DD")}
                </p>
              </div>
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">대상성별</h3>
                <p>
                  {eventDetail.gender === "NONE" && "성별무관"}
                  {eventDetail.gender === "MAN" && "남자"}
                  {eventDetail.gender === "WOMAN" && "여자"}
                </p>
              </div>
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">자유 태그</h3>
                <div className="flex gap-[5px]">
                  {eventDetail.tags.map((tagItem: any) => (
                    <span key={`tag-item-${tagItem.event_tag_id}`} className="rounded-md border px-[5px] py-[2px]">
                      {tagItem.description}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* item_5 가격 정보 */}
          <div className="rounded-md border-2">
            {/* item_5 title */}
            <div className="px-[20px] py-[10px]">
              <h2 className="font-bold">가격정보</h2>
            </div>
            {/* item_5 content */}
            <div className="flex flex-col gap-[30px] px-[20px] py-[20px]">
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">VAT</h3>
                <p>{eventDetail.is_vat ? "포함" : "없음"}</p>
              </div>
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">마취비</h3>
                <p>{eventDetail.is_anesthesia ? "포함" : "없음"}</p>
              </div>
              <div className="flex items-center">
                <h3 className="basis-[100px] font-bold">사후관리비</h3>
                <p>{eventDetail.is_after ? "포함" : "없음"}</p>
              </div>
            </div>
          </div>
          {/* item_6 옵션 정보 */}
          <div className="rounded-md border-2">
            {/* item_6 title */}
            <div className="px-[20px] py-[10px]">
              <h2 className="font-bold">옵션 정보</h2>
            </div>
            {/* item_6 content */}
            <div className="flex flex-col gap-[20px] px-[20px] py-[20px]">
              {eventDetail.items.map((optionItem: any, index: number) => (
                <table key={`option-item-${optionItem.event_item_id}`} className="w-full text-left">
                  <colgroup>
                    <col width={20} data-label="blank" />
                    <col width={100} data-label="thead" />
                  </colgroup>
                  <thead>
                    <tr className="h-[40px] border-y-2 bg-gray-300">
                      <th colSpan={3} className="pl-[20px]">
                        {`옵션${index + 1}`}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="h-[40px] border-y-2">
                      <td></td>
                      <th>옵션명</th>
                      <td>{optionItem.item_title}</td>
                    </tr>
                    <tr className="h-[40px] border-y-2">
                      <td></td>
                      <th>옵션가</th>
                      <td>{optionItem.price.toLocaleString("ko-Kr")}</td>
                    </tr>
                    <tr className="h-[40px] border-y-2">
                      <td></td>
                      <th>옵션 구성 시술</th>
                      <td>{optionItem.item_title}</td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>
          </div>
          {/* item_7 이벤트 이미지 */}
          <div className="rounded-md border-2">
            {/* item_7 title */}
            <div className="border-b-2 px-[20px] py-[10px]">
              <h2 className="font-bold">이벤트 이미지</h2>
            </div>
            {/* item_7 content */}
            <div>
              <div className="border-b-2 px-[20px] py-[20px]">
                <div className="mb-[20px]">
                  <h3 className="mb-[5px]">이벤트 썸네일 이미지 (1080X1080)</h3>
                  <img
                    src={eventDetail.thumb_big_image?.image_url}
                    alt={"event square thumnail"}
                    className="aspect-square"
                    width={540}
                    height={540}
                  />
                </div>
              </div>
              <div className="border-b-2 px-[20px] py-[20px]">
                <h3 className="mb-[5px]">이벤트 상품 상세페이지 상단 이미지 (1080X468)</h3>
                <div className="flex max-w-[1090px] flex-wrap gap-[10px]">
                  {eventDetail.detail_multi_image.map((imgItem: any) => (
                    <img
                      key={`multiple-image-${imgItem?.image_id}`}
                      src={imgItem?.image_url}
                      alt={"event multi"}
                      className="aspect-[30/13]"
                      width={540}
                    />
                  ))}
                </div>
              </div>
              <div className="px-[20px] py-[20px]">
                <h3 className="mb-[5px]">이벤트 상품 소개이미지</h3>
                <div className="h-[1200px] w-[768px] overflow-y-auto">
                  <img src={eventDetail.des_image?.image_url} alt={"event intro"} className="h-auto" />
                </div>
              </div>
            </div>
          </div>
          {/* item_8 부작용 안내 */}
          <div className="rounded-md border-2">
            {/* item_8 title */}
            <div className="border-b-2 px-[20px] py-[10px]">
              <h2 className="font-bold">부작용 안내</h2>
            </div>
            {/* item_8 content */}
            <div className="px-[20px] py-[20px]">
              <p>{eventDetail.side_effect_des || "-"}</p>
            </div>
          </div>
          {/* submit buttons */}
          <div className="flex justify-between">
            {/* admin */}
            <div className="flex gap-[20px]">
              {eventDetail.event_approval?.state === "REQ" && (
                <button
                  type="button"
                  className="bg-btn-blue px-[20px] py-[6px] text-white"
                  onClick={() => {
                    patchMutation.mutate({ type: "approval", id: eventDetail.event_id, body: { state: "APPROVAL" } });
                  }}
                >
                  승인처리
                </button>
              )}
              {eventDetail.event_approval?.state === "REQ" && (
                <button
                  type="button"
                  className="bg-btn-red px-[20px] py-[6px] text-white"
                  onClick={() => {
                    patchMutation.mutate({ type: "approval", id: eventDetail.event_id, body: { state: "REFUSAL" } });
                  }}
                >
                  반려
                </button>
              )}
              {eventDetail.event_approval?.state === "APPROVAL" && (
                <Link to={"/event/list"} className="bg-btn-gray px-[20px] py-[6px] text-white">
                  목록
                </Link>
              )}
            </div>
            {/* user */}
            <div className="flex gap-[20px]">
              {eventDetail.event_approval?.state === "WAIT" && (
                <>
                  <Link to={"/event/list"} className="bg-btn-gray px-[20px] py-[6px] text-white">
                    목록
                  </Link>
                  <Link
                    to={`/event/edit/${eventDetail.event_id}`}
                    className="bg-btn-green px-[20px] py-[6px] text-white"
                  >
                    수정
                  </Link>
                  <button
                    type="button"
                    className="bg-btn-red px-[20px] py-[6px] text-white"
                    onClick={() => {
                      patchMutation.mutate({ type: "approval", id: eventDetail.event_id, body: { state: "REQ" } });
                    }}
                  >
                    이벤트 승인 신청
                  </button>
                </>
              )}
              {eventDetail.event_approval?.state === "REQ" && (
                <>
                  <Link to={"/event/list"} className="bg-btn-gray px-[20px] py-[6px] text-white">
                    목록
                  </Link>
                  <span className="bg-btn-red px-[20px] py-[6px] text-white">검수중</span>
                </>
              )}
              {eventDetail.event_approval?.state === "APPROVAL" && (
                <button
                  type="button"
                  className="bg-btn-red px-[20px] py-[6px] text-white"
                  onClick={() => {
                    patchMutation.mutate({
                      type: "approval",
                      id: eventDetail.event_id,
                      body: { state: "TERMINATION" }
                    });
                  }}
                >
                  게시 종료
                </button>
              )}
              {eventDetail.event_approval?.state === "REFUSAL" && (
                <>
                  <Link to={"/event/list"} className="bg-btn-gray px-[20px] py-[6px] text-white">
                    목록
                  </Link>
                  <Link
                    to={`/event/edit/${eventDetail.event_id}`}
                    className="bg-btn-green px-[20px] py-[6px] text-white"
                  >
                    수정
                  </Link>
                  <button
                    type="button"
                    className="bg-btn-red px-[20px] py-[6px] text-white"
                    onClick={() => {
                      patchMutation.mutate({ type: "approval", id: eventDetail.event_id, body: { state: "REQ" } });
                    }}
                  >
                    이벤트 승인 신청
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <dialog ref={dialogRef} className="rounded-md">
        <form method="dialog" className="w-[600px]">
          {/* title */}
          <div className="flex h-[50px] items-center justify-between px-[10px]">
            <div className="flex gap-[10px]">
              <img src="" alt="miin logo" />
              <h2>반려사유</h2>
            </div>
            <button>
              <IconClose />
            </button>
          </div>
          {/* content */}
          <div className="h-[200px] overflow-y-auto overflow-x-hidden px-[10px]">
            <p>{rejectText}</p>
          </div>
        </form>
      </dialog>
    </div>
  );
}
