import { hospitalContactFetch } from "@/api";

export const query = (id: string) => ({
  queryKey: ["hospital", id, "contact"],
  queryFn: async () => {
    const response = await hospitalContactFetch.get(id);

    return { ...response.data, main_phone: response.data.main_phone?.split("-") };
  }
});
