import React, { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

import { Pagination } from "@/components/pagination";
import { IconClose } from "@/components/icons";
import { convertTime } from "@/utils/date";
import { consultantDetailFetch, consultantFetch } from "@/api";

import { tableColumn } from "@/pages/inquiry/consultant/constants";

export default function InquiryTable({ adminList }: any) {
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const [detailId, setDetailId] = useState<string | null>(null);
  const [status, setStatus] = useState<any | null>(null);

  const detailDialogRef = useRef<HTMLDialogElement>(null);
  const statusDialogRef = useRef<HTMLDialogElement>(null);

  const { isSuccess, data } = useQuery({
    queryKey: ["inquiry", "consultant", "table", searchParams.toString()],
    queryFn: async () => {
      const newSearchParams = new URLSearchParams();

      const page = searchParams.get("page");

      if (page) {
        newSearchParams.set("page", `${+page - 1}`);
      }

      const key = searchParams.get("option");
      const value = searchParams.get("value");

      if (key && value) {
        newSearchParams.set(key, value);

        if (key === "createS") {
          newSearchParams.set("createS", dayjs.tz(value).utc().format("YYYY-MM-DD HH:mm:ss.SSS"));
          newSearchParams.set(
            "createE",
            dayjs.tz(value).utc().add(1, "day").subtract(1, "millisecond").format("YYYY-MM-DD HH:mm:ss.SSS")
          );
        }
      }

      const response = await consultantFetch.get(newSearchParams.toString());

      if (!response.ok) throw new Error(response.data.error);

      return response.data;
    }
  });

  const { data: detailData } = useQuery({
    queryKey: ["inquiry", "consultant", "detail", detailId],
    queryFn: async () => {
      if (!detailId) return null;

      const response = await consultantDetailFetch.get(detailId);

      return response.data;
    },
    enabled: !!detailId
  });

  const patchMutation = useMutation({
    mutationFn: (item: any) => consultantDetailFetch.patch(item.id, item.body),

    onSuccess: () => {
      alert("상태를 변경 했습니다.");
      if (statusDialogRef.current?.open) statusDialogRef.current.close();
      return queryClient.invalidateQueries({ queryKey: ["inquiry", "consultant", "table"] });
    },
    onError: () => {
      alert("상태변경에 실패했습니다.");
      return;
    }
  });

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const option = formData.get("option") as string;
    const value = formData.get("value") as string;
    if (option && value) setSearchParams({ option, value });
  };

  return (
    <div className="bg-white px-[30px] py-[40px] text-black">
      <form className="mb-[10px] flex justify-end gap-[8px]" onSubmit={handleSearchSubmit}>
        <select
          className="rounded border p-[10px]"
          name="option"
          defaultValue={searchParams.get("option") ?? "createS"}
        >
          <option value="createS">신청일자</option>
          <option value="writerN">고객명</option>
          <option value="state">상담 진행 상태</option>
          <option value="email">이메일</option>
        </select>
        <input
          className="w-[300px] rounded border p-[10px]"
          type="text"
          name="value"
          defaultValue={searchParams.get("text") ?? ""}
          required
        />
        <button className="rounded-md bg-blue-500 px-[20px] text-white">검색</button>
      </form>
      <table className="mb-[60px] border-t text-center">
        <caption className="mb-[10px] text-left">{`총 게시물 ${isSuccess ? data.paging_info.total_item_count : 0} 개`}</caption>
        <colgroup>
          {tableColumn.map((col) => (
            <col key={`table-column-${col.text}`} width={col.width} />
          ))}
        </colgroup>
        <thead className="border-b-2 border-black text-[14px]">
          <tr className="h-[40px]">
            {tableColumn.map((col) => (
              <td
                key={`table-head-${col.text}`}
                className="relative after:absolute after:right-0 after:top-1/2 after:h-1/2 after:-translate-y-1/2 after:border-e-2 last:after:hidden"
              >
                {col.text}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {isSuccess &&
            data.items.map((item: any) => (
              <tr key={`table-list-${item.consultation_id}`} className="h-[40px] border-b">
                <td>{convertTime(item.create_date)}</td>
                <td>{item.country}</td>
                <td>{item.writer_name ?? "-"}</td>
                <td>{item.gender ?? "-"}</td>
                <td>{item.birthday}</td>
                <td>{item.phone ?? "-"}</td>
                <td>{item.email}</td>
                <td className="relative">
                  <button
                    type="button"
                    className="absolute left-1/2 top-1/2 h-[80%] w-[70%] -translate-x-1/2 -translate-y-1/2 rounded-md bg-purple-600 align-middle text-white disabled:bg-gray-200 disabled:text-black"
                    disabled={!item.is_image}
                    onClick={() => {
                      detailDialogRef.current?.showModal();
                      setDetailId(item.consultation_id);
                    }}
                  >
                    {item.is_image ? "이미지 & 내용보기" : "첨부없음"}
                  </button>
                </td>
                <td className="relative">
                  <select
                    value={item.state ?? ""}
                    className="absolute left-0 top-1/2 h-[90%] w-[90%] -translate-y-1/2 rounded-md border"
                    onChange={(e) => {
                      statusDialogRef.current?.showModal();
                      setStatus({
                        type: "progress",
                        value: e.currentTarget.querySelector("option:checked")?.textContent,
                        body: { id: item.consultation_id, body: { state: e.currentTarget.value } }
                      });
                    }}
                  >
                    <option hidden value="">
                      NULL
                    </option>
                    <option value="PRE">상담전</option>
                    <option value="EM">이메일 연락진행</option>
                    <option value="PH">전화 통화진행</option>
                    <option value="IV">인터뷰 진행</option>
                    <option value="CP">상담완료</option>
                  </select>
                </td>
                <td className="relative">
                  <select
                    value={item.admin_user_id ?? ""}
                    className="absolute left-0 top-1/2 h-[90%] w-[90%] -translate-y-1/2 rounded-md border"
                    onChange={(e) => {
                      statusDialogRef.current?.showModal();
                      setStatus({
                        type: "admin",
                        value: e.currentTarget.querySelector("option:checked")?.textContent,
                        body: { id: item.consultation_id, body: { admin_user_id: e.currentTarget.value } }
                      });
                    }}
                  >
                    <option hidden value="">
                      담당자
                    </option>
                    {adminList.map((admin: any) => (
                      <option key={`admin-${item.consultation_id}-${admin.admin_user_id}`} value={admin.admin_user_id}>
                        {admin.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {isSuccess && <Pagination totalItem={data.paging_info.total_item_count} viewCount={10} />}
      {/* 상태 변경 재확인 모달 */}
      <dialog ref={statusDialogRef} className="rounded-xl" onClose={() => setStatus(null)}>
        {status && (
          <form method="dialog" className="w-[400px]">
            {/* head */}
            <div className="flex h-[50px] items-center justify-between bg-gray-600 px-[20px] text-white">
              {status.type === "admin" && <h3>담당자</h3>}
              {status.type === "progress" && <h3>상담진행 상태</h3>}
              <button>
                <IconClose fill="white" />
              </button>
            </div>
            {/* body */}
            <div className="p-[20px]">
              <div className="min-h-[100px]">
                {status.type === "admin" && <p>{`${status.value} 담당자로 변경 하시겠습니까?`}</p>}
                {status.type === "progress" && <p>{`${status.value} 진행상태로 변경 하시겠습니까?`}</p>}
              </div>
              <div className="flex justify-end gap-[5px]">
                <button className="rounded-3xl bg-stone-400 px-[20px] py-[5px] text-white">취소</button>
                <button
                  type="button"
                  className="rounded-3xl bg-red-400 px-[20px] py-[5px] text-white"
                  onClick={() => patchMutation.mutate(status.body)}
                >
                  변경
                </button>
              </div>
            </div>
          </form>
        )}
      </dialog>
      {/* 상세보기 모달 */}
      <dialog ref={detailDialogRef} className="rounded-xl" onClose={() => setDetailId(null)}>
        {detailData && (
          <form method="dialog" className="w-[1080px]">
            {/* head */}
            <div className="flex h-[50px] items-center justify-between bg-gray-600 px-[20px] text-white">
              <h3>상세보기</h3>
              <button>
                <IconClose fill="white" />
              </button>
            </div>
            {/* body */}
            <div className="flex flex-col gap-[20px] p-[20px]">
              <div className="flex gap-[10px]">
                <span>고객명</span>
                <span>{detailData?.consultation_info?.name}</span>
                <span>{detailData?.consultation_info?.phone}</span>
                <span>{detailData?.consultation_info?.email}</span>
              </div>
              <div className="flex gap-[20px]">
                <div className="flex-1">
                  <div>
                    <span>병력</span>
                  </div>
                  <div className="h-[200px] overflow-y-auto border p-[10px]">
                    <p>{detailData.consultation_info.medical_history}</p>
                  </div>
                </div>
                <div className="flex-1">
                  <div>
                    <span>문의내용</span>
                  </div>
                  <div className="h-[200px] overflow-y-auto border p-[10px]">
                    <p>{detailData.consultation_info.description}</p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <span>첨부이미지</span>
                </div>
                <div className="flex items-center justify-between gap-[20px]">
                  {[...detailData?.image_info, ...Array(3)].slice(0, 3).map((image: any, index) => (
                    <div key={`preview-${image?.image_id ?? index}`} className="w-[300px] leading-[0]">
                      <span className="inline-block h-[400px] w-[300px] border after:inline-block after:h-full after:w-full after:bg-[linear-gradient(to_left_top,transparent_calc(50%-1px),rgb(229,231,235)_50%,transparent_calc(50%+1px)),linear-gradient(to_right_top,transparent_calc(50%-1px),rgb(229,231,235)_50%,transparent_calc(50%+1px))] has-[+img[src]]:hidden" />
                      <img src={image?.image_url} alt="" />
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-right">
                <button className="rounded-3xl bg-red-500 px-[20px] py-[5px] text-white">목록</button>
              </div>
            </div>
          </form>
        )}
      </dialog>
    </div>
  );
}
