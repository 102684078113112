import useStore from "@/pages/hospital/info/store";

export const useFieldThumnailHook = () => {
  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const locale = storeData.locale;
  const thumnail = storeData[locale]?.thumb_image;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (!files) return;
    if (files.length < 1) return;

    const imageFile = files[0];

    const fileReader = new FileReader();

    fileReader.onload = (ev) => {
      const url = ev.target?.result as string;

      if (!thumnail) {
        storeAction.setLocaleData(locale, { thumb_image: { image_url: url, image: imageFile, state: "INSERT" } });

        return;
      }

      if (!thumnail.state || thumnail.state === "DELETE") {
        storeAction.setLocaleData(locale, {
          thumb_image: { image_id: thumnail.image_id, image: imageFile, image_url: url, state: "UPDATE" }
        });
      }
    };

    fileReader.readAsDataURL(imageFile);
  };

  const handleClick = () => {
    if (!thumnail) return;

    if (thumnail.state !== "INSERT") {
      storeAction.setLocaleData(locale, { thumb_image: { ...thumnail, state: "DELETE" } });
      return;
    }
    storeAction.setLocaleData(locale, { thumb_image: null });
  };

  return {
    thumnail,
    required: !thumnail?.image_url || thumnail?.state === "DELETE",
    handleChange,
    handleClick
  };
};
