export const tableColumn = [
  { text: "신청일자", width: 160 },
  { text: "국가", width: 100 },
  { text: "고객명", width: 100 },
  { text: "성별", width: 100 },
  { text: "생년월일", width: 130 },
  { text: "연락처", width: 140 },
  { text: "이메일", width: 180 },
  { text: "내용 상세보기", width: 200 },
  { text: "상담진행 상태", width: 160 },
  { text: "담당자", width: 120 }
];
