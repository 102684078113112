import { create } from "zustand";

import { Action, Data, State } from "@/pages/hospital/doctor/detail/types";

const initialData: Data = {
  d_license: "",
  spec_license_no: "",
  gender: false,
  type: "DOCTOR",
  is_terms_agreed: false,
  is_head_director: false,
  is_consultation: false,
  profile_image: null,
  d_license_image: null,
  spec_image: null,
  etc_multi_image: [],
  medical_id: null,
  medical_category: [],
  state: "LEAVE",
  text: []
};

const useStore = create<State & Action>((set) => ({
  data: initialData,
  action: {
    init: (data) => set(() => ({ data })),
    reset: () => set(() => ({ data: initialData })),
    setData: (key, value) => set((state) => ({ data: { ...state.data, [key]: value } }))
  }
}));

export default useStore;
