import { useQuery } from "@tanstack/react-query";

import { commonMedicalFetch } from "@/api";

import useStore from "@/pages/hospital/info/store";

export const useFieldMedicalHook = () => {
  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const { data: medicalData } = useQuery({
    queryKey: ["common", "medical", "allow"],
    queryFn: async () => {
      const response = await commonMedicalFetch.get();

      return response.data.items.filter((item: any) => item.is_open_allow);
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, name } = e.currentTarget;
    const selectId = +value;

    if (!storeData.medical_category) {
      storeAction.setData(name, [{ m_c_i_id: selectId, classify: "MEDICAL", state: "INSERT" }]);
      return;
    }
    const hasItem = storeData.medical_category.find((item) => item.m_c_i_id === selectId);

    if (hasItem) {
      const result = storeData.medical_category.map((item) => {
        if (item.m_c_i_id === selectId && item.state === "DELETE") return { ...item, state: "STAY" };
        return item;
      });

      storeAction.setData(name, result);
      return;
    }

    storeAction.setData(name, [
      ...storeData.medical_category,
      { m_c_i_id: selectId, classify: "MEDICAL", state: "INSERT" }
    ]);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    const selectId = +value;

    const isInsertState = storeData.medical_category?.find((item) => item.state === "INSERT");

    if (isInsertState) {
      const result = storeData.medical_category?.filter((item) => item.m_c_i_id !== selectId) ?? null;

      storeAction.setData("medical_category", result);
      return;
    }

    const result =
      storeData.medical_category?.map((item) => {
        if (item.m_c_i_id === selectId) return { ...item, state: "DELETE" };
        return item;
      }) ?? null;

    storeAction.setData("medical_category", result);
  };

  return {
    fieldData: storeData.medical_category ?? [],
    required: !storeData.medical_category?.filter((item) => item.state !== "DELETE")?.length,
    medicalData: medicalData ?? [],
    handleChange,
    handleClick
  };
};
