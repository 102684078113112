import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import ASIDE from "../utils/aside.json";
import { IconAngleLeft } from "@/components/icons";

export default function RootAside() {
  const location = useLocation();

  const [listOpen, setListOpen] = useState(location.pathname);

  const handleDepsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value, name } = e.currentTarget;

    if (listOpen.includes(value)) {
      if (name === "first") setListOpen("");
      if (name === "second") {
        const changeValue = listOpen.split("/").slice(0, -1).join("/");
        setListOpen(changeValue);
      }
      return;
    }
    setListOpen(value);
  };

  useEffect(() => {
    let ignore = false;

    if (!ignore) setListOpen(location.pathname);

    return () => {
      ignore = true;
    };
  }, [location]);

  return (
    <aside className="col-span-1 bg-aside-default text-sm text-white">
      <nav className="pt-[10px]">
        <ul>
          {ASIDE.map((deps) => (
            <li
              key={deps.title}
              className="group border-t border-gray-400 last:border-b data-[open=true]:bg-aside-gray"
              data-open={listOpen.startsWith(deps.link)}
            >
              <button
                type="button"
                className="flex h-[35px] w-full items-center justify-between border-gray-400 px-5 group-data-[open=true]:border-b"
                onClick={handleDepsClick}
                name="first"
                value={deps.link}
              >
                {deps.title}
                <IconAngleLeft
                  className="transition-transform group-data-[open='true']:-rotate-90"
                  fill="white"
                  width={10}
                  height={10}
                />
              </button>
              <ul className="h-0 overflow-hidden bg-aside-default group-data-[open=true]:h-auto">
                {deps.items &&
                  deps.items.map((deps2) => (
                    <li
                      key={deps2.title}
                      className="group/deps2 data-[open=true]:bg-aside-gray"
                      data-open={listOpen.startsWith(deps2.link)}
                    >
                      {!deps2.items ? (
                        <Link
                          to={deps2.link}
                          className="block h-[35px] leading-[35px] before:ml-6 before:mr-2 before:content-['-'] data-[active='true']/deps2:text-aside-yellow"
                          data-active={location.pathname.endsWith(deps2.link)}
                        >
                          {deps2.title}
                        </Link>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="hidden h-[35px] w-full items-center justify-between pr-5"
                            // className="flex h-[35px] w-full items-center justify-between pr-5"
                            onClick={handleDepsClick}
                            name="second"
                            value={deps2.link}
                          >
                            <span className="before:ml-6 before:mr-2 before:content-['-']">{deps2.title}</span>
                            <IconAngleLeft
                              className="transition-transform group-data-[open='true']/deps2:-rotate-90"
                              fill="white"
                              width={10}
                              height={10}
                            />
                          </button>
                          <ul className="h-0 overflow-hidden bg-aside-default group-data-[open='true']/deps2:h-auto">
                            {(deps2.items as any[]).map((deps3) => (
                              <li
                                key={deps3.title}
                                className="group/deps3 data-[open='true']:bg-aside-gray"
                                data-open={listOpen.startsWith(deps3.link)}
                              >
                                <Link
                                  to={deps3.link}
                                  className="inline-block h-[35px] pl-10 pr-[20px] leading-[35px] before:mr-[5px] before:content-['ㄴ'] data-[active='true']/deps3:text-aside-yellow"
                                  data-active={location.pathname.startsWith(deps3.link)}
                                >
                                  {deps3.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </li>
                  ))}
              </ul>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
}
