import { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import { doctorDetailFetch } from "@/api";

import { useQueryFn, useStore } from "@/pages/hospital/doctor/detail/hooks";
import { Data } from "@/pages/hospital/doctor/detail/types";

const useHook = () => {
  const loaderData: any = useLoaderData();
  const navigate = useNavigate();
  const params = useParams();

  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const doctorId = params.doctor_id as string;

  const dialogRef = useRef<HTMLDialogElement>(null);

  const [tab, setTab] = useState<string>("default");

  const { isSuccess, data: detailData } = useQuery<any>({
    ...useQueryFn(doctorId),
    initialData: loaderData
  });

  const mutation = useMutation({
    mutationFn: async (item: FormData) => doctorDetailFetch.put(doctorId, item),
    onSuccess: () => {
      alert("성공");
      navigate(-1);
    },
    onError: () => alert("실패")
  });

  const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setTab(value);
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    navigate(-1);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();

    if (!storeData.is_terms_agreed) {
      dialogRef.current?.showModal();
      return;
    }

    for (const k in storeData) {
      const key = k as keyof Data;
      // image
      if (key === "spec_image" || key === "d_license_image" || key === "profile_image") {
        const data = storeData[key];

        if (!data) continue;

        if (data.state === "INSERT") {
          formData.set(`${key}.image`, data.image);
          formData.set(`${key}.state`, "INSERT");
          continue;
        }
        if (data.state === "UPDATE") {
          formData.set(`${key}.image_id`, `${data.image_id}`);
          formData.set(`${key}.image`, data.image);
          formData.set(`${key}.state`, "UPDATE");
          continue;
        }

        if (data.state === "DELETE") {
          formData.set(`${key}.image_id`, `${data.image_id}`);
          formData.set(`${key}.state`, "DELETE");
          continue;
        }

        formData.set(`${key}.image_id`, `${data.image_id}`);
        formData.set(`${key}.state`, "STAY");
        continue;
      }
      // text
      if (key === "text") {
        const data = storeData[key];

        data.forEach((v, i) => {
          formData.set(`${key}[${i}].local`, v.local);
          formData.set(`${key}[${i}].title`, v.title);
          formData.set(`${key}[${i}].description`, v.description ?? "");
        });
        continue;
      }

      if (key === "medical_category") {
        const data = storeData[key];

        data.forEach((v, i) => {
          formData.set(`${key}[${i}].m_c_i_id`, `${v.m_c_i_id}`);
          formData.set(`${key}[${i}].classify`, v.classify);
          formData.set(`${key}[${i}].state`, v.state);
        });
        continue;
      }

      if (key === "etc_multi_image") {
        for (let i = 0; i < storeData[key].length; i++) {
          const item = storeData[key][i];

          if (!item) continue;

          if (item.state === "INSERT") {
            formData.set(`${key}[${i}].image`, item.image);
            formData.set(`${key}[${i}].state`, "INSERT");
            continue;
          }
          if (item.state === "UPDATE") {
            formData.set(`${key}[${i}].image_id`, `${item.image_id}`);
            formData.set(`${key}[${i}].image`, item.image);
            formData.set(`${key}[${i}].state`, "UPDATE");
            continue;
          }
          if (item.state === "DELETE") {
            formData.set(`${key}[${i}].image_id`, `${item.image_id}`);
            formData.set(`${key}[${i}].state`, "DELETE");
            continue;
          }
          formData.set(`${key}[${i}].image_id`, `${item.image_id}`);
          formData.set(`${key}[${i}].state`, "STAY");
        }
        continue;
      }

      formData.set(`${key}`, `${storeData[key]}`);
    }

    mutation.mutate(formData);
  };

  useEffect(() => {
    if (detailData) storeAction.init(detailData);
  }, [detailData, storeAction]);

  return {
    tab,
    dialogRef,
    isSuccess,
    handleTabClick,
    handleReset,
    handleSubmit
  };
};

export default useHook;
