import { hospitalDetailFetch } from "@/api";

export const query = (hospitalId: string) => ({
  queryKey: ["hospital", "detail", hospitalId],
  queryFn: async () => {
    const response = await hospitalDetailFetch.get(hospitalId as string);
    const data = response.data;

    delete data.hospital_id;
    delete data.result_code;

    return response.data;
  }
});
