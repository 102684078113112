import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

import { IconClose } from "@/components/icons";

import { useModalHook } from "./hooks";
import { PropsType } from "./types";
import { LocaleData } from "./constants";
import Image from "./Image";

export default forwardRef<HTMLDialogElement, PropsType>(function HospitalImageModal(props, ref) {
  const { selectLocale, data, handleClick } = useModalHook(props);
  return (
    <dialog ref={ref} className="rounded-md">
      <form method="dialog" className="min-w-max">
        <div className="flex items-center bg-slate-700 px-[20px] py-[10px]">
          <h3 className="text-[20px] text-white">등록된 병원 이미지 보기</h3>
          <Link
            to={`/hospital/regist/info/${data?.id}`}
            className="ml-[20px] rounded-md bg-orange-400 px-[10px] py-[4px] text-white"
          >
            수정
          </Link>
          <button className="ml-auto">
            <IconClose fill="white" />
          </button>
        </div>
        <div className="mx-[20px] mt-[10px] border-b-2">
          {LocaleData.map((item) => (
            <button
              type="button"
              key={item.key}
              className="relative top-[2px] rounded-tl-md rounded-tr-md border-2 bg-stone-100 px-[20px] py-[5px] data-[active=true]:border-b-0 data-[active=true]:bg-white data-[active=true]:pb-[7px]"
              value={item.value}
              data-active={item.value === selectLocale}
              onClick={handleClick}
            >
              {item.text}
            </button>
          ))}
        </div>
        <div className="m-[20px] grid grid-cols-[repeat(2,300px)] grid-rows-3 gap-x-[30px] gap-y-[20px]">
          <div className="row-span-1">
            <h4 className="mb-[5px]">병원 목록 썸네일</h4>
            <div>
              <Image src={data?.thumImage} alt="병원 목록 썸네일" style={{ objectFit: "contain" }} />
            </div>
          </div>
          <div className="col-span-1 row-span-3 flex flex-col">
            <h4 className="mb-[5px]">병원 대표 이미지</h4>
            <div className="flex h-0 max-h-full w-full flex-grow flex-col gap-[5px] overflow-y-auto">
              {data?.multiImage?.map((url, index) => (
                <Image key={`multi-image-${index}`} src={url} alt="병원 대표 이미지" style={{ objectFit: "contain" }} />
              ))}
            </div>
          </div>
        </div>
      </form>
    </dialog>
  );
});
