import useStore from "@/pages/hospital/info/store";

export const useFieldLogoHook = () => {
  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const logo = storeData.logo_image;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (!files) return;
    if (files.length < 1) return;

    const imageFile = files[0];

    const fileReader = new FileReader();

    fileReader.onload = (ev) => {
      const url = ev.target?.result as string;

      if (!logo) {
        storeAction.setData("logo_image", { image_url: url, image: imageFile, state: "INSERT" });

        return;
      }

      if (!logo.state || logo.state === "DELETE") {
        storeAction.setData("logo_image", {
          image_id: logo.image_id,
          image: imageFile,
          image_url: url,
          state: "UPDATE"
        });
      }
    };

    fileReader.readAsDataURL(imageFile);
  };

  const handleClick = () => {
    if (!logo) return;

    if (logo.state !== "INSERT") {
      storeAction.setData("logo_image", { ...logo, state: "DELETE" });
      return;
    }
    storeAction.setData("logo_image", null);
  };
  return {
    logo,
    required: !logo?.image_url || logo?.state === "DELETE",
    handleChange,
    handleClick
  };
};
