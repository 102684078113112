import { IconPaperClip } from "@/components/icons";
import { convertTime } from "@/utils/date";
import React from "react";
import { Link } from "react-router-dom";

export default function Board({ type, title, image, data, list }: any) {
  return (
    <div className="col-span-1 flex flex-col gap-[30px]">
      <div className="flex flex-col">
        <div className={`flex aspect-[260/49] flex-col justify-between p-[20px] pb-[10px] ${image.top}`}>
          <div className="flex items-end gap-[20px]">
            <span>{title}</span>
            <span className="text-[14px] font-light">금월 누적건수</span>
            <span className="text-[14px] font-light">{data.month_cnt}건</span>
          </div>
          <div className="flex gap-[40px]">
            <div className="flex items-end gap-[20px]">
              <span className="text-[14px] font-light">미확인 문의</span>
              <span className="text-[30px] leading-none">{data.uf_cnt}</span>
            </div>
            <div className="flex items-end gap-[20px]">
              <span className="text-[14px] font-light">금일확인 문의 총건수</span>
              <span className="text-[30px] leading-none">{data.cf_cnt}</span>
            </div>
          </div>
        </div>
        <span className="h-[3px] w-full bg-white">
          <span className="sr-only">divide line</span>
        </span>
        <div className={`flex aspect-[260/19] items-center gap-[20px] px-[20px] ${image.bot}`}>
          <span className="text-[14px]">금년도 누적건수</span>
          <span>{data.year_cnt}건</span>
        </div>
      </div>
      <div className="h-[calc(calc(115px*6.5)+70px)] rounded-2xl bg-white py-[10px]">
        <ul className="flex max-h-full grow flex-col gap-[10px] overflow-y-auto px-[10px] text-black">
          {type === "m" &&
            list.map((item: any) => (
              <li key={item.ms_id}>
                <Link
                  to="/inquiry/marketing"
                  className="block min-h-[95px] w-full rounded-2xl bg-[#dee1e6] px-[20px] py-[15px]"
                >
                  <div className="mb-[10px] text-[14px]">{convertTime(item.create_date)}</div>
                  <div className="flex justify-between text-[16px]">
                    <p>{`${item.marketer} - ${item.channel_name}`}</p>
                  </div>
                </Link>
              </li>
            ))}
          {type === "h" &&
            list.map((item: any, index: number) => (
              <li key={index}>
                <Link
                  to={"/inquiry/hospital/??"}
                  className="block min-h-[95px] w-full rounded-2xl bg-[#dee1e6] px-[20px] py-[15px]"
                >
                  <div className="mb-[10px] text-[14px]">2024-08-23T09:21:00.303+00:00</div>
                  <div className="flex justify-between text-[16px]">
                    <p>{`${"TEST 제목"} - ${"작성자"}`}</p>
                  </div>
                </Link>
              </li>
            ))}
          {type === "c" &&
            list.map((item: any) => (
              <li key={item.consultation_id}>
                <Link
                  to="/inquiry/consultant"
                  className="block min-h-[95px] w-full rounded-2xl bg-[#dee1e6] px-[20px] py-[15px]"
                >
                  <div className="mb-[10px] text-[14px]">{convertTime(item.create_date)}</div>
                  <div className="flex justify-between text-[16px]">
                    <p>{`${item.title} - ${item.writer_name}`}</p>
                    {item.is_image && (
                      <div className="relative">
                        <span className="absolute right-0 top-0">
                          <IconPaperClip width={26} height={26} />
                        </span>
                      </div>
                    )}
                  </div>
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
