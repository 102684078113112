import { useStore } from "@/pages/hospital/doctor/detail/hooks";

const useDefaultFormHook = () => {
  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const ko = storeData.text.find((item) => item.local === "ko");
  const en = storeData.text.find((item) => item.local === "en");
  const ja = storeData.text.find((item) => item.local === "ja");
  const vi = storeData.text.find((item) => item.local === "vi");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value: text,
      dataset: { locale }
    } = e.currentTarget;

    if (!locale) return;

    const hasData = storeData.text.find((txt) => txt.local === locale);

    if (hasData) {
      storeAction.setData(
        "text",
        storeData.text.map((txt) => (txt.local === locale ? { ...txt, title: text } : txt))
      );
      return;
    }
    storeAction.setData("text", [...storeData.text, { local: locale, title: text }]);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      value: text,
      dataset: { locale }
    } = e.currentTarget;

    if (!locale) return;

    const hasData = storeData.text.find((txt) => txt.local === locale);

    if (hasData) {
      storeAction.setData(
        "text",
        storeData.text.map((txt) => (txt.local === locale ? { ...txt, description: text } : txt))
      );
      return;
    }

    storeAction.setData("text", [...storeData.text, { local: locale, title: "", description: text }]);
  };
  return {
    ko,
    en,
    ja,
    vi,
    handleChange,
    handleTextAreaChange
  };
};
export default useDefaultFormHook;
