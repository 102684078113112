import { IconInfo } from "@/components/icons";

import { useHook } from "./hook";

export default function Head() {
  const { handleClick } = useHook();
  return (
    <div className="bg-white px-[30px] pb-[10px] pt-[20px]">
      <div className="mb-[20px]">
        <h2 className="text-[18px] font-semibold">의사정보 목록</h2>
      </div>
      <div className="mb-[15px] flex h-[40px] w-[600px] items-center gap-[8px] rounded-md border-2 border-black bg-orange-400 px-[10px]">
        <IconInfo fill="white" />
        <span>의사 면허증 번호 기준으로 의사정보를 관리할 수 있습니다.</span>
      </div>
      <div className="flex h-[50px] items-center justify-between rounded-md border-2 bg-gray-100 px-[20px]">
        <h3 className="text-[16px]">{localStorage.getItem("s_hospital") ?? ""}</h3>
        <button type="button" className="rounded-md bg-btn-blue px-[20px] py-[8px] text-white" onClick={handleClick}>
          의사 등록하기
        </button>
      </div>
    </div>
  );
}
