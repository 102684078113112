import React from "react";
import { useFieldAddressHook } from "@/pages/hospital/info/hooks";

export default function FieldAddress() {
  const { localeData, handleChange } = useFieldAddressHook();

  return (
    <div>
      <label className="label-required mr-[30px] text-[14px] font-medium text-form-purple">주소</label>
      <button
        type="button"
        className="bg-form-putext-form-purple inline-block h-[30px] rounded-md px-[20px] text-white"
      >
        주소검색
      </button>
      <div className="my-[10px] mt-[10px] flex gap-[10px]">
        <input
          type="text"
          className="h-[40px] w-[100px] rounded-md border-2 text-center"
          name="zipcode"
          value={localeData?.zipcode ?? ""}
          required
          onChange={handleChange}
        />
        <input
          type="text"
          className="h-[40px] grow rounded-md border-2 px-[20px]"
          name="address"
          value={localeData?.address ?? ""}
          required
          onChange={handleChange}
        />
      </div>
      {/* <input
        type="text"
        className="h-[40px] w-full rounded-md border-2 px-[20px]"
        name="sub_address"
        value={localeData?.sub_address ?? ""}
        required
        onChange={handleChange}
      /> */}
    </div>
  );
}
