import React from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { hospitalContactFetch } from "@/api";

import { ContactData } from "@/pages/hospital/contact/components/form/type";
import { query } from "@/pages/hospital/contact/query";

export const useHook = () => {
  const loaderData = useLoaderData() as ContactData;
  const navigate = useNavigate();
  const params = useParams();
  const quertClient = useQueryClient();

  const hospitalId = params.hospital_id as string;

  const { data } = useQuery<ContactData>({ ...query(hospitalId), initialData: loaderData });

  const mutation = useMutation({
    mutationFn: async (item: any) => {
      hospitalContactFetch.post(hospitalId, item);
    },
    onSuccess: () => {
      alert("성공");
      quertClient.invalidateQueries({ queryKey: query(hospitalId).queryKey });

      navigate(-1);
    },
    onError: () => alert("fail")
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const item = {
      main_phone: formData.getAll("main_phone").join("-"),
      inquiry_phone: formData.get("inquiry_phone"),
      contact_phone: formData.get("contact_phone"),
      contact_name: formData.get("contact_name"),
      mkt_email: formData.get("mkt_email")
    };

    mutation.mutate(item);
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    navigate(-1);
  };
  return {
    data,
    handleSubmit,
    handleReset
  };
};
