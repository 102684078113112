import React from "react";

import { useFieldThumnailHook } from "../../hooks/useFieldThumnailHook";
import { IconClose, IconInfo } from "@/components/icons";

export default function FieldThumnail() {
  const { thumnail, required, handleChange, handleClick } = useFieldThumnailHook();

  return (
    <div>
      <div className="mb-[10px]">
        <h4 className="label-required mr-[30px] inline text-[14px] font-medium text-form-purple">병원 목록 썸네일</h4>
        <label className="relative inline cursor-pointer rounded-md bg-form-green px-[20px] py-[5px] text-white">
          이미지 선택
          <input
            type="file"
            className="sr-only bottom-0 left-0 scroll-mt-[50px]"
            accept="image/*"
            required={required}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        {thumnail?.image_url && thumnail?.state !== "DELETE" && (
          <div className="relative mb-1 w-[300px] border-2 bg-green-200">
            <img src={thumnail?.image_url} alt="" className="aspect-[1080/284] rounded-md object-contain" />
            <button type="button" className="absolute right-2 top-1" name="thumb_image" onClick={handleClick}>
              <IconClose width={12} height={12} />
            </button>
          </div>
        )}
        <div className="flex gap-[10px]">
          <IconInfo className="fill-form-green" />
          <span>1080px * 284px, 최대 2M</span>
        </div>
      </div>
    </div>
  );
}
