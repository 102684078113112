import { IconInfo } from "@/components/icons";

import { TABS } from "./constants";
import { useHook } from "./hook";
import { isSame } from "./utils";
import { Box } from "@/pages/hospital/info/components";
import {
  FieldAddress,
  FieldButton,
  FieldLanguage,
  FieldLogo,
  FieldMedical,
  FieldMulti,
  FieldService,
  FieldSimpleAddress,
  FieldSubway,
  FieldThumnail
} from "@/pages/hospital/info/components/field";
import FieldSchedule from "../field/FieldSchedule";

export default function HospitalInfoForm() {
  const { i18n, isKorea, storeData, handleButtonClick, handleChange, handleReset, handleSubmit } = useHook();

  return (
    <div>
      {/* 탭 */}
      <div className="flex h-[40px]">
        {TABS.map((locale) => (
          <button
            type="button"
            key={locale.id}
            className="relative mt-[2px] h-full w-[100px] rounded-tl-md rounded-tr-md border-2 bg-slate-300 data-[active=true]:border-b-0 data-[active=true]:bg-white"
            name="tab"
            value={locale.id}
            data-active={isSame(locale.id, storeData.locale)}
            onClick={handleButtonClick}
          >
            {locale.text}
          </button>
        ))}
      </div>
      <form
        className="w-[800px] overflow-hidden rounded-lg rounded-tl-none border-2"
        onReset={handleReset}
        onSubmit={handleSubmit}
      >
        {/* box-1 인사말 */}
        <Box>
          <div className="mb-[30px]">
            <label className="label-required text-[14px] font-medium text-form-purple">{i18n.name.label}</label>
            <input
              type="text"
              className="my-[5px] block h-[40px] w-full rounded-md border-2 px-[20px] disabled:bg-slate-300"
              name="title"
              value={storeData[storeData.locale]?.title ?? ""}
              placeholder={i18n.name.placeholder}
              disabled={storeData.locale === "ko"}
              required
              onChange={handleChange}
            />
            <div className="flex gap-[10px]">
              <IconInfo />
              <span>{i18n.name.message}</span>
            </div>
          </div>
          <div className="mb-[30px]">
            <label className="label-required text-[14px] font-medium text-form-purple">{i18n.slogan.label}</label>
            <div className="relative">
              <input
                type="text"
                className="my-[5px] block h-[40px] w-full rounded-md border-2 px-[20px]"
                name="sub_title"
                value={storeData[storeData.locale]?.sub_title ?? ""}
                maxLength={50}
                required
                placeholder={i18n.slogan.placeholder}
                onChange={handleChange}
              />
              <span className="absolute bottom-[5px] right-[10px]">{`${storeData[storeData.locale]?.sub_title?.length ?? 0} / 50`}</span>
            </div>
            <div className="flex gap-[10px]">
              <IconInfo />
              <span>
                병원대표 전화번호, SNS ID, 홈페이지 주소는 등록 할 수 없습니다. 임의로 내용 수정 될 수 있습니다.
              </span>
            </div>
          </div>
          <div>
            <label className="label-required text-[14px] font-medium text-form-purple">{i18n.simple.label}</label>
            <div className="relative my-[5px]">
              <textarea
                className="block h-[150px] w-full resize-none rounded-md border-2 p-[20px]"
                name="description"
                value={storeData[storeData.locale]?.description ?? ""}
                maxLength={300}
                required
                placeholder={i18n.simple.placeholder}
                onChange={handleChange}
              />
              <span className="absolute bottom-[5px] right-[10px]">{`${storeData[storeData.locale]?.description?.length ?? 0} / 300`}</span>
            </div>
            <div className="flex items-center gap-[10px]">
              <IconInfo />
              <span>
                병원대표 전화번호, SNS ID, 홈페이지 주소는 등록 할 수 없습니다. 임의로 내용 수정 될 수 있습니다.
              </span>
            </div>
          </div>
        </Box>
        {/* box-2 주소 */}
        <Box>
          {isKorea ? <FieldAddress /> : <FieldSimpleAddress />}
          {isKorea && <FieldSubway />}
        </Box>
        {/* box-3 시간 only Korea */}
        {isKorea && <FieldSchedule />}
        {/* box-4 진료 only Korea */}
        {isKorea && <FieldMedical />}
        {/* box-5 언어 only Korea  */}
        {isKorea && <FieldLanguage />}
        {/* box-6 부가서비스 only Korea */}
        {isKorea && <FieldService />}
        {/* box-7  */}
        <Box>
          <div className="flex gap-[100px]">
            <FieldThumnail />
            {isKorea && <FieldLogo />}
          </div>
        </Box>

        {/* box-7 이미지-대표 */}
        <Box>
          <FieldMulti />
        </Box>
        {/* box-last 버튼 */}
        <Box>
          <FieldButton />
        </Box>
      </form>
    </div>
  );
}
