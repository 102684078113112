import { useRef, useState } from "react";
import { Link, LoaderFunctionArgs, useLoaderData, useSearchParams } from "react-router-dom";
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

import Nav from "@/components/nav";
import { IconClose } from "@/components/icons";
import { Pagination } from "@/components/pagination";
import { eventFetch } from "@/api";

const eventListQuery = (searchParams: URLSearchParams) => ({
  queryKey: ["event", "list", searchParams.toString()],
  queryFn: async () => {
    const newSearch = new URLSearchParams();

    const st = searchParams.get("st");
    const sv = searchParams.get("sv");
    const page = searchParams.get("page");

    if (page) newSearch.set("page", `${+page - 1}`);
    if (st && sv) newSearch.set(st, sv);

    try {
      const response = await eventFetch.get(newSearch.toString());

      return response.data;
    } catch {
      return {
        items: []
      };
    }
  }
});

export const EventListLoader = (queryClient: QueryClient) => (args: LoaderFunctionArgs) => {
  const url = new URL(args.request.url);

  return queryClient.ensureQueryData(eventListQuery(url.searchParams));
};

export default function EventListPage() {
  const loaderData = useLoaderData();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const [allowState, setAllowState] = useState({ id: "", state: false });

  const dialogRef = useRef<HTMLDialogElement>(null);

  const { data: eventList, isSuccess } = useQuery<any>({
    ...eventListQuery(searchParams),
    initialData: loaderData
  });

  const patchMutation = useMutation({
    mutationFn: async ({ type, id, body }: any) => eventFetch.patch(type, id, body),
    onSuccess: () => {
      alert("good");
      queryClient.invalidateQueries({ queryKey: ["event", "list"] });
      if (dialogRef.current?.open) dialogRef.current?.close();
    },
    onError: (error: any) => {
      const code = error?.data?.code;
      const message = error?.data?.message;

      alert(`실패 / ${code} / ${message}`);

      if (dialogRef.current?.open) dialogRef.current?.close();
    }
  });

  return (
    <div>
      <Nav
        list={[
          { title: "이벤트관리", link: "/event/list" },
          { title: "이벤트목록", link: null }
        ]}
      />
      <div className="w-max bg-white px-[30px] py-[40px] text-black">
        <div className="mb-[20px] pb-[10px]">
          <h2 className="text-[18px] font-semibold">이벤트 목록</h2>
        </div>
        <div className="flex items-center justify-between">
          <Link to={"/event/regist"} className="rounded-md bg-btn-blue px-[10px] py-[5px] text-white">
            이벤트 등록
          </Link>
          <form
            className="mb-[10px] flex justify-end gap-[8px]"
            onSubmit={(e) => {
              e.preventDefault();

              const formData = new FormData(e.currentTarget);
              // search-type = st
              const st = formData.get("st") as string;
              // search-value = sv
              const sv = formData.get("sv") as string;

              if (st === "all") {
                setSearchParams();
                return;
              }
              setSearchParams({ st, sv });
            }}
          >
            <select className="rounded border p-[10px]" name="st" defaultValue={searchParams.get("st") ?? ""} required>
              <option value="" hidden>
                검색
              </option>
              <option value="all">전체</option>
              <option value="id">ID</option>
              <option value="hospital_title">병원명</option>
              <option value="title">이벤트명</option>
              <option value="medical">시술대분류</option>
              <option value="allow">노출상태</option>
            </select>
            <input
              type="text"
              className="w-[300px] rounded border p-[10px]"
              name="sv"
              defaultValue={searchParams.get("sv") ?? ""}
              required
            />
            <button className="rounded-md bg-btn-blue px-[20px] text-white">검색</button>
          </form>
        </div>
        <table className="mb-[60px] border-t text-center">
          <caption className="sr-only">이벤트 리스트 테이블</caption>
          <colgroup>
            <col className="w-[100px]" data-label="ID" />
            <col className="w-[100px]" data-label="대상국가" />
            <col className="w-[120px]" data-label="병원명" />
            <col className="w-[300px]" data-label="이벤트명" />
            <col className="w-[160px]" data-label="이벤트 기간" />
            <col className="w-[140px]" data-label="가격" />
            <col className="w-[140px]" data-label="서술 대분류" />
            <col className="w-[100px]" data-label="상담수" />
            <col className="w-[100px]" data-label="평점" />
            <col className="w-[100px]" data-label="노출상태" />
            <col className="w-[140px]" data-label="버튼" />
          </colgroup>
          <thead className="border-b-2 border-black text-[14px]">
            <tr className="h-[40px]">
              {[
                "ID",
                "대상국가",
                "병원명",
                "이벤트명",
                "이벤트 기간",
                "가격",
                "서술 대분류",
                "상담수",
                "평점",
                "노출상태",
                "버튼"
              ].map((title) => (
                <td
                  key={`consultant-${title}`}
                  className="relative after:absolute after:right-0 after:top-1/2 after:h-1/2 after:-translate-y-1/2 after:border-e-2 last:text-white last:after:hidden"
                >
                  {title}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {isSuccess &&
              eventList.items.map((item: any) => (
                <tr
                  key={`event-list-${item.event_id}-${Math.floor(Math.random() * 10000)}`}
                  className="h-[40px] border-b"
                >
                  <td>{item.event_id}</td>
                  <td>{item.local}</td>
                  <td>{item.hospital_title}</td>
                  <td>
                    <figure className="flex items-center gap-[10px] p-[6px] [&>img]:has-[img[src]]:bg-transparent">
                      <img
                        src={item.thumb_big_image_url}
                        alt="thumnail"
                        className="aspect-square bg-gray-300"
                        width={80}
                        height={80}
                      />
                      <figcaption className="line-clamp-2 text-left leading-[1]">{item.event_title}</figcaption>
                    </figure>
                  </td>
                  <td>{`${dayjs.utc(item.open_start_date).tz().format("YYYY.MM.DD")} ~ ${item.open_end_date ? dayjs.utc(item.open_end_date).tz().format("YYYY.MM.DD") : ""}`}</td>
                  <td>{`${item.price.toLocaleString("ko-Kr")}원`}</td>
                  <td>{item.medical_category.map((v: any) => v.title).join(",")}</td>
                  <td>{item.consult_cnt}</td>
                  <td>{item.eval}</td>
                  <td>{item.is_open_allow ? "노출" : "비노출"}</td>
                  <td>
                    <div className="m-auto flex w-fit flex-col items-baseline gap-[5px]">
                      <Link
                        to={`/event/detail/${item.event_id}`}
                        className="inline-block rounded-md border border-black bg-gray-200 px-[10px] py-[6px]"
                      >
                        승인요청상세보기
                      </Link>
                      {item.is_open_allow ? (
                        <button
                          type="button"
                          className="min-w-[90px] rounded-md bg-red-600 p-[6px] text-white"
                          onClick={() => {
                            setAllowState({ id: item.event_id, state: false });
                            dialogRef.current?.showModal();
                          }}
                        >
                          비노출로 전환
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="min-w-[90px] rounded-md bg-blue-600 p-[6px] text-white"
                          onClick={() => {
                            setAllowState({ id: item.event_id, state: true });
                            dialogRef.current?.showModal();
                          }}
                        >
                          노출로 전환
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination totalItem={eventList?.paging_info?.total_item_count ?? 0} viewCount={10} />
      </div>
      <dialog ref={dialogRef} className="rounded-xl">
        <form method="dialog" className="w-[400px]">
          {/* head */}
          <div className="flex h-[50px] w-full items-center justify-between bg-slate-700 pl-[20px] pr-[10px]">
            <h3 className="text-[20px] text-white">노출변경</h3>
            <button>
              <IconClose fill="white" />
            </button>
          </div>
          {/* content */}
          <div className="px-[16px] py-[20px]">
            <p className="mb-[5px]">{`이벤트를 ${allowState.state ? "노출" : "미노출"} 할 수 있습니다.`}</p>
            <p className="mb-[5px]">이벤트를 다시 노출하려면 검수가 필요합니다.</p>
            <p>{`정말 ${allowState.state ? "노출" : "미노출"}로 변경하시겠습니까?`}</p>
          </div>
          {/* footer */}
          <div className="px-[16px] pb-[20px] text-right text-white">
            <button className="mr-[10px] rounded-full bg-stone-400 px-[16px] py-[10px]">취소</button>
            {allowState.state ? (
              <button
                type="button"
                className="rounded-full bg-blue-600 px-[24px] py-[10px]"
                onClick={() =>
                  patchMutation.mutate({ type: "allow", id: allowState.id, body: { is_open_allow: true } })
                }
              >
                노출
              </button>
            ) : (
              <button
                type="button"
                className="rounded-full bg-red-600 px-[24px] py-[10px]"
                onClick={() =>
                  patchMutation.mutate({ type: "allow", id: allowState.id, body: { is_open_allow: false } })
                }
              >
                미노출
              </button>
            )}
          </div>
        </form>
      </dialog>
    </div>
  );
}
