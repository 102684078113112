import { consultantFetch, dashboardFetch, marketingFetch } from "@/api";

export const dashboardLoader = async () => {
  const marketing = await dashboardFetch.get("?type=MARKETER");
  const hospital = await dashboardFetch.get("?type=HOSPITAL");
  const consultation = await dashboardFetch.get("?type=CONSULTATION");

  const marketingList = await marketingFetch.get("");
  const consultantList = await consultantFetch.get("");

  // if (marketing.ok && hospital.ok && consultation.ok)
  return {
    marketing,
    hospital,
    consultation,
    marketingList,
    consultantList
  };
};
