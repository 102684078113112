import React, { useRef, useState } from "react";

import { hospitalDetailFetch } from "@/api";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { HospitalDetail, PropsType } from "./types";
import { hospitalFetch, userFetch } from "@/api";
import { query } from "../../query";

export const useHook = () => {
  const loaderData = useLoaderData();
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const imageRef = useRef<HTMLDialogElement>(null);

  const [hospitalId, setHospitalId] = useState<string | null>(null);

  const { data: hospitalList } = useQuery<any>({
    ...query(searchParams),
    initialData: loaderData
  });

  const { data: adminList } = useQuery<any>({
    queryKey: ["admin", "list"],
    queryFn: async () => {
      const response = await userFetch.get();

      return response.data.items;
    }
  });

  const patchMutation = useMutation({
    mutationFn: async ({ type, id, body }: { type: "state" | "manager" | "allow"; id: string; body: any }) =>
      hospitalFetch.patch(type, id, body),
    onSuccess: () => {
      alert("Success");

      queryClient.invalidateQueries({ queryKey: ["hospital", "list"] });
    },
    onError: () => {
      alert("failed");
    }
  });

  const handleOpenAllowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const openAllow = e.currentTarget.value;
    const hospitalId = e.currentTarget.dataset.hospitalId as string;

    patchMutation.mutate({ type: "allow", id: hospitalId, body: { is_open_allow: openAllow } });
  };

  const handleManagerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const adminId = e.currentTarget.value;
    const hospitalId = e.currentTarget.dataset.hospitalId as string;

    patchMutation.mutate({ type: "manager", id: hospitalId, body: { admin_user_id: adminId } });
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {
      value,
      dataset: { id }
    } = e.currentTarget;

    if (value === "show-image") {
      setHospitalId(id as string);
      imageRef.current?.showModal();
    }
  };

  return {
    imageRef,
    hospitalId,
    handleClick,
    hospitalList: hospitalList?.items ?? [],
    totalItemCount: hospitalList?.paging_info?.total_item_count ?? 0,
    adminList: adminList ?? [],
    handleOpenAllowChange,
    handleManagerChange
  };
};

export const useModalHook = ({ hospitalId }: PropsType) => {
  const [selectLocale, setSelectLocale] = useState("ko");

  const { data } = useQuery<HospitalDetail>({
    queryKey: ["hospital", "info", hospitalId],
    queryFn: async () => {
      const response = await hospitalDetailFetch.get(hospitalId as string);

      return response.data;
    },
    enabled: hospitalId !== null
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectLocale(e.currentTarget.value);
  };

  return {
    selectLocale,
    data: {
      id: data?.hospital_id,
      thumImage: data?.[selectLocale]?.thumb_image?.image_url ?? null,
      multiImage: data?.[selectLocale]?.main_multi_image?.map((item) => item.image_url) ?? [null],
      desImage: data?.[selectLocale]?.des_image?.image_url ?? null
    },
    handleClick
  };
};
