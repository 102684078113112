import { useFieldButtonHook } from "../../hooks";

export default function FieldButton() {
  const { storeData, handleClick } = useFieldButtonHook();
  return (
    <div className="flex justify-between">
      <div className="flex gap-[10px]">
        {storeData.state !== "COMPLETE" && (
          <button type="button" className="bg-blue-400 px-[20px] py-[6px] text-center text-white" onClick={handleClick}>
            승인
          </button>
        )}
      </div>

      <div className="flex gap-[10px]">
        <button type="reset" className="bg-btn-gray px-[20px] py-[6px] text-white">
          목록
        </button>
        <button type="submit" className="bg-btn-red px-[20px] py-[6px] text-white">
          수정저장
        </button>
      </div>
    </div>
  );
}
