import React from "react";

export default function HospitalInfoHeader() {
  return (
    <div>
      <div className="mb-[20px] border-b-2 border-black pb-[10px]">
        <h2 className="mr-[15px] inline text-[18px] font-semibold">병원 기본 정보등록</h2>
        <p className="inline">병원의 기본정보를 등록하는 페이지 입니다.</p>
      </div>
      <h3 className="mb-[10px] text-[14px] font-medium">병원 기본정보</h3>
    </div>
  );
}
