import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { doctorFetch } from "@/api";
import { query } from "@/pages/hospital/doctor/list/query";

export const useHook = () => {
  const queryClient = useQueryClient();
  const params = useParams();

  const hospitalId = params.hospital_id as string;

  const postMutation = useMutation({
    mutationFn: async (item: any) => doctorFetch.post(item),
    onSuccess: () => {
      alert("등록 성공");

      queryClient.invalidateQueries({ queryKey: query(hospitalId).queryKey });
    },
    onError: () => alert("등록 실패")
  });

  const handleReset = (e: React.FormEvent<HTMLDialogElement>) => {
    e.currentTarget.close();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);

    postMutation.mutate({
      hospital_id: hospitalId,
      text: [{ local: "ko", title: formData.get("name") }],
      type: formData.get("type"),
      d_license: formData.get("license")
    });
  };

  return { handleSubmit, handleReset };
};
