export const i18n = {
  ko: {
    name: {
      label: "병원명",
      placeholder: "",
      message: "병원명 수정을 원하시면 관리자에게 문의하기를 통해 문의주세요"
    },
    slogan: {
      label: "병원슬로건",
      placeholder: "한줄로 표현하는 홍보글 작성해주세요."
    },
    simple: {
      label: "간단소개",
      placeholder: "실리프팅에 진심, 실리프팅 특화 의료기관 예뻐지는 성형외과 의원입니다."
    }
  },
  en: {
    name: {
      label: "병원명 영어버전",
      placeholder: "영어 버전의 병원명을 입력해주세요.",
      message: "한국어외 병원명은 수정 가능합니다."
    },
    slogan: {
      label: "병원슬로건 영어버전",
      placeholder: "영어로 한줄 홍보글 작성해주세요."
    },
    simple: {
      label: "간단소개 영어버전",
      placeholder: "영어버전으로 입력해주세요."
    }
  },
  ja: {
    name: {
      label: "병원명 일어버전",
      placeholder: "베트남어 버전의 병원명을 입력해주세요.",
      message: "한국어외 병원명은 수정 가능합니다."
    },
    slogan: {
      label: "병원슬로건 일어버전",
      placeholder: "베트남어로 한줄 홍보글 작성해주세요."
    },
    simple: {
      label: "간단소개 일어버전",
      placeholder: "일어버전으로 입력해주세요."
    }
  },
  vi: {
    name: {
      label: "병원명 베트남어버전",
      placeholder: "일어 버전의 병원명을 입력해주세요.",
      message: "한국어외 병원명은 수정 가능합니다."
    },
    slogan: {
      label: "병원슬로건 베트남어버전",
      placeholder: "일어로 한줄 홍보글 작성해주세요."
    },
    simple: {
      label: "간단소개 베트남어버전",
      placeholder: "베트남어버전으로 입력해주세요."
    }
  }
} as const;

export const TABS = [
  {
    id: "ko",
    text: "Korean"
  },
  {
    id: "en",
    text: "English"
  },
  {
    id: "ja",
    text: "Japaness"
  },
  {
    id: "vi",
    text: "Vietnam"
  }
] as const;
