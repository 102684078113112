export const NAV_LIST = [
  { title: "이벤트관리", link: null },
  { title: "이벤트 온라인 상담", link: null }
];

export const TABLE_HEAD = [
  {
    id: "col-0",
    text: "신청일시",
    class: "w-[120px]"
  },
  {
    id: "col-1",
    text: "고객정보",
    class: "w-[130px] text-left"
  },
  {
    id: "col-2",
    text: "연락처",
    class: "w-[120px]"
  },
  {
    id: "col-3",
    text: "상담 이벤트 ID",
    class: "w-[240px]"
  },
  {
    id: "col-4",
    text: "상담 진행절차",
    class: "w-[340px]"
  },
  {
    id: "col-5",
    text: "내원일정",
    class: "w-[130px]"
  },
  {
    id: "col-6",
    text: "시술일정",
    class: "w-[130px]"
  },
  {
    id: "col-7",
    text: "집도의",
    class: "w-[90px]"
  },
  {
    id: "col-8",
    text: "메모",
    class: "w-[110px]"
  }
] as const;

export const CONSULTATION_PROCESS = {
  0: [
    {
      id: "deps-0-0",
      value: "HOLD",
      status: "blocked",
      text: "신규상담"
    },
    {
      id: "deps-0-1",
      value: "CF",
      status: "fail",
      text: "통화실패"
    },
    {
      id: "deps-0-2",
      value: "NV",
      status: "fail",
      text: "내원안함"
    },
    {
      id: "deps-0-3",
      value: "NR",
      status: "warn",
      text: "재전화필요"
    },
    {
      id: "deps-0-4",
      value: "CC",
      status: "success",
      text: "전화필요"
    }
  ],
  1: [
    {
      id: "deps-1-0",
      value: "HOLD",
      status: "blocked",
      text: "-"
    },
    {
      id: "deps-1-1",
      value: "VCA",
      status: "fail",
      text: "내원예약취소"
    },
    {
      id: "deps-1-2",
      value: "NT",
      status: "fail",
      text: "시술안함"
    },
    {
      id: "deps-1-3",
      value: "VS",
      status: "warn",
      text: "내원예정"
    },
    {
      id: "deps-1-4",
      value: "VCO",
      status: "success",
      text: "내원완료"
    }
  ],
  2: [
    {
      id: "deps-2-0",
      value: "HOLD",
      status: "blocked",
      text: "-"
    },
    {
      id: "deps-2-1",
      value: "TS",
      status: "fail",
      text: "시술예정"
    },
    {
      id: "deps-2-2",
      value: "NT",
      status: "warn",
      text: "시술안함"
    },
    {
      id: "deps-2-3",
      value: "TC",
      status: "success",
      text: "시술완료"
    }
  ]
} as const;
