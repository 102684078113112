import React, { ComponentProps } from "react";

type ButtonStatus = "default" | "blocked" | "fail" | "warn" | "success";

export function ProcessButton({
  children,
  status,
  props
}: {
  children: React.ReactNode;
  status: ButtonStatus;
  props?: ComponentProps<"button">;
}) {
  const style: Record<ButtonStatus, string> = {
    default: "rounded-md border w-[100px] py-[6px]",
    blocked: "border-black bg-gray-200 text-gray-400",
    fail: "bg-red-300 border-red-500",
    warn: "bg-yellow-200 border-yellow-600",
    success: "bg-green-200 border-green-500"
  };

  return (
    <button type="button" className={`${style["default"]} ${style[status]}`} {...props}>
      {children}
    </button>
  );
}
