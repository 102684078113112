import React, { forwardRef } from "react";

import { IconClose, IconInfo } from "@/components/icons";
import { useModalHook } from "./hooks";

export default forwardRef<HTMLDialogElement>(function HospitalRegistModal(_, ref) {
  const { handleReset, handleSubmit } = useModalHook();

  return (
    <dialog ref={ref} className="rounded-xl" onReset={handleReset}>
      <form method="dialog" className="w-[500px]" onSubmit={handleSubmit}>
        <div className="flex h-[50px] items-center justify-between bg-gray-600 px-[20px] text-white">
          <h3 className="text-[18px]">병원 등록하기</h3>
          <button type="reset">
            <IconClose fill="white" />
          </button>
        </div>
        <div className="flex h-[200px] flex-col justify-between p-[20px]">
          <div>
            <label htmlFor="regist-input">병원명</label>
            <input
              type="text"
              id="regist-input"
              className="mb-[8px] mt-[5px] h-[40px] w-full rounded-md border px-[10px]"
              name="title"
              placeholder="병원명을 입력해주세요"
              required
            />
            <div className="flex gap-[4px] text-red-500">
              <IconInfo fill="red" />
              미인 관리자만 변경 가능합니다.
            </div>
          </div>
          <div className="h-[30px] self-end">
            <button type="reset" className="mr-[10px] h-full rounded-3xl bg-stone-400 px-[1.5em] text-white">
              취소
            </button>
            <button className="h-full rounded-3xl bg-red-600 px-[1.5em] text-white">등록하기</button>
          </div>
        </div>
      </form>
    </dialog>
  );
});
