import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import reportWebVitals from "./reportWebVitals";
import router from "./pages/routes";

import "@/libs/dayjs";
import "./index.css";

const rootElement = document.getElementById("root") as HTMLElement;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
});

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router(queryClient)} />
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);

if (process.env.NODE_ENV === "development") reportWebVitals(console.debug);
