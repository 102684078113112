import React from "react";
import { useQuery } from "@tanstack/react-query";

import Nav from "@/components/nav";
import { userFetch } from "@/api";
import InquiryBoard from "@/pages/inquiry/marketing/components/board";
import InquiryTable from "@/pages/inquiry/marketing/components/table";

export default function MarketingListPage() {
  const { isSuccess, data } = useQuery({
    queryKey: ["admin", "user", "list", "ADMIN,SADMIN"],
    queryFn: async () => {
      const response = await userFetch.get("?types=SUPER,ADMIN,SADMIN");

      return response.data.items;
    }
  });

  return (
    <div>
      <Nav
        list={[
          { title: "문의관리", link: null },
          { title: "마케팅 문의관리 목록", link: "/inquiry/marketing" }
        ]}
      />
      <div className="flex min-w-[1080px] flex-col gap-[30px] text-white">
        <InquiryBoard />
        {isSuccess ? <InquiryTable adminList={data} /> : null}
      </div>
    </div>
  );
}
