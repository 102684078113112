import { QueryClient } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";

import { useQueryFn } from "@/pages/hospital/doctor/detail/hooks";

const loader =
  (queryClient: QueryClient) =>
  ({ params: { doctor_id } }: LoaderFunctionArgs) =>
    queryClient.ensureQueryData(useQueryFn(doctor_id as string));
export default loader;
