export const TABLE_HEAD = [
  {
    id: 1,
    text: "등록상태",
    visible: true,
    class: "w-[160px]"
  },
  {
    id: 2,
    text: "노출상태",
    visible: true,
    class: "w-[150px]"
  },
  {
    id: 3,
    text: "담당자",
    visible: true,
    class: "w-[100px]"
  },
  {
    id: 4,
    text: "진료과목",
    visible: true,
    class: "w-[200px]"
  },
  {
    id: 5,
    text: "병원명",
    visible: true,
    class: "w-[100px]"
  },
  {
    id: 6,
    text: "병원위치",
    visible: true,
    class: "w-[100px]"
  },
  {
    id: 7,
    text: "병원 의사 정보",
    visible: true,
    class: "w-[140px]"
  },
  {
    id: 8,
    text: "소개이미지",
    visible: true,
    class: "w-[140px]"
  },
  {
    id: 9,
    text: "병원 연락처 정보",
    visible: true,
    class: "w-[140px]"
  },
  {
    id: 10,
    text: "병원 담당자 번호",
    visible: true,
    class: "w-[140px]"
  },
  {
    id: 11,
    text: "병원 담당자명",
    visible: true,
    class: "w-[120px]"
  },
  {
    id: 12,
    text: "등록일자",
    visible: true,
    class: "w-[200px]"
  }
] as const;

export const LocaleData = [
  {
    key: "image-modal-nav-ko",
    value: "ko",
    text: "Korean"
  },
  {
    key: "image-modal-nav-en",
    value: "en",
    text: "English"
  },
  {
    key: "image-modal-nav-ja",
    value: "ja",
    text: "Japaness"
  },
  {
    key: "image-modal-nav-vi",
    value: "vi",
    text: "Vietnam"
  }
] as const;
