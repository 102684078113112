import useStore from "@/pages/hospital/info/store";

export const useFieldAddressHook = () => {
  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const locale = storeData.locale;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    storeAction.setLocaleData(locale, { [name]: value });
  };

  return {
    localeData: storeData[locale],
    handleChange
  };
};
