import useStore from "@/pages/hospital/info/store";
import { ImageItem } from "../types";

export const useFieldMultiHook = () => {
  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const locale = storeData.locale;
  const multi = storeData[locale]?.main_multi_image ?? [];

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (!files) return;
    if (files.length < 1) return;

    const arr = [];

    for (const file of files) {
      arr.push(
        new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.onload = (ev) => {
            const url = ev.target?.result as string;
            resolve({ image_url: url, image: file, state: "INSERT" });
          };
          fileReader.readAsDataURL(file);
        })
      );
    }
    const newImages = (await Promise.all(arr)) as ImageItem[];

    storeAction.setLocaleData(locale, { main_multi_image: [...multi, ...newImages] });
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    const selectId = +value;

    if (!multi) return;

    const isOld = multi.find((item) => item?.image_id === selectId);

    if (isOld) {
      storeAction.setLocaleData(locale, {
        main_multi_image: multi.map((item) => {
          if (item?.image_id !== selectId) return item;
          return { ...item, state: "DELETE" };
        })
      });
      return;
    }
    storeAction.setLocaleData(locale, {
      main_multi_image: multi.filter((item) => item?.image_id === selectId)
    });
  };

  return {
    multi,
    required: multi.length < 1,
    handleChange,
    handleClick
  };
};
