import React from "react";
import Nav from "@/components/nav";
import { HospitalListBoard, HospitalListHeader } from "./components";

export default function HospitalListPage() {
  return (
    <div>
      <Nav list={[{ title: "병원관리", link: null }]} />
      <div className="flex flex-col gap-[10px] bg-white px-[30px] py-[20px]">
        <HospitalListHeader />
        <HospitalListBoard />
      </div>
    </div>
  );
}
