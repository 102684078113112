import { create } from "zustand";

interface State {
  medical: string;
  category: string;
  treatment: string;
}

interface Action {
  setMedical: (selectId: string) => void;
  setCategory: (selectId: string) => void;
  setTreatment: (selectId: string) => void;
  reset: () => void;
}

const initialState: State = {
  medical: "",
  category: "",
  treatment: ""
};

export const useMedicalStore = create<State & Action>((set) => ({
  ...initialState,
  setMedical: (selectId) => set(() => ({ medical: selectId, category: "", treatment: "" })),
  setCategory: (selectId) => set(() => ({ category: selectId, treatment: "" })),
  setTreatment: (selectId) => set(() => ({ treatment: selectId })),
  reset: () => set(() => initialState)
}));
