import { dashboardFetch } from "@/api";
import { useQuery } from "@tanstack/react-query";
import React from "react";

export default function InquiryBoard() {
  const { data, isSuccess } = useQuery({
    queryKey: ["inquiry", "consultant", "board"],
    queryFn: async () => {
      const response = await dashboardFetch.get("?type=CONSULTATION");

      return response.data;
    }
  });

  if (!isSuccess) return null;
  return (
    <div className="flex w-[1080px] flex-col gap-[3px] bg-white">
      <div className="flex flex-col gap-[20px] bg-[url('@/assets/images/dashboard/consultant-top.png')] bg-contain p-[20px] pb-[10px]">
        <div className="flex items-center gap-[20px]">
          <span className="text-[16px]">상담문의</span>
          <span>금년 누적 상담건수</span>
          <span>{`${data.year_cnt} 건`}</span>
        </div>
        <div className="flex gap-[40px]">
          <div className="flex items-end gap-[20px]">
            <span>금일 등록된 문의 건수</span>
            <span className="text-[30px] leading-none">{data.today_cnt}</span>
          </div>
          <div className="flex items-end gap-[20px]">
            <span>확인완료 된 문의건수</span>
            <span className="text-[30px] leading-none">{data.cf_cnt}</span>
          </div>
          <div className="flex items-end gap-[20px]">
            <span>현재 미확인 문의</span>
            <span className="text-[30px] leading-none">{data.uf_cnt}</span>
          </div>
          <div className="flex items-end gap-[20px]">
            <span>금월 총 누적문의건수</span>
            <span className="text-[30px] leading-none">{data.month_cnt}</span>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-[20px] bg-[url('@/assets/images/dashboard/consultant-bot.png')] bg-contain px-[20px] py-[10px]">
        <span className="text-[14px]">금년도 누적건수</span>
        <span>{`${data.year_cnt} 건`}</span>
      </div>
    </div>
  );
}
