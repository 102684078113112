import React from "react";
import { Link } from "react-router-dom";

import { Pagination } from "@/components/pagination";
import { TABLE_HEAD } from "./constants";
import HospitalImageModal from "./Modal";
import { useHook } from "./hooks";
import { joinMedical, transDate } from "./utils";

export default function HospitalListBoard() {
  const {
    imageRef,
    hospitalId,
    adminList,
    hospitalList,
    totalItemCount,
    handleClick,
    handleOpenAllowChange,
    handleManagerChange
  } = useHook();

  return (
    <div>
      <div className="w-max text-black">
        <table className="mb-[60px] border-t text-center">
          <colgroup>
            {TABLE_HEAD.map((item) => (
              <col key={`table-col-${item.id}`} className={item.class} />
            ))}
          </colgroup>
          <thead className="border-b-2 border-black text-[14px]">
            <tr className="h-[40px]">
              {TABLE_HEAD.map((item) => (
                <td
                  key={`table-head-${item.id}`}
                  className="relative after:absolute after:right-0 after:top-1/2 after:h-1/2 after:-translate-y-1/2 after:border-e-2 last:after:hidden"
                >
                  {item.text}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {hospitalList.map((item: any) => (
              <tr key={`hospital-list-${item.hospital_id}`} className="h-[40px] border-b">
                <td className="relative">
                  <div className="absolute left-1/2 top-1/2 flex h-[90%] w-[70%] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-md border-2 border-purple-400 bg-orange-100">
                    {item.state === "WAIT" && "등록대기"}
                    {item.state === "COMPLETE" && "등록완료"}
                  </div>
                </td>
                <td>
                  <fieldset className="flex justify-evenly">
                    <label className="leading-[12px]">
                      <input
                        type="radio"
                        className="mr-[5px] aspect-square w-[12px] appearance-none rounded-full border-2 border-black bg-white align-middle checked:bg-green-300"
                        name={"hospital-" + item.hospital_id}
                        data-hospital-id={item.hospital_id}
                        value="true"
                        checked={item.is_open_allow}
                        onChange={handleOpenAllowChange}
                      />
                      <span className="align-middle">게시</span>
                    </label>
                    <label className="leading-[12px]">
                      <input
                        type="radio"
                        className="mr-[5px] aspect-square w-[12px] appearance-none rounded-full border-2 border-black bg-white align-middle checked:bg-green-300"
                        name={"hospital-" + item.hospital_id}
                        data-hospital-id={item.hospital_id}
                        value="false"
                        checked={!item.is_open_allow}
                        onChange={handleOpenAllowChange}
                      />
                      <span className="align-middle">대기</span>
                    </label>
                  </fieldset>
                </td>
                <td className="relative">
                  <select
                    className="absolute left-0 top-1/2 h-[90%] w-[90%] -translate-y-1/2 rounded-md border"
                    value={item.admin_user_id}
                    data-hospital-id={item.hospital_id}
                    onChange={handleManagerChange}
                  >
                    {adminList.map((admin: any) => (
                      <option key={`admin-list-${admin.admin_user_id}`} value={admin.admin_user_id}>
                        {admin.name}
                      </option>
                    ))}
                  </select>
                </td>
                <td>{joinMedical(item.medicals)}</td>
                <td className="relative">
                  <Link
                    to={`/hospital/regist/info/${item.hospital_id}`}
                    className="absolute left-0 top-0 h-full w-full content-center"
                  >
                    {item.title}
                  </Link>
                </td>
                <td>{item.address || "-"}</td>
                <td className="relative">
                  <Link
                    to={`/hospital/regist/doctor/${item.hospital_id}`}
                    className="absolute left-1/2 top-1/2 h-[90%] w-[70%] -translate-x-1/2 -translate-y-1/2 place-content-center rounded-md border border-purple-500 hover:bg-purple-500 hover:text-white"
                    onClick={() => localStorage.setItem("s_hospital", item.title)}
                  >
                    의사정보 보기
                  </Link>
                </td>
                <td className="relative">
                  <button
                    type="button"
                    className="absolute left-1/2 top-1/2 h-[90%] w-[70%] -translate-x-1/2 -translate-y-1/2 rounded-md border border-purple-500 hover:bg-purple-500 hover:text-white"
                    value={"show-image"}
                    data-id={item.hospital_id}
                    onClick={handleClick}
                  >
                    이미지 보기
                  </button>
                </td>
                <td className="relative">
                  <Link
                    to={`/hospital/regist/contact/${item.hospital_id}`}
                    className="absolute left-1/2 top-1/2 h-[90%] w-[70%] -translate-x-1/2 -translate-y-1/2 place-content-center rounded-md border border-purple-500 hover:bg-purple-500 hover:text-white"
                  >
                    연락처정보 보기
                  </Link>
                </td>
                <td>{item.contact_phone ?? "-"}</td>
                <td>{item.contact_name ?? "-"}</td>
                <td>{transDate(item.create_date)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination totalItem={totalItemCount} viewCount={10} />
      </div>
      <HospitalImageModal ref={imageRef} hospitalId={hospitalId} />
    </div>
  );
}
