import React from "react";

import Nav from "@/components/nav";

import { HospitalInfoForm, HospitalInfoHeader } from "./components";
import { NAV_LIST } from "./constant";

export default function HospitalInfoPage() {
  return (
    <div>
      <Nav list={NAV_LIST} />
      <div className="h-full min-w-full bg-white px-[30px] py-[20px]">
        <HospitalInfoHeader />
        <HospitalInfoForm />
      </div>
    </div>
  );
}
