import API_URL from "./apiUrl";
import cf from "./apiRequest";

export const loginFetch = async (body: any) =>
  cf.post(API_URL.LOGIN, JSON.stringify(body), { "Content-Type": "application/json;" });
export const logoutFetch = async () => cf.post(API_URL.LOGOUT, undefined, { "Content-Type": "application/json;" });

export const userFetch = {
  get: async (searchParams?: string) => cf.get(API_URL.USER, searchParams)
};

export const dashboardFetch = {
  get: async (searchParams: string) => cf.get(API_URL.DASHBOARD, searchParams)
};
export const dashboardSSE = () =>
  new EventSource(API_URL.SSE, {
    withCredentials: true
  });

export const consultantFetch = {
  get: async (searchParams: string) => cf.get(API_URL.CONSULTANT, searchParams)
};
export const consultantDetailFetch = {
  get: async (id: string) => cf.get(API_URL.CONSULTANT.concat("/", id)),
  patch: async (id: string, body: BodyInit) =>
    cf.patch(API_URL.CONSULTANT.concat("/", id), JSON.stringify(body), { "Content-Type": "application/json;" })
};

export const marketingFetch = {
  get: async (searchParams: string) => cf.get(API_URL.MARKETING, searchParams),
  patch: async (id: string, body: BodyInit) =>
    cf.patch(API_URL.MARKETING.concat("/", id), JSON.stringify(body), { "Content-Type": "application/json;" })
};

// 병원 ******************************************************************************
export const hospitalFetch = {
  get: async (searchParams?: string) => cf.get(API_URL.HOSPITAL, searchParams),
  post: async (body: any) =>
    cf.post(API_URL.HOSPITAL, JSON.stringify(body), {
      "Content-type": "application/json"
    }),
  patch: async (type: "state" | "manager" | "allow", id: string, body: any) => {
    if (!type) throw new Error("no type");

    let URL: string = API_URL.HOSPITAL_STATE;

    if (type === "manager") URL = API_URL.HOSPITAL_MANAGER;
    if (type === "allow") URL = API_URL.HOSPITAL_ALLOW;

    return cf.patch(URL.concat("/", id), JSON.stringify(body), {
      "Content-Type": "application/json;"
    });
  }
};
export const hospitalDetailFetch = {
  get: async (id: string) => cf.get(API_URL.HOSPITAL.concat("/", id)),
  put: async (id: string, body: BodyInit) => cf.put(API_URL.HOSPITAL.concat("/", id), body)
};

export const hospitalContactFetch = {
  get: async (id: string) => cf.get(API_URL.HOSPITAL_CONTACT.concat("/", id)),
  post: async (id: string, body: any) =>
    cf.post(API_URL.HOSPITAL_CONTACT.concat("/", id), JSON.stringify(body), { "Content-Type": "application/json" })
};
// ******************************************************************************

// 의사 ******************************************************************************
export const doctorFetch = {
  get: async (searchParams: string) => cf.get(API_URL.DOCTOR, searchParams),
  post: async (body: any) => cf.post(API_URL.DOCTOR, JSON.stringify(body), { "Content-Type": "application/json;" }),
  patch: async (type: "order" | "consult", body: any) =>
    cf.patch(type === "order" ? API_URL.DOCTOR_ORDER : API_URL.DOCTOR_CONSULT, JSON.stringify(body), {
      "Content-Type": "application/json;"
    })
};

export const doctorDetailFetch = {
  get: async (id: string) => cf.get(API_URL.DOCTOR.concat("/", id)),
  put: async (id: string, body: BodyInit) => cf.put(API_URL.DOCTOR.concat("/", id), body)
};
// ******************************************************************************

// 이벤트 ******************************************************************************
export const eventFetch = {
  get: async (searchParams?: string) => cf.get(API_URL.EVENT, searchParams),
  post: async (body: FormData) => cf.post(API_URL.EVENT, body),
  patch: async (type: "approval" | "allow", id: string, body: any) =>
    cf.patch(API_URL.EVENT.concat("/", type, "/", id), JSON.stringify(body), { "Content-Type": "application/json" })
};

export const eventDetailFetch = {
  get: async (id: string) => cf.get(API_URL.EVENT.concat("/", id)),
  put: async (id: string, body: FormData) => cf.put(API_URL.EVENT.concat("/", id), body)
};

export const eventApprovalFetch = {
  get: async (searchParams: string) => cf.get(API_URL.EVENT_APPROVAL, searchParams)
};
export const eventConsultFetch = {
  get: async (searchParams?: string) => cf.get(API_URL.EVENT_CONSULT, searchParams),
  patch: async (type: "step" | "memo" | "doctor" | "date", id: string, body: any) =>
    cf.patch(API_URL.EVENT_CONSULT.concat("/", type, "/", id), JSON.stringify(body), {
      "Content-Type": "application/json"
    })
};

// ******************************************************************************
/**
 * 공통 관리
 * 지하철, 응대 언어, 부가서비스
 */
export const commonFetch = {
  get: async (searchParams: string) => cf.get(API_URL.COMMON_SHARED, searchParams),
  post: async (body: any) =>
    cf.post(API_URL.COMMON_SHARED, JSON.stringify(body), { "Content-type": "application/json;" }),
  put: async (id: string, body: BodyInit) =>
    cf.put(API_URL.COMMON_SHARED.concat("/", id), JSON.stringify(body), { "Content-type": "application/json;" }),
  patch: async (body: any) =>
    cf.patch(API_URL.COMMON_SHARED_ALLOW, JSON.stringify(body), { "Content-type": "application/json;" })
};

export const commonMedicalFetch = {
  get: async () => cf.get(API_URL.MEDICAL),
  post: async (body: any) => cf.post(API_URL.MEDICAL, JSON.stringify(body), { "Content-Type": "application/json;" }),
  patch: async (body: any) => cf.patch(API_URL.MEDICAL, JSON.stringify(body), { "Content-Type": "application/json;" }),
  put: async (id: string, body: any) =>
    cf.put(API_URL.MEDICAL.concat("/", id), JSON.stringify(body), { "Content-Type": "application/json;" })
};

export const commonMedicalCategoryFetch = {
  get: async (searchParams?: string) => cf.get(API_URL.MEDICAL_CATEGORY, searchParams),
  post: async (body: any) => cf.post(API_URL.MEDICAL_CATEGORY, body),
  patch: async (body: any) =>
    cf.patch(API_URL.MEDICAL_CATEGORY, JSON.stringify(body), { "Content-Type": "application/json" }),
  orderPatch: async (body: any) =>
    cf.patch(API_URL.MEDICAL_CATEGORY.concat("/order"), JSON.stringify(body), { "Content-Type": "application/json" }),
  put: async (id: string, body: any) => cf.put(API_URL.MEDICAL_CATEGORY.concat("/", id), body)
};

export const commonMedicalTreatmentFetch = {
  get: async (searchParams: string) => cf.get(API_URL.MEDICAL_ITEM, searchParams),
  post: async (body: any) =>
    cf.post(API_URL.MEDICAL_ITEM, JSON.stringify(body), { "Content-Type": "application/json;" }),
  patch: async (body: any) =>
    cf.patch(API_URL.MEDICAL_ITEM, JSON.stringify(body), { "Content-Type": "application/json;" }),
  put: async (id: string, body: any) =>
    cf.put(API_URL.MEDICAL_ITEM.concat("/", id), JSON.stringify(body), {
      "Content-Type": "application/json;"
    })
};

export const commonMedicalSubjectFetch = {
  get: async (searchParams: string) => cf.get(API_URL.MEDICAL_SUBJECT, searchParams),
  post: async (body: any) =>
    cf.post(API_URL.MEDICAL_SUBJECT, JSON.stringify(body), { "Content-Type": "application/json;" }),
  patch: async (body: any) =>
    cf.patch(API_URL.MEDICAL_SUBJECT, JSON.stringify(body), { "Content-Type": "application/json;" }),
  put: async (id: string, body: any) =>
    cf.put(API_URL.MEDICAL_SUBJECT.concat("/", id), JSON.stringify(body), {
      "Content-Type": "application/json;"
    })
};

export const commonCountryFetch = {
  get: async () => cf.get(API_URL.COMMON_COUNTRY),
  patch: async (body: any) =>
    cf.patch(API_URL.COMMON_COUNTRY, JSON.stringify(body), { "Content-Type": "application/json;" })
};
