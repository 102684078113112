import React from "react";

import HospitalRegistModal from "./Modal";
import { useHook } from "./hooks";

export default function HospitalListHeader() {
  const { registRef, searchParams, handleSearchSubmit, handleRegistDialog } = useHook();

  return (
    <div>
      <h2 className="mb-[20px] text-[18px]">가입된 병원 목록</h2>
      <div className="flex items-center justify-between text-black">
        <button
          type="button"
          className="block h-[30px] w-[100px] rounded-md bg-btn-blue text-white"
          onClick={handleRegistDialog}
        >
          병원 등록하기
        </button>
        <form onSubmit={handleSearchSubmit}>
          <select
            className="rounded border p-[10px]"
            name="search_type"
            defaultValue={searchParams.get("st") ?? ""}
            required
          >
            <option hidden value="">
              검색
            </option>
            <option value="all">전체</option>
            <option value="state">등록상태</option>
            <option value="medical">진료과목</option>
            <option value="title">병원명</option>
            <option value="contact">병원 담당자 정보</option>
            <option value="admin">미인 담당자</option>
            <option value="allow">노출상태</option>
          </select>
          <input
            className="mx-[8px] w-[300px] rounded border p-[10px]"
            type="text"
            name="search_value"
            defaultValue={searchParams.get("sv") ?? ""}
            required
          />
          <input
            className="w-[60px] cursor-pointer rounded-md bg-btn-blue p-[10px] text-white"
            type="submit"
            value="검색"
          />
        </form>
      </div>
      <HospitalRegistModal ref={registRef} />
    </div>
  );
}
