import { eventConsultFetch } from "@/api";
import { IconClose } from "@/components/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { forwardRef, useRef } from "react";

interface Props {
  selectMemo: {
    id: string;
    text: string;
  };
}

export default forwardRef<HTMLDialogElement, Props>(function TextEditorModal({ selectMemo }, ref) {
  const formRef = useRef<HTMLFormElement>(null);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (memo: string) => eventConsultFetch.patch("memo", selectMemo.id, { memo }),
    onSuccess: () => {
      alert("성공");
      queryClient.invalidateQueries({ queryKey: ["event", "consultation", "process"] });
    },
    onError: () => {
      alert("실패");
    }
  });
  return (
    <dialog
      ref={ref}
      className="rounded-xl"
      onReset={(e) => e.currentTarget.close()}
      onClose={() => formRef.current?.reset()}
    >
      <form
        ref={formRef}
        method="dialog"
        className="w-[500px]"
        onSubmit={(e) => {
          const formData = new FormData(e.currentTarget);

          mutation.mutate(formData.get("memo") as string);
        }}
      >
        <div className="flex h-[50px] items-center justify-between bg-slate-800 px-[20px]">
          <h3 className="text-2xl text-white">{"메모"}</h3>
          <button type="reset">
            <IconClose fill="white" />
          </button>
        </div>
        <div className="p-[20px]">
          <div className="mb-[20px] flex flex-col gap-[5px]">
            <label>{"내용"}</label>
            <p className="text-blue-500">{"요청사항이 맨 윗줄부터 표기 예)[대기예약],[예약취소/변경],[고객요청]"}</p>
            <textarea
              className="w-full resize-none rounded-md border p-[5px]"
              name="memo"
              rows={9}
              placeholder="내용을 입력해주세요."
            />
          </div>
          <div className="text-right">
            <button className="rounded-3xl bg-red-500 px-[20px] py-[5px] text-white">저장</button>
          </div>
        </div>
      </form>
    </dialog>
  );
});
