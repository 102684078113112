import { useQuery } from "@tanstack/react-query";

import { commonFetch } from "@/api";

import useStore from "@/pages/hospital/info/store";

export const useFieldServiceHook = () => {
  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const { data: serviceData } = useQuery({
    queryKey: ["common", "service", "allow"],
    queryFn: async () => {
      const response = await commonFetch.get("?classify=ADDTL&allow=true");

      return response.data.items;
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked, name } = e.currentTarget;
    const selectId = +value;

    if (!storeData.shared_category) {
      storeAction.setData(name, [
        {
          shared_category_id: selectId,
          classify: "ADDTL",
          state: "INSERT"
        }
      ]);
      return;
    }
    const hasItem = storeData.shared_category.find(
      (item) => item.classify === "ADDTL" && item.shared_category_id === selectId
    );

    if (hasItem) {
      storeAction.setData(
        name,
        storeData.shared_category
          .filter((item) => {
            if (item.classify !== "ADDTL") return true;
            if (item.shared_category_id === selectId) {
              const isInsert = !checked && item.state === "INSERT";
              return isInsert ? false : true;
            }
            return true;
          })
          .map((item) => {
            if (item.classify !== "ADDTL") return item;
            if (item.shared_category_id === selectId) {
              return {
                ...item,
                state: checked ? "STAY" : "DELETE"
              };
            }
            return item;
          })
      );
      return;
    }
    storeAction.setData(name, [
      ...storeData.shared_category,
      {
        shared_category_id: selectId,
        classify: "ADDTL",
        state: "INSERT"
      }
    ]);
  };

  const checked = (id: number) => {
    const result = storeData.shared_category?.find(
      (item) => item.classify === "ADDTL" && item.shared_category_id === id && item.state !== "DELETE"
    )
      ? true
      : false;
    return result;
  };
  return {
    serviceData: serviceData ?? [],
    checked,
    handleChange
  };
};
