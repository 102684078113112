import { useQuery } from "@tanstack/react-query";

import { commonFetch } from "@/api";

import useStore from "@/pages/hospital/info/store";

export const useFieldSubwayHook = () => {
  const storeData = useStore((state) => state.data);
  const storeAction = useStore((state) => state.action);

  const { data: subwayData } = useQuery({
    queryKey: ["common", "subway", "allow"],
    queryFn: async () => {
      const response = await commonFetch.get("?classify=SUBWAY&allow=true");

      return response.data.items;
    }
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    const selectId = +value;

    const isInsertState = storeData.shared_category?.find((item) => item.state === "INSERT");

    if (isInsertState) {
      const result = storeData.shared_category?.filter((item) => item.shared_category_id !== selectId) ?? null;

      storeAction.setData("shared_category", result);
      return;
    }

    const result =
      storeData.shared_category?.map((item) => {
        if (item.shared_category_id === selectId) return { ...item, state: "DELETE" };
        return item;
      }) ?? null;

    storeAction.setData("shared_category", result);
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    const selectId = +value;
    if (!storeData.shared_category) {
      storeAction.setData(name, [{ shared_category_id: selectId, classify: "SUBWAY", state: "INSERT" }]);
      return;
    }
    const hasItem = storeData.shared_category.find((item) => item.shared_category_id === selectId);

    if (hasItem) {
      const result = storeData.shared_category.map((item) => {
        if (item.shared_category_id === selectId && item.state === "DELETE") return { ...item, state: "STAY" };
        return item;
      });

      storeAction.setData(name, result);
      return;
    }

    storeAction.setData(name, [
      ...storeData.shared_category,
      { shared_category_id: selectId, classify: "SUBWAY", state: "INSERT" }
    ]);
  };

  return {
    storeData,
    fieldData: storeData.shared_category?.filter((item) => item.classify === "SUBWAY") ?? [],
    subwayData: subwayData ?? [],
    handleChange,
    handleClick
  };
};
