import React from "react";
import { Link } from "react-router-dom";

import { IconAngleDown, IconAngleUP, IconSortDown, IconSortUp } from "@/components/icons";

import { TABLE_HEAD } from "./constant";
import { useHook } from "./hook";

export default function List() {
  const { data, isSuccess, patchMutation } = useHook();
  return (
    <div className="bg-white px-[30px] pb-[20px]">
      <table className="mb-[60px] text-center">
        <caption className="sr-only">의사 리스트</caption>
        <colgroup>
          {TABLE_HEAD.map((head) => (
            <col key={`table-col-group-${head.text}`} className={head.class} />
          ))}
        </colgroup>
        <thead className="border-b-2 border-black text-[14px]">
          <tr className="h-[40px] *:relative *:after:absolute *:after:left-full *:after:top-1/2 *:after:h-1/2 *:after:-translate-x-1/4 *:after:-translate-y-1/2 *:after:border-e-2 [&>*:last-child]:after:hidden">
            {TABLE_HEAD.map((head) => (
              <th key={`table-head-${head.text}`} className="font-normal">
                <div
                  className="flex w-full items-center justify-center gap-[10px] data-[visible=false]:hidden"
                  data-visible={head.visible}
                >
                  {head.text}
                  {head.sort && (
                    <span className="relative">
                      <button type="button">
                        <IconSortUp className="absolute top-1/2 -translate-y-[55%]" />
                      </button>
                      <button type="button">
                        <IconSortDown className="absolute top-1/2 -translate-y-[45%]" />
                      </button>
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isSuccess &&
            data.map((item: any, index: number, arr: any[]) => (
              <tr key={`doctor-list-${item.doctor_id}`} className="h-[120px] border-b-2">
                <td>
                  <div className="flex flex-col items-center gap-[5px]">
                    <button
                      type="button"
                      onClick={(e) => {
                        if (!arr[index - 1]?.doctor_id) {
                          alert("최상단 입니다.");
                          return;
                        }
                        patchMutation.mutate({
                          type: "order",
                          body: {
                            base_doctor_id: item.doctor_id,
                            change_doctor_id: arr[index - 1].doctor_id
                          }
                        });
                      }}
                    >
                      <IconAngleUP />
                    </button>
                    <button
                      type="button"
                      onClick={(e) => {
                        if (!arr[index + 1]?.doctor_id) {
                          alert("최하단 입니다.");
                          return;
                        }
                        patchMutation.mutate({
                          type: "order",
                          body: {
                            base_doctor_id: item.doctor_id,
                            change_doctor_id: arr[index + 1].doctor_id
                          }
                        });
                      }}
                    >
                      <IconAngleDown />
                    </button>
                  </div>
                </td>
                <td>{item.is_head_director && "대표원장"}</td>
                <td>
                  <img
                    src={item.profile_image}
                    alt={item.title}
                    width={100}
                    height={100}
                    className="m-auto bg-blue-300"
                  />
                </td>
                <td>{item.title}</td>
                <td>{item.d_license}</td>
                <td>{item.medical_title}</td>
                <td>
                  <div className="mx-auto flex w-[90%] flex-wrap gap-[5px]">
                    {item.medical_category?.map((category: string) => (
                      <span
                        key={`medical-category-${category}`}
                        className="inline-block min-w-[50px] rounded-md bg-lb-blue px-[8px] py-[2px] text-white"
                      >
                        {category}
                      </span>
                    ))}
                  </div>
                </td>
                <td>
                  <label className="custom-check-button mr-[10px]">
                    <input
                      type="checkbox"
                      checked={item.is_consultation}
                      hidden
                      onChange={(e) => {
                        const checked = e.currentTarget.checked;
                        patchMutation.mutate({
                          type: "consult",
                          body: {
                            doctor_id: item.doctor_id,
                            is_consultation: checked
                          }
                        });
                      }}
                    />
                  </label>
                </td>
                <td>
                  {item.state === "TREATMENT" && (
                    <span className="inline-block min-w-[50px] rounded-md bg-lb-green px-[8px] py-[2px] text-white">
                      진료중
                    </span>
                  )}
                  {item.state === "LEAVE" && (
                    <span className="inline-block min-w-[50px] rounded-md bg-lb-gray px-[8px] py-[2px] text-white">
                      휴진
                    </span>
                  )}
                  {item.state === "RETIREMENT" && (
                    <span className="inline-block min-w-[50px] rounded-md bg-lb-gray px-[8px] py-[2px] text-white">
                      퇴직
                    </span>
                  )}
                </td>
                <td>{item.review_cnt}</td>
                <td>{item.rating}</td>
                <td>
                  <Link to={`/hospital/regist/doctor/detail/${item.doctor_id}`}>
                    <span className="inline-block min-w-[50px] rounded-md bg-btn-red px-[8px] py-[2px] text-white">
                      수정
                    </span>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
