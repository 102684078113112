import React from "react";

export default function Head() {
  return (
    <div className="border-b-2 border-black pb-[10px]">
      <h2 className="mr-[15px] inline text-[18px] font-semibold">병원 연락처정보</h2>
      <p className="inline">해당 병원의 의사정보를 등록하는 페이지 입니다.</p>
    </div>
  );
}
