import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import Nav from "@/components/nav";
import ProcessCard from "@/pages/event/consultation/components/process-card";
import DatePickerModal from "@/pages/event/consultation/components/datepicker-modal";
import { NAV_LIST, TABLE_HEAD } from "@/pages/event/consultation/constants";
import TextEditorModal from "./components/text-editor-modal";
import { eventConsultFetch } from "@/api";
import dayjs from "dayjs";

export default function EventConsultationPage() {
  const naewonRef = useRef<HTMLDialogElement>(null);
  const treamentRef = useRef<HTMLDialogElement>(null);
  const textEditorRef = useRef<HTMLDialogElement>(null);

  const [selectDate, setSelectDate] = useState({ id: "", date: "" });
  const [selectMemo, setSelectMemo] = useState({ id: "", text: "" });

  const { data } = useQuery({
    queryKey: ["event", "consultation", "process"],
    queryFn: async () => {
      const response = await eventConsultFetch.get();

      setSelectDate({ id: "", date: "" });
      setSelectMemo({ id: "", text: "" });
      return response?.data;
    }
  });

  useEffect(() => {
    const handleWindowClick = () => {
      for (const el of document.querySelectorAll("div[data-name=process-card]")) {
        el.setAttribute("data-modal", "close");
      }
    };

    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  return (
    <>
      <Nav list={NAV_LIST} />
      <div className="w-max bg-white px-[30px] py-[40px] text-black">
        <div className="mb-[20px] border-b-2 border-black pb-[10px]">
          <h2 className="text-[18px] font-semibold">이벤트 온라인 상담</h2>
        </div>
        <table className="mb-[60px] text-center">
          <caption className="sr-only">이벤트 승인 요청 목록 테이블</caption>
          <colgroup>
            {TABLE_HEAD.map((item) => (
              <col key={item.id} className={item.class} data-label={item.text} />
            ))}
          </colgroup>
          <thead className="border-b-2 border-black text-[14px]">
            <tr className="h-[40px]">
              {TABLE_HEAD.map((item) => (
                <td
                  key={item.id}
                  className="relative after:absolute after:right-0 after:top-1/2 after:h-1/2 after:-translate-y-1/2 after:border-e-2 last:after:hidden"
                >
                  {item.text}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.items &&
              data.items.map((item: any) => (
                <tr key={item.eventConsultationId} className="h-[120px] border-b">
                  <td>
                    <div className="justify-items-center">
                      <p className="w-fit whitespace-pre-line text-left">
                        {dayjs
                          .utc(item.createDate as string)
                          .tz()
                          .format("YYYY. MM. DD.\nHH:mm")}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="px-[20px]">
                      <p className="w-fit whitespace-pre-line text-left">{`${item.countryName}\n${item.writerName}\n${item.gender === "MAN" ? "남성" : item.gender === "FEMALE" ? "여성" : "알 수 없음"}/${dayjs.tz().get("year") - item.year}`}</p>
                    </div>
                  </td>
                  <td>{item.phone}</td>
                  <td>
                    <div className="flex items-center gap-[5px] pl-[20px]">
                      <span className="rounded-3xl bg-yellow-800 px-[8px] py-[2px] text-sm text-white">이벤트</span>
                      <p className="line-clamp-2 w-[120px] text-left">{item.eventTitle}</p>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center justify-center gap-[5px]">
                      <ProcessCard
                        id={item.eventConsultationId}
                        deps={0}
                        value={item.stepOneState}
                        disabled={item.stepThreeState === "TC"}
                      />
                      <span>{">"}</span>
                      <ProcessCard
                        id={item.eventConsultationId}
                        deps={1}
                        value={item.stepTwoState}
                        disabled={item.stepThreeState === "TC"}
                      />
                      <span>{">"}</span>
                      <ProcessCard
                        id={item.eventConsultationId}
                        deps={2}
                        value={item.stepThreeState}
                        disabled={item.stepThreeState === "TC"}
                      />
                    </div>
                  </td>
                  <td>
                    {item.visitDate == null && item.stepThreeState !== "TC" ? (
                      <button
                        type="button"
                        className="rounded-md border px-[20px] py-[6px]"
                        onClick={() => {
                          setSelectDate({
                            id: item.eventConsultationId,
                            date: ""
                          });
                          naewonRef.current?.showModal();
                        }}
                      >
                        내원일 입력
                      </button>
                    ) : (
                      <p>
                        {dayjs.utc(item.visitDate).tz().format("YYYY-MM-DD.HH:mm")}
                        {item.stepThreeState !== "TC" && (
                          <button
                            type="button"
                            className="ml-[5px] inline-block aspect-square w-[10px] bg-red-400"
                            onClick={() => {
                              setSelectDate({
                                id: item.eventConsultationId,
                                date: dayjs.utc(item.visitDate).tz().format("YYYY-MM-DD HH:mm")
                              });
                              naewonRef.current?.showModal();
                            }}
                          />
                        )}
                      </p>
                    )}
                  </td>
                  <td>
                    {item.treatmentDate == null && item.stepThreeState !== "TC" ? (
                      <button
                        type="button"
                        className="rounded-md border px-[20px] py-[6px]"
                        onClick={() => {
                          setSelectDate({
                            id: item.eventConsultationId,
                            date: ""
                          });
                          treamentRef.current?.showModal();
                        }}
                      >
                        시술일 입력
                      </button>
                    ) : (
                      <p>
                        {dayjs.utc(item.visitDate).tz().format("YYYY-MM-DD.HH:mm")}
                        {item.stepThreeState !== "TC" && (
                          <button
                            type="button"
                            className="ml-[5px] inline-block aspect-square w-[10px] bg-red-400"
                            onClick={() => {
                              setSelectDate({
                                id: item.eventConsultationId,
                                date: dayjs.utc(item.visitDate).tz().format("YYYY-MM-DD HH:mm")
                              });
                              treamentRef.current?.showModal();
                            }}
                          />
                        )}
                      </p>
                    )}
                  </td>
                  <td>
                    {item.stepThreeState === "TC" ? (
                      <select
                        className="rounded-md border p-[8px]"
                        onClick={() => alert("아직 지원하지 않는 기능입니다.")}
                      >
                        <option value={""} hidden>
                          의사명
                        </option>
                        <option value={"1"}>김김김</option>
                      </select>
                    ) : (
                      <span>-</span>
                    )}
                  </td>
                  <td>
                    {item.memo == null ? (
                      <button
                        type="button"
                        className="rounded-md border border-blue-500 px-[30px] py-[6px] text-blue-500"
                        onClick={() => {
                          setSelectMemo({
                            id: item.eventConsultationId,
                            text: item.memo
                          });
                          textEditorRef.current?.showModal();
                        }}
                      >
                        메모하기
                      </button>
                    ) : (
                      <div className="rounded-md border border-black bg-gray-200 p-[4px]">
                        <p className="whitespace-pre-line text-left">{item.memo}</p>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <DatePickerModal ref={naewonRef} title={"내원일정"} label={"내원예정일"} type={0} selectDate={selectDate} />
      <DatePickerModal ref={treamentRef} title={"시술일정"} label={"시술예정일"} type={1} selectDate={selectDate} />
      <TextEditorModal ref={textEditorRef} selectMemo={selectMemo} />
    </>
  );
}
