import { QueryKey } from "@tanstack/react-query";

import { doctorFetch } from "@/api";

export const query: (id: string) => { queryKey: QueryKey; queryFn: () => Promise<any> } = (id) => ({
  queryKey: ["hospital", id, "doctor", "list"],
  queryFn: async () => {
    const response = await doctorFetch.get(`hospital=${id}`);
    return response.data.items;
  }
});
