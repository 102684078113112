import { QueryClient } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";

import { query } from "./query";

const loader =
  (queryClient: QueryClient) =>
  ({ params: { hospital_id } }: LoaderFunctionArgs) => {
    return queryClient.ensureQueryData<any>(query(hospital_id as string));
  };
export default loader;
