import React, { useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Nav from "@/components/nav";
import { IconClose, IconInfo } from "@/components/icons";
import { commonMedicalCategoryFetch } from "@/api";

const commonMedicalCategoryQuery = {
  queryKey: ["common", "medical", "category"],
  queryFn: async () => {
    const response = await commonMedicalCategoryFetch.get();

    return response.data.items;
  }
};

export const CommonTreatmentCategoryLoader = (queryClient: QueryClient) => () => {
  return queryClient.ensureQueryData(commonMedicalCategoryQuery);
};

export default function CommonTreatmentCategoryPage() {
  const loaderData = useLoaderData();
  const queryClient = useQueryClient();

  const dialogRef = useRef<HTMLDialogElement>(null);

  const [selectCategory, setSelectCategory] = useState<any>(null);

  const { data, isSuccess } = useQuery<any>({ ...commonMedicalCategoryQuery, initialData: loaderData });

  const putMutation = useMutation({
    mutationFn: ({ id, item }: { id: string; item: FormData }) => commonMedicalCategoryFetch.put(id, item),
    onSuccess: () => {
      alert("성공");
      queryClient.invalidateQueries({ queryKey: ["common", "medical", "category"] });
      dialogRef.current?.close();
    },
    onError: () => {
      alert("실패");
    }
  });

  const patchMutation = useMutation({
    mutationFn: async (item: any) => commonMedicalCategoryFetch.patch(item),
    onSuccess: () => {
      alert("성공");
      queryClient.invalidateQueries({ queryKey: ["common", "medical", "category"] });
    },
    onError: () => {
      alert("실패");
    }
  });

  const orderMutation = useMutation({
    mutationFn: async (item: any) => commonMedicalCategoryFetch.orderPatch(item),
    onSuccess: () => {
      alert("성공");
      queryClient.invalidateQueries({ queryKey: ["common", "medical", "category"] });
    },
    onError: () => {
      alert("실패");
    }
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    const newFormData = new FormData();

    newFormData.set("text[0].title", formData.get("koTitle") ?? "");
    newFormData.set("text[0].local", "ko");
    newFormData.set("text[0].description", formData.get("koDes") ?? "");
    newFormData.set("text[1].title", formData.get("enTitle") ?? "");
    newFormData.set("text[1].description", formData.get("enDes") ?? "");
    newFormData.set("text[1].local", "en");
    newFormData.set("text[2].title", formData.get("viTitle") ?? "");
    newFormData.set("text[2].description", formData.get("viDes") ?? "");
    newFormData.set("text[2].local", "vi");
    newFormData.set("text[3].title", formData.get("jaTitle") ?? "");
    newFormData.set("text[3].description", formData.get("jaDes") ?? "");
    newFormData.set("text[3].local", "ja");

    if (selectCategory.image.image) {
      if (selectCategory.image.image_id) {
        newFormData.set("image.image_id", selectCategory.image.image_id);
        newFormData.set("image.image", formData.get("image") ?? "");
        newFormData.set("image.state", "UPDATE");
      } else {
        newFormData.set("image.image", formData.get("image") ?? "");
        newFormData.set("image.state", "INSERT");
      }
    } else {
      if (selectCategory.image.image_id) {
        newFormData.set("image.image_id", selectCategory.image.image_id);
        newFormData.set("image.state", "STAY");
      }
    }

    putMutation.mutate({ id: selectCategory.id, item: newFormData });
  };

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (!files) return;

    const file = files[0];

    const fileReader = new FileReader();

    fileReader.onload = (ev) => {
      const url = ev.target?.result;

      setSelectCategory((prev: any) => ({ ...prev, image: { ...prev.image, image: file, image_url: url } }));
    };

    fileReader.readAsDataURL(file);
  };
  const handleChangeAllow = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isOpen = e.currentTarget.checked ? true : false;
    const id = e.currentTarget.value;

    patchMutation.mutate({ items: [{ medical_category_id: id, is_open_allow: isOpen }] });
  };

  const handleOrderChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {
      dataset: { origin, reverse }
    } = e.currentTarget;

    if (reverse === "-1") {
      alert("변경이 불가능합니다.");
      return;
    }

    orderMutation.mutate({
      base_medical_category_id: origin,
      change_medical_category_id: reverse
    });
  };

  return (
    <div>
      <Nav
        list={[
          { title: "공통관리", link: null },
          { title: "시술 카테고리ㄴ 관리", link: null }
        ]}
      />
      <div className="h-full min-w-full bg-white px-[30px] py-[20px]">
        <div className="mb-[30px]">
          <h2 className="mb-[20px] text-[18px] font-semibold">시술 카테고리 노출관리</h2>
          <div className="flex h-[50px] w-full items-center gap-[5px] rounded-md border border-black bg-orange-300 px-[5px]">
            <IconInfo fill="white" />
            <p>맨위 게시물부터 순서대로 나열됩니다.</p>
          </div>
        </div>
        <div>
          <table className="text-center">
            <colgroup>
              <col width={50} />
              <col width={100} />
              <col width={150} />
              <col width={200} />
              <col width={200} />
              <col width={200} />
              <col width={200} />
              <col width={120} />
            </colgroup>
            <thead>
              <tr className="border-b-2 border-b-black">
                {["순서", "노출유무", "카테고리 이미지", "한국어명", "영어명", "일본어명", "베트남어명", ""].map(
                  (title) => (
                    <th
                      key={`table-head-${title}`}
                      className="relative py-[8px] after:absolute after:right-0 after:top-1/2 after:h-1/2 after:-translate-y-1/2 after:border-e-2 last:after:hidden"
                    >
                      {title}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {isSuccess &&
                data.map((item: any, index: number, array: any) => (
                  <tr key={`table-data-${item.medical_category_id}`}>
                    <td className="py-[10px]">
                      <button
                        type="button"
                        value={"up"}
                        data-origin={item.medical_category_id}
                        data-reverse={array?.[index - 1]?.medical_category_id ?? -1}
                        onClick={handleOrderChange}
                      >
                        UP
                      </button>
                      <button
                        type="button"
                        value={"down"}
                        data-origin={item.medical_category_id}
                        data-reverse={array?.[index + 1]?.medical_category_id ?? -1}
                        onClick={handleOrderChange}
                      >
                        DOWN
                      </button>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={item.is_open_allow}
                        value={item.medical_category_id}
                        onChange={handleChangeAllow}
                      />
                    </td>
                    <td className="py-[10px]">
                      {item.image.image_url ? (
                        <img
                          src={item.image.image_url}
                          alt="category"
                          className="mx-auto aspect-square"
                          width={130}
                          height={130}
                        />
                      ) : (
                        <div className="max-auto inline-block h-[130px] w-[130px] place-content-center rounded-md border">
                          No Image
                        </div>
                      )}
                    </td>
                    <td className="py-[10px]">
                      <div className="flex flex-col">
                        <span>{item.text.find((txt: any) => txt.local === "ko").title}</span>
                        <span>{item.text.find((txt: any) => txt.local === "ko").description}</span>
                      </div>
                    </td>
                    <td className="py-[10px]">
                      <div className="flex flex-col">
                        <span>{item.text.find((txt: any) => txt.local === "en").title}</span>
                        <span>{item.text.find((txt: any) => txt.local === "en").description}</span>
                      </div>
                    </td>
                    <td className="py-[10px]">
                      <div className="flex flex-col">
                        <span>{item.text.find((txt: any) => txt.local === "ja").title}</span>
                        <span>{item.text.find((txt: any) => txt.local === "ja").description}</span>
                      </div>
                    </td>
                    <td className="py-[10px]">
                      <div className="flex flex-col">
                        <span>{item.text.find((txt: any) => txt.local === "vi").title}</span>
                        <span>{item.text.find((txt: any) => txt.local === "vi").description}</span>
                      </div>
                    </td>
                    <td className="py-[10px]">
                      <button
                        type="button"
                        className="rounded-md bg-red-500 px-[16px] py-[2px] text-white"
                        onClick={() => {
                          dialogRef.current?.showModal();
                          setSelectCategory({
                            id: item.medical_category_id,
                            en: item.text.find((txt: any) => txt.local === "en"),
                            ko: item.text.find((txt: any) => txt.local === "ko"),
                            ja: item.text.find((txt: any) => txt.local === "ja"),
                            vi: item.text.find((txt: any) => txt.local === "vi"),
                            image: item.image
                          });
                        }}
                      >
                        수정
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <dialog
        ref={dialogRef}
        className="rounded-xl"
        onReset={(e) => e.currentTarget.close()}
        onClose={() => setSelectCategory(null)}
      >
        <form method="dialog" className="w-[500px]" onSubmit={handleSubmit}>
          <div className="flex h-[50px] items-center justify-between bg-gray-600 px-[20px] text-white">
            <h3 className="text-[18px]">시술 카테고리명 수정하기</h3>
            <button type="reset">
              <IconClose fill="white" />
            </button>
          </div>
          {selectCategory && (
            <div className="p-[20px]">
              <div className="mb-[20px]">
                <h4 className="mb-[20px]">카테고리명 및 설명</h4>
                <div className="ml-[10px] flex flex-col gap-[16px]">
                  <fieldset className="flex gap-[10px]">
                    <legend>한국어</legend>
                    <input
                      type="text"
                      className="mb-[8px] mt-[5px] h-[40px] w-full rounded-md border px-[10px]"
                      name="koTitle"
                      placeholder="카테고리"
                      defaultValue={selectCategory.ko.title}
                      required
                    />
                    <input
                      type="text"
                      className="mb-[8px] mt-[5px] h-[40px] w-full rounded-md border px-[10px]"
                      name="koDes"
                      placeholder="#설명,#설명"
                      defaultValue={selectCategory.ko.description}
                    />
                  </fieldset>
                  <fieldset className="flex gap-[10px]">
                    <legend>영어</legend>
                    <input
                      type="text"
                      className="mb-[8px] mt-[5px] h-[40px] w-full rounded-md border px-[10px]"
                      name="enTitle"
                      placeholder="카테고리"
                      defaultValue={selectCategory.en.title}
                      required
                    />
                    <input
                      type="text"
                      className="mb-[8px] mt-[5px] h-[40px] w-full rounded-md border px-[10px]"
                      name="enDes"
                      placeholder="#설명,#설명"
                      defaultValue={selectCategory.en.description}
                    />
                  </fieldset>
                  <fieldset className="flex gap-[10px]">
                    <legend>일어</legend>
                    <input
                      type="text"
                      className="mb-[8px] mt-[5px] h-[40px] w-full rounded-md border px-[10px]"
                      name="jaTitle"
                      placeholder="카테고리"
                      defaultValue={selectCategory.ja.title}
                    />
                    <input
                      type="text"
                      className="mb-[8px] mt-[5px] h-[40px] w-full rounded-md border px-[10px]"
                      name="jaDes"
                      placeholder="#설명,#설명"
                      defaultValue={selectCategory.ja.description}
                    />
                  </fieldset>
                  <fieldset className="flex gap-[10px]">
                    <legend>베트남어</legend>
                    <input
                      type="text"
                      className="mb-[8px] mt-[5px] h-[40px] w-full rounded-md border px-[10px]"
                      name="viTitle"
                      placeholder="카테고리"
                      defaultValue={selectCategory.vi.title}
                    />
                    <input
                      type="text"
                      className="mb-[8px] mt-[5px] h-[40px] w-full rounded-md border px-[10px]"
                      name="viDes"
                      placeholder="#설명,#설명"
                      defaultValue={selectCategory.vi.description}
                    />
                  </fieldset>
                </div>
              </div>
              <div className="mb-[20px] ml-[10px]">
                <div className="mb-[10px] flex items-end gap-[5px]">
                  <img src={selectCategory.image.image_url} alt="test" className="border" width={120} height={120} />
                  <label className="rounded-md bg-red-500 px-[8px] py-[2px] text-white">
                    이미지 수정
                    <input type="file" className="sr-only" name="image" onChange={handleImage} />
                  </label>
                </div>
                <div className="flex items-center gap-[5px]">
                  <IconInfo fill="black" />
                  <p>512px*512px, 최대 2MB</p>
                </div>
              </div>
              <div className="text-right">
                <button type="reset" className="mr-[10px] rounded-3xl bg-stone-400 px-[1.5em] py-[10px] text-white">
                  취소
                </button>
                <button className="rounded-3xl bg-red-600 px-[2em] py-[10px] text-white">수정</button>
              </div>
            </div>
          )}
        </form>
      </dialog>
    </div>
  );
}
