import React from "react";

import { Box } from "@/pages/hospital/info/components";
import { useFieldLanguageHook } from "@/pages/hospital/info/hooks";

export default function FieldLanguage() {
  const { languageData, checked, handleChange } = useFieldLanguageHook();
  return (
    <Box>
      <div className="mb-[10px]">
        <label htmlFor="h-address" className="mr-[30px] text-[14px] font-medium text-form-purple">
          응대가능언어
        </label>
      </div>
      <div className="flex gap-[30px]">
        {languageData.map((item: any) => (
          <div key={`common-language-key-${item.shared_category_id}`}>
            <input
              type="checkbox"
              id={item.shared_category_id}
              className="mr-[5px] align-middle"
              name="shared_category"
              value={item.shared_category_id}
              checked={checked(item.shared_category_id)}
              onChange={handleChange}
            />
            <label htmlFor={item.shared_category_id} className="align-middle">
              {item.text.find((v: any) => v.local === "ko")?.title}
            </label>
          </div>
        ))}
      </div>
    </Box>
  );
}
